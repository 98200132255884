const styles = (theme) => ({
    root: {},
    requiredLabel: {
        fontStyle: 'italic',
        fontSize: 16,
        color: '#4a4a4a',
    },
});

export default styles;
