const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ruler: {
        height: 1,
        backgroundColor: '#979797',
        width: '100%',
    },
});

export default styles;
