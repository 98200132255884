const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.tuna.main,
        height: '100%',
        flex: '0 1 330px',
        position: 'sticky',
        top: 0,
        padding: 30,
        minHeight: '100vh',
        maxWidth: '330px',
        '&::before': {
            content: "''",
            width: '390px',
            position: 'absolute',
            height: '100vh',
            backgroundColor: theme.palette.tuna.main,
            left: 0,
            zIndex: -1,
        },
    },
    inputRoot: {
        backgroundColor: 'rgba(29, 32, 40, 0.64)',
        color: '#ffffff',
        '& #search': {
            marginRight: 15,
            opacity: 0.3,
        },
    },
    closeBtn: {
        opacity: 0.3,
        color: '#ffffff',
    },
    listItem: {
        padding: '12px 16px',
        color: '#ffffff',
        backgroundColor: theme.palette.tuna.main,
    },
    text: {},
    icon: {
        color: '#ffffff',
        opacity: 0.3,
    },
    divider: {
        opacity: 0.1,
        backgroundColor: '#ffffff',
    },
});

export default styles;
