export default {
    university: {
        value: '',
        valid: true,
    },
    location: {
        value: '',
        valid: true,
    },
};
