import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Wysiwyg
import Wysiwyg from 'components/Input/Wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
// Styles
import styles from './styles';

class BodyText extends React.Component {
    constructor(props) {
        super(props);

        this.body = props.body
            ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.body)))
            : EditorState.createEmpty();
    }

    onChange(field, value) {
        this[field] = value;
        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                body: typeof this.body === 'string' ? this.body : '', // Body can initially be an object with the editor state this is no bueno,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Wysiwyg
                    ref="body"
                    classes={{ editor: classes.editor }}
                    defaultEditorState={this.body}
                    placeholder="Type Body Text Here"
                    onEditorStateChange={(e) => this.onChange('body', draftToHtml(convertToRaw(e.getCurrentContent())))}
                    toolbar={{
                        options: ['blockType', 'fontFamily', 'inline', 'list', 'link'], // This is where you can specify what options you need in
                        //the toolbar and appears in the same order as specified
                        fontFamily: {
                            options: ['Lato', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                        },
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                        },
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(BodyText);
