// React
import React, { useEffect, useState, useRef } from 'react';
// Modules
import { API } from 'aws-amplify';
import Mixpanel from 'mixpanel-browser';
import checkError from 'utils/check-error';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Mui-Core
import { Grid, MenuItem, Hidden } from '@material-ui/core';
// Mui-Icons
import { ArrowBackIos } from '@material-ui/icons';
// Custom Components
import Page from 'components/Page';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Input/Select';
import RoundedSection from 'components/RoundedSection';
import RoundedSectionTop from 'components/RoundedSection/RoundedSectionTop';
import RoundedSectionLabel from 'components/RoundedSection/RoundedSectionLabel';
import RoundedSectionBody from 'components/RoundedSection/RoundedSectionBody';
// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const ChapterSafetyAlertNew = ({ className, classes, ...props }) => {
    const dispatch = useDispatch();
    const mounted = useRef(false);
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));

    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;
    const FirstChapterId = cognitoUser.FirstChapter ? cognitoUser.FirstChapter.id : null;

    const [Organizations, setOrganizations] = useState({ data: [], count: 0 });
    const [organization, setOrganization] = useState(ParentOrgId || 'none-selected');
    const [Chapters, setChapters] = useState({ data: [], count: 0 });
    const [chapter, setChapter] = useState('none-selected');
    const [members, setMembers] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const [message, setMessage] = useState('');

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        async function _getChapters() {
            try {
                const chapters = await API.get('ClutchAPI', `/organizations?ParentId=${organization}&members=1`);
                mounted && setChapters(chapters);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        if (!!organization) {
            _getChapters();
        }
    }, [organization, dispatch]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    async function handleFormSubmit(e) {
        e.preventDefault();

        mounted && setSubmitting(true);
        try {
            await API.post('ClutchAPI', `/organizations/${chapter.id}/safety-alerts?ParentId=${organization}`, {
                body: {
                    information: message,
                },
            });
            Mixpanel.track('Chapter safety alert created');
            dispatch(setSnackbar(`Alert was sent to ${members} members`));
            setMessage('');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setSubmitting(false);
    }

    function handleOrganizationChange(value) {
        setChapter('none-selected');
        setOrganization(value);
    }
    function handleChapterChange(value) {
        setChapter(value);
        setMembers(value.memberCount);
    }
    function handleBackButton() {
        props.history.push(`/chapter-safety/`);
    }

    return (
        <div className={classes.root}>
            <Page
                topNavigationProps={{
                    pageTitle: 'New Safety Alert',
                }}>
                <Hidden smDown>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4} md={4}>
                            <Button
                                startIcon={<ArrowBackIos />}
                                disableElevation
                                variant="contained"
                                color={'primary'}
                                onClick={handleBackButton}>
                                Return to Chapter Safety Alerts
                            </Button>
                        </Grid>
                    </Grid>
                </Hidden>

                <Grid container spacing={2}>
                    {!ParentOrgId && (
                        <Grid item xs={12} lg={5} md={4}>
                            <Select
                                classes={{ root: classes.selectHeight }}
                                value={organization}
                                onChange={(e) => handleOrganizationChange(e.target.value)}
                                typography={'Organization'}>
                                <MenuItem key={`org-none-selected`} value={'none-selected'}>
                                    Select Organization
                                </MenuItem>
                                {Organizations.data.map((org) => {
                                    return (
                                        <MenuItem key={`org-${org.id}`} value={org.id}>
                                            {org.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    )}
                    <Grid item xs={12} lg={3} md={4}>
                        <Select
                            classes={{ root: classes.selectHeight }}
                            value={chapter}
                            onChange={(e) => handleChapterChange(e.target.value)}
                            typography={'Chapter'}
                            disabled={Chapters.data.length === 0 || organization === 'none-selected'}>
                            <MenuItem key={'chapter-none-selected'} value="none-selected">
                                Select Chapter
                            </MenuItem>
                            {Chapters.data.map((chapter) => {
                                return (
                                    <MenuItem key={`chapter-${chapter.id}`} value={chapter}>
                                        {chapter.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
                <form onSubmit={handleFormSubmit} className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8} md={8}>
                            <RoundedSection classes={{ root: classes.sendMessage }}>
                                <RoundedSectionTop>
                                    <RoundedSectionLabel
                                        primary={`Message ${members} members`}
                                        secondary="Keep the message under 120 characters"
                                    />
                                </RoundedSectionTop>
                                <RoundedSectionBody>
                                    <Input
                                        multiline
                                        rows={4}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        maxLength={120}
                                        placeholder="Type message here..."
                                    />
                                </RoundedSectionBody>
                            </RoundedSection>

                            <Button
                                classes={{ root: classes.submitButton }}
                                color="primary"
                                size="large"
                                text="Send Alert"
                                type="submit"
                                variant="contained"
                                fullWidth
                                loading={submitting}
                                onClick={handleFormSubmit}
                                disabled={
                                    organization === 'none-selected' ||
                                    chapter === 'none-selected' ||
                                    message.length === 0
                                }
                            />
                        </Grid>
                    </Grid>
                </form>
            </Page>
        </div>
    );
};

export default withStyles(styles)(ChapterSafetyAlertNew);
