import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import SideNavigation from 'components/SideNavigation';

// Pages

import Events from './Events';
import OrganizationRoles from './OrganizationRoles';
import OrganizationRole from './OrganizationRole';
import EventReviewProcess from './EventReviewProcess';
import EventReview from './EventReview';
import EventLog from './EventLog';
import EventStep from './EventStep';
import EventSteps from './EventSteps';
import EventType from './EventType';
import EventTypes from './EventTypes';
import ChapterSafetyAlert from './ChapterSafetyAlert';
import ChapterSafetyAlerts from './ChapterSafetyAlerts';
import ChapterSafetyAlertNew from './ChapterSafetyAlertNew';
import Incident from './Incident';
import Incidents from './Incidents';
import PersonalSafetyAlert from './PersonalSafetyAlert';
import PersonalSafetyAlerts from './PersonalSafetyAlerts';
import HouseRules from './HouseRules';
import HouseRule from './HouseRule';
import Category from './Category';
import Education from './Education';
import Resources from './Resources';
import Chapters from './Chapters';
import Chapter from './Chapter';
import OrganizationUsers from './OrganizationUsers';
import OrganizationUserNew from './OrganizationUserNew';
import OrganizationUserEdit from './OrganizationUserEdit';
import SystemSetup from './SystemSetup';

// Constants
import { policyIdentifiers } from 'global-constants';

// styles
import styles from './styles';

const Secure = ({ classes, ...props }) => {
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const policies = cognitoUser.policies;

    const availableRoutes = [];

    if (
        policies[policyIdentifiers.COMMENT_ON_EVENTS] ||
        policies[policyIdentifiers.EVENT_APPROVAL_REQUIRED] ||
        policies[policyIdentifiers.VIEW_EVENTS] ||
        policies[policyIdentifiers.FINAL_EVENT_APPROVER] ||
        policies[policyIdentifiers.SITE_ADMIN_POLICY]
    ) {
        availableRoutes.push(<Route exact path="/event/:id" key="/event/:id" component={EventReview} />);
        availableRoutes.push(<Route exact path="/event/:id/log" key="/event/:id/log" component={EventLog} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS]) {
        availableRoutes.push(<Route exact path="/org-users" key="/org-users" component={OrganizationUsers} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS]) {
        availableRoutes.push(
            <Route exact path="/org-users/new" key="/org-users/new" component={OrganizationUserNew} />
        );
        availableRoutes.push(
            <Route exact path="/org-users/:id" key="/org-users/:id" component={OrganizationUserEdit} />
        );
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_SYSTEM_SETUP]) {
        availableRoutes.push(<Route exact path="/system-setup" key="/system-setup" component={SystemSetup} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_ROLES_AND_PERMISSIONS]) {
        availableRoutes.push(<Route exact path="/org-roles" key="/org-roles" component={OrganizationRoles} />);
        availableRoutes.push(<Route exact path="/org-roles/:id" key="/org-roles/:id" component={OrganizationRole} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_APPROVAL_PROCESS]) {
        availableRoutes.push(
            <Route
                exact
                path="/event-review-process/:id"
                key="/event-review-process/:id"
                component={EventReviewProcess}
            />
        );
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_EVENT_STEPS]) {
        availableRoutes.push(<Route exact path="/event-steps/:id" key="/event-steps/:id" component={EventStep} />);
        availableRoutes.push(<Route exact path="/event-steps" key="/event-steps" component={EventSteps} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_EVENT_TYPES]) {
        availableRoutes.push(<Route exact path="/event-types/:id" key="/event-types/:id" component={EventType} />);
        availableRoutes.push(<Route exact path="/event-types" key="/event-types" component={EventTypes} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_CHAPTERS]) {
        availableRoutes.push(<Route exact path="/chapters" key="/chapters" component={Chapters} />);
        availableRoutes.push(<Route exact path="/chapters/:id" key="/chapters/:id" component={Chapter} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.VIEW_CHAPTER_SAFETY_ALERTS]) {
        availableRoutes.push(
            <Route exact path="/chapter-safety" key="/chapter-safety" component={ChapterSafetyAlerts} />
        );
        availableRoutes.push(
            <Route exact path="/chapter-safety/new" key="/chapter-safety/new" component={ChapterSafetyAlertNew} />
        );
        availableRoutes.push(
            <Route exact path="/chapter-safety/:id" key="/chapter-safety/:id" component={ChapterSafetyAlert} />
        );
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.VIEW_INCIDENT_REPORTS]) {
        availableRoutes.push(<Route exact path="/incidents" key="/incidents" component={Incidents} />);
        availableRoutes.push(<Route exact path="/incidents/:id" key="/incidents/:id" component={Incident} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.VIEW_PERSONAL_SAFETY_ALERTS]) {
        availableRoutes.push(
            <Route
                exact
                path="/personal-safety-alerts/:id"
                key="/personal-safety-alerts/:id"
                component={PersonalSafetyAlert}
            />
        );
        availableRoutes.push(
            <Route
                exact
                path="/personal-safety-alerts"
                key="/personal-safety-alerts"
                component={PersonalSafetyAlerts}
            />
        );
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_HOUSE_RULES]) {
        availableRoutes.push(<Route exact path="/house-rules" key="/house-rules" component={HouseRules} />);
        availableRoutes.push(<Route exact path="/house-rules/:id" key="/house-rules/:id" component={HouseRule} />);
    }
    if (policies[policyIdentifiers.SITE_ADMIN_POLICY] || policies[policyIdentifiers.MANAGE_EDUCATION]) {
        availableRoutes.push(<Route exact path="/categories/:id" key="/categories/:id" component={Category} />);
        availableRoutes.push(<Route exact path="/page/:id" key="/page/:id" component={Education} />);
        availableRoutes.push(<Route exact path="/education" key="/education" component={Resources} />);
    }

    return (
        <SideNavigation>
            <Switch>
                <Route exact path="/events-for-review" component={Events} />
                {availableRoutes.map((node) => node)}

                {/* Redirect */}
                <Route
                    path="/*"
                    render={() => (
                        <Redirect
                            to={{
                                pathname: '/events-for-review',
                            }}
                        />
                    )}
                />
            </Switch>
        </SideNavigation>
    );
};

export default withStyles(styles)(Secure);
