import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    get as getIncidentReport,
    remove as deleteIncidentReport,
    sendEmail as sendIncidentEmail,
} from 'redux/actions/incidentReport';
import { setSnackbar } from 'redux/actions/snackbar';

// Modules
import Mixpanel from 'mixpanel-browser';
import dayjs from 'dayjs';
import checkError from 'utils/check-error';
import queryString from 'query-string';
import { ImageFileTypes, VideoFileTypes, bytesToSize } from 'utils/file-types';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
// Components
import Page from 'components/Page';
import EmailDialog from 'components/Dialogs/EmailDialog';
import BasicDialog from 'components/Dialogs/BasicDialog';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import DeleteButton from 'components/Button/DeleteButton';
import EmailButton from 'components/Button/EmailButton';
import RoundedSection from 'components/RoundedSection';
import RoundedSectionBody from 'components/RoundedSection/RoundedSectionBody';
import RoundedSectionLabel from 'components/RoundedSection/RoundedSectionLabel';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const IncidentReport = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { incidentReport, cognitoUser } = useSelector(({ incidentReport, cognitoUser }) => ({
        incidentReport,
        cognitoUser,
    }));
    const query = queryString.parse(props.history.location.search);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [emailListModalOpen, setEmailListModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        async function _getAlert() {
            if (!query.OrganizationId || !query.EventId || !query.ParentId) {
                setSnackbar('Permission Denied');
                return props.history.push('/incidents');
            }

            setLoading(true);
            try {
                await dispatch(getIncidentReport({ id: props.match.params.id, ...query }));
                Mixpanel.track('Incident report viewed');
                setLoading(false);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
                props.history.push('/incidents');
            }
        }
        _getAlert();
    }, [dispatch, props.match.params.id, props.history]);

    async function handleDeleteReport() {
        setRemoving(true);
        try {
            await dispatch(deleteIncidentReport(incidentReport.id));
            Mixpanel.track('Incident report deleted');
            dispatch(setSnackbar('Incident Report was removed!'));
            props.history.push('/incidents');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        setRemoving(false);
    }

    async function handleSendEmails(emails) {
        try {
            await dispatch(sendIncidentEmail(emails.replace(/ /g, '').split(',')));
            Mixpanel.track('Incident report email sent');
            dispatch(setSnackbar('Emails sent'));
            setEmailListModalOpen(false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    return loading ? (
        <Page maxWidth={720}>
            <Skeleton type="infoPage" />
        </Page>
    ) : (
        <Page maxWidth={720}>
            <EmailDialog
                open={emailListModalOpen}
                onClose={() => setEmailListModalOpen(false)}
                onSubmit={handleSendEmails}
            />
            <BasicDialog
                open={deleteModalOpen}
                title={
                    <span>
                        Delete Incident Report
                        <br />
                        by {`" ${incidentReport.User.firstName} ${incidentReport.User.lastName}"`}
                    </span>
                }
                subtitle={'Are you sure you would like to delete this Incident Report?'}
                onClose={() => setDeleteModalOpen(false)}
                onSubmit={handleDeleteReport}
            />
            <div className={classes.topPageForm}>
                <Button
                    fullWidth={false}
                    text="Return to Incidents"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={(e) => props.history.push('/incidents')}
                    startIcon={<ChevronLeft />}
                />
                <div className={classes.rightButtons}>
                    {/* <DownloadButton className={classes.actionButton} /> */}
                    <EmailButton disabled={removing} onClick={() => setEmailListModalOpen(true)} />
                    <DeleteButton disabled={removing} onClick={() => setDeleteModalOpen(true)} tooltip="Delete Alert" />
                </div>
            </div>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel margin primary="Chapter" secondary={incidentReport.Organization.name} />
                    <RoundedSectionLabel primary="Event" secondary={incidentReport.Event.information.title} />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel
                        margin
                        primary="Submitted By"
                        secondary={
                            cognitoUser.policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS] ||
                            cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY] ? (
                                <Link
                                    to={`/org-users/${incidentReport.User.id}?OrganizationId=${query.OrganizationId}`}>
                                    {`${incidentReport.User.firstName} ${incidentReport.User.lastName}`}
                                </Link>
                            ) : (
                                `${incidentReport.User.firstName} ${incidentReport.User.lastName}`
                            )
                        }
                    />
                    <RoundedSectionLabel margin primary="Phone Number" secondary={`${incidentReport.User.phone}`} />
                    <RoundedSectionLabel
                        margin
                        primary="Submitted Date"
                        secondary={dayjs(incidentReport.createdAt).format('MMMM DD, YYYY')}
                    />
                    <RoundedSectionLabel
                        margin
                        primary="Submitted Time"
                        secondary={dayjs(incidentReport.createdAt).format('h:mm A')}
                    />
                    <RoundedSectionLabel primary="Location of Incident" secondary={incidentReport.location} />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel
                        margin
                        primary="Witnessed The Incident"
                        secondary={incidentReport.witnessed ? 'Yes' : 'No'}
                    />
                    <RoundedSectionLabel
                        primary="Other Witnesses"
                        secondary={incidentReport.witnesses ? incidentReport.witnesses : 'None'}
                    />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel primary="Incident Report" secondary={incidentReport.information} />
                </RoundedSectionBody>
            </RoundedSection>
            {incidentReport.Media.map((media) => {
                let mediaElement;
                let primaryText;
                if (VideoFileTypes.includes(media.type)) {
                    primaryText = 'Video of Incident';
                    mediaElement = <a href={media.url}>View video attachment ({bytesToSize(media.size)})</a>;
                } else if (ImageFileTypes.includes(media.type)) {
                    primaryText = 'Photo of Incident';
                    mediaElement = <img alt="incident" src={media.url} className={classes.image} />;
                } else {
                    primaryText = 'Document of Incident';
                    mediaElement = <a href={media.url}>View attachment ({bytesToSize(media.size)})</a>;
                }

                return (
                    <RoundedSection key={`media-${media.id}`} classes={{ root: classes.roundedSection }}>
                        <RoundedSectionBody>
                            <RoundedSectionLabel primary={primaryText} secondary={mediaElement} />
                        </RoundedSectionBody>
                    </RoundedSection>
                );
            })}
        </Page>
    );
};

export default withStyles(styles)(IncidentReport);
