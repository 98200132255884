import React from 'react';

// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Modules
import queryString from 'query-string';
import { API } from 'aws-amplify';
import Formalize from '@fyresite/formalize';
import Upload from 'utils/Upload';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import { ChromePicker } from 'react-color';
import FormValidator from 'utils/FormValidator';
import Mixpanel from 'mixpanel-browser';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Grid, Typography } from '@material-ui/core';

// Components
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';
import FileInput from 'components/Input/FileInput';
import Input from 'components/Input';
import Page from 'components/Page';
import Select from 'components/Input/Select';
import Skeleton from 'components/Skeleton';
import ThemePreview from 'components/ThemePreview';

// Variables
import FORM_INIT from './init';

// styles
import styles from './styles';

class Dashboard extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);

        // Permissional select
        const { cognitoUser } = this.props;
        this.ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;

        this.query = queryString.parse(this.props.history.location.search);
        this.state = {
            Organizations: { data: [], count: 0 },
            submitting: false,
            loading: !isNaN(parseInt(props.match.params.id)),
            organization: this.ParentOrgId || this.query.organization || -1,
            ...cloneDeep(this.INIT),
        };
    }

    componentDidMount() {
        this.mounted = true;
        !this.ParentOrgId && this._getOrganizations();
        this.state.organization !== -1 && this._getSystemSettings();
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async _getSystemSettings() {
        const { setSnackbar } = this.props;

        this.setState({ loading: true, ...cloneDeep(this.INIT) });
        try {
            const settings = {};
            const response = await API.get('ClutchAPI', `/organizations/${this.state.organization}/settings`);
            if (!response.count) {
                this.setState({
                    loading: false,
                    organization: -1,
                });
                this.props.history.push(`/system-setup`);
                return;
            }
            Object.keys(response.data).forEach((key) => (settings[key] = response.data[key].value));

            this.setState({
                loading: false,
                ...Formalize(this.INIT, settings),
            });
        } catch (error) {
            setSnackbar(checkError(error));
            this.setState({ loading: false, organization: -1 });
        }
    }

    async _getOrganizations() {
        const { setSnackbar } = this.props;
        try {
            const Organizations = await API.get('ClutchAPI', '/organizations');
            this.setState({ Organizations });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    async handleSubmit(e) {
        e.preventDefault();

        const { setSnackbar } = this.props;

        const invalidFields = FormValidator(this.refs, this.handleChange);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        const formData = new FormData();
        formData.append('maxGuestAllowed', this.state.maxGuestAllowed.value);
        formData.append('backgroundColor', this.state.backgroundColor.value);
        formData.append('textColor', this.state.textColor.value);
        formData.append('logoDisplaySize', this.state.logoDisplaySize.value);
        formData.append('eventDateChangeTimeLimit', this.state.eventDateChangeTimeLimit.value);
        formData.append('daysBeforeEventCreate', this.state.daysBeforeEventCreate.value);
        formData.append('beverageEducationOn', this.state.beverageEducationOn.value);
        if (typeof this.state.logo.value === 'object' && !this.state.logo.value.id) {
            formData.append('logo', this.state.logo.value);
        }
        if (typeof this.state.eventPhoto.value === 'object' && !this.state.eventPhoto.value.id) {
            formData.append('eventPhoto', this.state.eventPhoto.value);
        }

        this.setState({ submitting: true });
        try {
            await Upload.put(`/organizations/${this.state.organization}/settings`, formData);
            Mixpanel.track('Organization settings updated');
            setSnackbar('Settings updated');
        } catch (error) {
            console.log(error);
            setSnackbar(checkError(error));
        }
        this.setState({ submitting: false });
    }

    handleChange = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes, history } = this.props;
        const {
            backgroundColor,
            daysBeforeEventCreate,
            eventDateChangeTimeLimit,
            eventPhoto,
            id,
            loading,
            logo,
            logoDisplaySize,
            maxGuestAllowed,
            organization,
            Organizations,
            submitting,
            textColor,
        } = this.state;

        return (
            <Page maxWidth={600} topNavigationProps={{ pageTitle: 'System Settings' }}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Grid container spacing={2}>
                        {!this.ParentOrgId && (
                            <Grid item xs={12}>
                                <Select
                                    value={organization}
                                    onChange={(e) =>
                                        this.setState({ organization: e.target.value }, () => {
                                            // Allow the state to be "saved" in the url
                                            history.push(
                                                `?${queryString.stringify({
                                                    organization: e.target.value,
                                                })}`
                                            );
                                            this._getSystemSettings();
                                        })
                                    }
                                    marginBottom={10}
                                    classes={{ root: classes.selectHeight }}
                                    disabled={Organizations.data.length === 0 || !!id}
                                    typography={'Organization'}>
                                    <MenuItem value={-1}>Select an Organization</MenuItem>
                                    {Organizations.data.map((org) => {
                                        return (
                                            <MenuItem key={`org-${org.id}`} value={org.id}>
                                                {org.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                        )}
                        {organization === -1 ? null : loading ? (
                            <Grid item xs={12}>
                                <Skeleton type="infoPage" />
                            </Grid>
                        ) : (
                            <React.Fragment>
                                {/* # of Guests Allowed */}
                                <Grid item xs={12}>
                                    <Input
                                        ref="maxGuestAllowed"
                                        value={maxGuestAllowed.value}
                                        valid={maxGuestAllowed.valid}
                                        type="number"
                                        errorMessage="Max Guest Allowed must be greater then 0"
                                        validator={() => maxGuestAllowed.value >= 0}
                                        onChange={(e) =>
                                            this.handleChange({
                                                field: 'maxGuestAllowed',
                                                value: parseInt(e.target.value),
                                            })
                                        }
                                        marginBottom={10}
                                        fullWidth
                                        typography="# of Guest Allowed"
                                    />
                                </Grid>
                                {/* Organization Logo */}
                                <Grid item xs={12}>
                                    <FileInput
                                        ref="logo"
                                        imageBackgroundColor={backgroundColor.value}
                                        file={logo.value}
                                        valid={logo.valid}
                                        onChange={(file) =>
                                            this.handleChange({
                                                field: 'logo',
                                                value: file,
                                                expectedType: 'object',
                                            })
                                        }
                                        marginBottom={10}
                                        label="Organization Logo"
                                    />
                                    <Typography variant="caption">
                                        Please use this recommended file size, no larger than 5 MB and 1000x800
                                    </Typography>
                                </Grid>
                                {/* Logo Display Size */}
                                <Grid item xs={12}>
                                    <Typography variant="h6">Logo Display Size</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Checkbox
                                        classes={{
                                            root: classes.checkboxRoot,
                                        }}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.handleChange({
                                                    field: 'logoDisplaySize',
                                                    value: 'default',
                                                });
                                            }
                                        }}
                                        label="Default"
                                        checked={logoDisplaySize.value === 'default'}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Checkbox
                                        classes={{
                                            root: classes.checkboxRoot,
                                            label: classes.checkboxLabel,
                                        }}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.handleChange({
                                                    field: 'logoDisplaySize',
                                                    value: 'large',
                                                });
                                            }
                                        }}
                                        label="Large"
                                        checked={logoDisplaySize.value === 'large'}
                                    />
                                </Grid>
                                {/* Event Date Change Time Limit */}
                                <Grid item md={6} xs={12}>
                                    <Input
                                        ref="eventDateChangeTimeLimit"
                                        value={eventDateChangeTimeLimit.value}
                                        valid={eventDateChangeTimeLimit.valid}
                                        type="number"
                                        errorMessage="Event date change time limit must be greater then 0"
                                        validator={() => eventDateChangeTimeLimit.value >= 0}
                                        onChange={(e) =>
                                            this.handleChange({
                                                field: 'eventDateChangeTimeLimit',
                                                value: parseInt(e.target.value),
                                            })
                                        }
                                        marginBottom={10}
                                        fullWidth
                                        typography="Event Date Change Time Limit"
                                    />
                                </Grid>
                                {/* Days Before Event Create */}
                                <Grid item md={6} xs={12}>
                                    <Input
                                        ref="daysBeforeEventCreate"
                                        value={daysBeforeEventCreate.value}
                                        valid={daysBeforeEventCreate.valid}
                                        type="number"
                                        errorMessage="Days before event create must be greater then 0"
                                        validator={() => daysBeforeEventCreate.value >= 0}
                                        onChange={(e) =>
                                            this.handleChange({
                                                field: 'daysBeforeEventCreate',
                                                value: parseInt(e.target.value),
                                            })
                                        }
                                        marginBottom={10}
                                        fullWidth
                                        typography="Days Before Event Create"
                                    />
                                </Grid>
                                {/* Theme Preview */}
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Please make your theme accessible for others to read
                                    </Typography>
                                    <ThemePreview
                                        backgroundColor={backgroundColor.value}
                                        textColor={textColor.value}
                                        logo={logo.value}
                                        logoSize={logoDisplaySize.value}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Typography variant="h6">Background Color</Typography>
                                    <ChromePicker
                                        disableAlpha={true}
                                        className={classes.colorPicker}
                                        color={backgroundColor.value}
                                        onChange={(color, event) =>
                                            this.handleChange({
                                                field: 'backgroundColor',
                                                value: color.hex,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Typography variant="h6">Text Color</Typography>
                                    <ChromePicker
                                        disableAlpha={true}
                                        className={classes.colorPicker}
                                        color={textColor.value}
                                        onChange={(color, event) =>
                                            this.handleChange({
                                                field: 'textColor',
                                                value: color.hex,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput
                                        ref="eventPhoto"
                                        imageBackgroundColor={backgroundColor.value}
                                        file={eventPhoto.value}
                                        valid={eventPhoto.valid}
                                        onChange={(file) =>
                                            this.handleChange({
                                                field: 'eventPhoto',
                                                value: file,
                                                expectedType: 'object',
                                            })
                                        }
                                        marginBottom={10}
                                        label="Default Event Photo"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                                    <Button
                                        fullWidth
                                        text="Save"
                                        onClick={this.handleSubmit.bind(this)}
                                        variant="contained"
                                        variantType="containedBlue"
                                        disableElevation
                                        loading={submitting}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </form>
            </Page>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
const actions = { setSnackbar };

export default connect(mapStateToProps, actions)(withStyles(styles)(Dashboard));
