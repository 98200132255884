import { grey, colors } from './global-styles';
var colorManipulator = require('./utils/style-utils');

const theme = {
    mixins: {
        toolbar: {
            minHeight: 111,
        },
    },
    palette: {
        primary: {
            main: '#454c5d',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#e5035b',
            contrastText: '#ffffff',
        },
        error: {
            main: '#e50000',
            contrastText: '#ffffff',
        },
        tuna: {
            main: '#303542',
            contrastText: '#ffffff',
        },
        shark: {
            main: '#1e2027',
            contrastText: '#ffffff',
        },
        success: {
            main: 'rgba(130, 201, 88, 1.0)', // hex #77b337
            contrastText: '#ffffff',
        },
    },
    typography: {
        fontFamily: `"Lato", "Roboto", "sans-serif"`,
        useNextVariants: true, // https://material-ui.com/style/typography/#migration-to-typography-v2
        h3: {
            fontSize: 20,
            fontWeight: 400,
            color: '#4a4a4a',
        },
        h4: {
            fontSize: 18,
            fontWeight: 400,
            color: '#222222',
        },
        h5: {
            fontSize: 16,
            fontWeight: 400,
            color: '#222222',
        },
        h6: {
            fontSize: 11,
            textTransform: 'uppercase',
            fontWeight: 700,
            color: '#4f5668',
            opacity: 0.7,
            marginBottom: 10,
        },
        subtitle1: {
            color: '#4f5668',
            fontSize: 11,
            fontWeight: 700,
            textTransform: 'uppercase',
            opacity: 0.6,
            marginBottom: 5,
        },
        subtitle2: {
            color: '#4f5668',
            fontSize: 16,
            fontWeight: 400,
        },
        body2: {
            color: '#4f5668',
            fontSize: 14,
            fontWeight: 400,
        },
    },
    overrides: {
        MuiTableSortLabel: {
            active: {
                color: `#fff`,
                textDecoration: 'underline',
            },
            icon: {
                color: `#fff`,
                textDecoration: 'underline',
            },
        },
        MuiButton: {
            root: {
                height: 62,
                textTransform: 'unset',
                borderRadius: 6,
            },
            outlined: {
                borderWidth: 2,
                boxShadow: 'none',
                fontWeight: 400,
            },
            outlinedPrimary: {
                borderColor: grey,
                borderWidth: 2,
                color: grey,
                '&:hover': {
                    borderColor: grey,
                    borderWidth: 2,
                    color: grey,
                },
            },
            label: {
                fontSize: 16,
            },
            sizeLarge: {
                height: 60,
            },
        },
        MuiPaper: {
            elevation2: {
                boxShadow: '0 7px 25px 0 rgba(0,0,0,0.12)',
            },
            rounded: {
                borderRadius: 6,
            },
        },
        MuiInput: {
            root: {
                paddingBottom: 2,
                // paddingTop: 10,
            },
            underline: {
                // paddingBottom: 10,
            },
        },
        MuiInputBase: {
            inputAdornedEnd: {
                // padding: 0,
            },
            root: {
                backgroundColor: 'white',
            },
        },
        MuiDivider: {
            root: {
                // backgroundColor: "rgba(0,0,0,0.42)",
            },
        },
    },
    buttons: {
        outlined: {
            outerSpace: {
                padding: '9px 30px',
                color: colors.brandColors.outerSpace,
                border: `1px solid ${colors.brandColors.outerSpace}`,
                '&:hover': {
                    border: `3px solid ${colors.brandColors.outerSpace}`,
                    filter: 'saturate(150%)',
                    backgroundColor: colorManipulator.changeColorOpacity(colors.brandColors.outerSpace, 0.2),
                    background: colorManipulator.changeColorOpacity(colors.brandColors.outerSpace, 0.2),
                    '& svg.MuiCircularProgress-svg': {
                        color: colors.brandColors.outerSpace,
                    },
                },
            },
            grey: {
                padding: '9px 30px',
                color: colors.brandColors.grey,
                border: `1px solid ${colors.brandColors.grey}`,
                '&:hover': {
                    border: `3px solid ${colors.brandColors.grey}`,
                    filter: 'saturate(150%)',
                    backgroundColor: colorManipulator.changeColorOpacity(colors.brandColors.grey, 0.2),
                    background: colorManipulator.changeColorOpacity(colors.brandColors.grey, 0.2),
                    '& svg.MuiCircularProgress-svg': {
                        color: colors.brandColors.grey,
                    },
                },
            },
            dashedGrey: {
                padding: '9px 30px',
                color: colors.brandColors.grey,
                border: `1px dashed ${colors.brandColors.grey}`,
                '&:hover': {
                    border: `3px dashed ${colors.brandColors.grey}`,
                    filter: 'saturate(150%)',
                    backgroundColor: colorManipulator.changeColorOpacity(colors.brandColors.grey, 0.2),
                    background: colorManipulator.changeColorOpacity(colors.brandColors.grey, 0.2),
                    '& svg.MuiCircularProgress-svg': {
                        color: colors.brandColors.grey,
                    },
                },
            },
        },
        contained: {
            outerSpace: {
                color: '#fff',
                backgroundColor: colors.brandColors.outerSpace,

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)(colors.brandColors.outerSpace, 0.3),
                },
            },
            blue: {
                color: '#fff',
                backgroundColor: colors.brandColors.metallicBlue,

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)(colors.brandColors.metallicBlue, 0.3),
                },
            },
            red: {
                color: '#fff',
                backgroundColor: colors.brandColors.red,

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)(colors.brandColors.red, 0.3),
                },
            },
            green: {
                color: '#fff',
                backgroundColor: colors.brandColors.green,

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)(colors.brandColors.green, 0.3),
                },
            },
        },
    },
};
// theme.overrides.MuiButton.containedPrimary = {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
// };

// theme.overrides.MuiButton.outlinedPrimary = {
//     borderColor: theme.palette.primary.main,
//     color: theme.palette.primary.main,
// };
export default theme;
