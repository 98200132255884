import React from 'react';
// Modules
import clsx from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Wysi
import { Editor } from 'react-draft-wysiwyg';
// Styles
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './styles';

class Wysiwyg extends React.Component {
    render() {
        const { classes, valid = true, errorMessage, validator, label, marginBottom, ...rest } = this.props;

        let bottomMargin = marginBottom ? { marginBottom } : {};

        const wrapper = clsx(classes.wrapper, {
            [classes.error]: !valid,
        });
        const editor = clsx(classes.editor, {
            [classes.error]: !valid,
        });
        const toolbar = clsx(classes.toolbar, {
            [classes.error]: !valid,
        });

        return (
            <div className={classes.container} style={bottomMargin}>
                {label && <Typography variant="h6">{label}</Typography>}
                <Editor
                    ref="editor"
                    wrapperClassName={wrapper}
                    toolbarClassName={toolbar}
                    editorClassName={editor}
                    {...rest}
                />
                {!valid && errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
            </div>
        );
    }
}

export default withStyles(styles)(Wysiwyg);
