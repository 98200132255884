// React
import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import clsx from 'clsx';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
    SvgIcon,
} from '@material-ui/core';
import { DeleteForever, Menu, Cancel, CheckCircle, Info } from '@material-ui/icons';
import TransitionAlert from 'components/TransitionAlert';
// Constants
import { policyIdentifiers } from 'global-constants';
// Styles
import styles from './styles';

const getItemStyle = (isDragging, draggableStyle) => ({
    border: isDragging ? '3px solid rgba(48,53,66, .5)' : '1px #00000017 solid',
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#3036424d' : 'transparent',
    height: '90%',
    minHeight: '400px',
});

const ProcessList = ({ classes, approvalProcess = [], ...props }) => {
    return (
        <>
            <div className={classes.root}>
                <div className={classes.transitionAlertContainer}>
                    <TransitionAlert
                        className={classes.transitionAlert}
                        variant={'outlined'}
                        color={'info'}
                        icon={<Info />}>
                        NOTE: Any changes to the review process will reset the process for all events with the review
                        status "Submitted".
                    </TransitionAlert>
                </div>
                <div className={classes.pageContent}>
                    <div className={classes.header}>
                        <Typography className={classes.firstTitle}>
                            Reviewer Role
                            <Tooltip
                                arrow
                                title="Organization Roles which are associated to the Event Reviewer Permissions show up at on this list">
                                <Info className={classes.infoIcon} />
                            </Tooltip>
                        </Typography>
                        <Typography className={classes.alignCenter}>View</Typography>
                        <Typography className={classes.alignCenter}>Comment</Typography>
                        <Typography className={classes.alignCenter}> Required</Typography>
                        <Typography className={classes.alignCenter}>Final</Typography>
                        <Typography className={classes.alignCenter}>Actions</Typography>
                    </div>
                    <Droppable className={classes.dropContainer} droppableId="roles-droppable">
                        {(dropProvided, dropSnapshot) => (
                            <div
                                className={classes.list}
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}
                                style={getListStyle(dropSnapshot.isDraggingOver)}
                                {...dropProvided.droppableProps}>
                                {approvalProcess.map((process, index) => {
                                    const reviewTypes = {};
                                    Array.isArray(process.role.policies) &&
                                        process.role.policies.forEach((policy) => {
                                            if (policy.identifier === policyIdentifiers.FINAL_EVENT_APPROVER) {
                                                reviewTypes.finalAppover = true;
                                            } else if (
                                                policy.identifier === policyIdentifiers.EVENT_APPROVAL_REQUIRED
                                            ) {
                                                reviewTypes.approvalRequired = true;
                                            } else if (policy.identifier === policyIdentifiers.VIEW_EVENTS) {
                                                reviewTypes.viewer = true;
                                            } else if (policy.identifier === policyIdentifiers.COMMENT_ON_EVENTS) {
                                                reviewTypes.commenter = true;
                                            }
                                        });

                                    return (
                                        <Draggable
                                            key={`process-${process.RoleId}`}
                                            draggableId={`process-${process.RoleId}`}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={classes.listItem}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}>
                                                    <List>
                                                        <ListItem>
                                                            <div className={classes.listWrapper}>
                                                                <div className={classes.title}>
                                                                    <ListItemIcon>
                                                                        <Menu />
                                                                    </ListItemIcon>

                                                                    <Typography>{process.role.name}</Typography>
                                                                </div>
                                                                <SvgIcon
                                                                    className={clsx(classes.svgStatus, {
                                                                        [classes.statusCircleGreen]: reviewTypes.viewer,
                                                                    })}
                                                                    component={
                                                                        reviewTypes.viewer ? CheckCircle : Cancel
                                                                    }
                                                                />
                                                                <SvgIcon
                                                                    className={clsx(classes.svgStatus, {
                                                                        [classes.statusCircleGreen]:
                                                                            reviewTypes.commenter,
                                                                    })}
                                                                    component={
                                                                        reviewTypes.commenter ? CheckCircle : Cancel
                                                                    }
                                                                />
                                                                <SvgIcon
                                                                    className={clsx(classes.svgStatus, {
                                                                        [classes.statusCircleGreen]:
                                                                            reviewTypes.approvalRequired,
                                                                    })}
                                                                    component={
                                                                        reviewTypes.approvalRequired
                                                                            ? CheckCircle
                                                                            : Cancel
                                                                    }
                                                                />
                                                                <SvgIcon
                                                                    className={clsx(classes.svgStatus, {
                                                                        [classes.statusCircleGreen]:
                                                                            reviewTypes.finalAppover,
                                                                    })}
                                                                    component={
                                                                        reviewTypes.finalAppover ? CheckCircle : Cancel
                                                                    }
                                                                />

                                                                <div className={classes.actionIcon}>
                                                                    <IconButton
                                                                        classes={{ root: classes.deleteIcon }}
                                                                        disabled={process.loading}
                                                                        className={classes.delete}
                                                                        onClick={() => props.onDelete(process)}>
                                                                        {process.loading ? (
                                                                            <CircularProgress
                                                                                style={{
                                                                                    height: 24,
                                                                                    width: 24,
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <DeleteForever />
                                                                        )}
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </ListItem>
                                                    </List>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {dropProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </>
    );
};

export default connect(null, { setSnackbar })(withStyles(styles)(ProcessList));
