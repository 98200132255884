const padding = 24;

const styles = (theme) => ({
    root: {
        borderBottom: '1px solid rgba(151, 151, 151, 0.3)',
        padding,
    },
});

export default styles;
