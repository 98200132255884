export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const INIT = {};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_ORGANIZATION:
            return payload;
        default:
            return state;
    }
}
