import { Auth } from "aws-amplify";

/* eslint-disable no-restricted-globals */
// AWS Project Constants
const awsmobile = {
  Auth: {
    identityPoolId: "us-east-1:aa21bd82-271a-4c8c-815e-9a8688c45264",
    userPoolId: "us-east-1_7YWYNx5d7",
    userPoolWebClientId: "29ko5d69d907df172jv2ph2b6e",

    region: "us-east-1",
    oauth: {
      domain: "clutch-dev.auth.us-east-1.amazoncognito.com",
      scope: ["email", "profile", "openid"],
      redirectSignIn: `${location.origin}/authorizing`,
      redirectSignOut: location.origin, // This is what is causing the hard refresh on signout, possible solution is to intercept this request and route through the History API using window.onbeforeunload
      responseType: "code",
    },
  },
  APIV1Endpoint: "https://dev3.api.myclutch.online/api/v1",
  PublicAPIEndpoint: "https://dev3.api.myclutch.online/api/v1",
  API: {
    endpoints: [
      {
        name: "ClutchAPI",
        endpoint: "https://dev3.api.myclutch.online/api/v1",
        region: "us-east-1",
        custom_header: async () => {
          const user = await Auth.currentSession();
          console.log(user);
          return {
            Authorization: user.idToken.jwtToken,
          };
        },
      },
    ],
  },
};

if (typeof location !== "undefined") {
  awsmobile.Auth.cookieStorage = {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: location.hostname,
    // OPTIONAL - Cookie path
    path: "/",
    // OPTIONAL - Cookie expiration in days
    // expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: location.protocol === "https:",
  };
}

export default awsmobile;
