import mixPanelConfigTest from './mixpanelconfig.test';
import mixPanelConfigDev from './mixpanelconfig.dev';
import mixPanelConfigStage from './mixpanelconfig.staging';
import mixPanelConfigProd from './mixpanelconfig.prod';

let mixPanelConfig;
// load the correct aws config options

switch (process.env.REACT_APP_ENV) {
    case 'production':
        mixPanelConfig = mixPanelConfigProd;
        break;
    case 'staging':
        mixPanelConfig = mixPanelConfigStage;
        break;
    case 'development':
        mixPanelConfig = mixPanelConfigDev;
        break;
    case 'test':
        mixPanelConfig = mixPanelConfigTest;
        break;
    default:
        mixPanelConfig = mixPanelConfigDev;
}
export { mixPanelConfig };
