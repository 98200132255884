import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';

// Utils
import FormValidator from 'utils/FormValidator';

// Redux
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slide,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

// Components
import Button from 'components/Button';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';

// Data
import FORM_INIT from './init';

// Styles
import styles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class EditEventTypeDialog extends React.Component {
    constructor(props) {
        super(props);

        this.INIT = FORM_INIT;

        this.state = {
            submitting: false,
            ...cloneDeep(this.INIT),
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                name: {
                    value: this.props.value.name,
                    valid: true,
                },
                bypassApproval: {
                    value: !!this.props.value.bypassApproval,
                    valid: true,
                },
            });
        }
    }

    handleClose = () => {
        if (!this.state.submitting && typeof this.props.onClose === 'function') this.props.onClose();
    };

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ submitting: true });

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            this.setState({ submitting: false });
            return this.props.setSnackbar(_invalidField.message, 'error');
        }

        if (typeof this.props.onSubmit === 'function') {
            try {
                await this.props.onSubmit({
                    name: this.state.name.value,
                    bypassApproval: this.state.bypassApproval.value,
                });
                this.setState({ submitting: false });
                this.setState(this.INIT);
            } catch (error) {
                /** Do nothing */
            }
        }
    };

    render() {
        const { classes, open, errorMessage, title, subtitle, placeholder, button = {} } = this.props;
        const { name, bypassApproval, submitting } = this.state;

        return (
            <Dialog
                open={open}
                classes={{ paper: classes.paper }}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <IconButton className={classes.closeButton} onClick={this.handleClose}>
                    <Close />
                </IconButton>
                <DialogTitle disableTypography classes={{ root: classes.title }}>
                    {title}
                </DialogTitle>
                <form onSubmit={this.handleSubmit}>
                    <DialogContent className={classes.content}>
                        <DialogContentText classes={{ root: classes.subtitle }}>{subtitle}</DialogContentText>
                        <Checkbox
                            marginBottom={10}
                            classes={{ root: classes.checkbox }}
                            label={'No Approval Required'}
                            checked={bypassApproval.value}
                            useCustomIcons={false}
                            onChange={(e) => {
                                this.updateField({
                                    field: 'bypassApproval',
                                    value: e.target.checked,
                                });
                            }}
                        />
                        <Input
                            id="name"
                            ref="name"
                            placeholder={placeholder}
                            fullWidth={true}
                            marginBottom={12}
                            value={name.value}
                            onChange={(e) => {
                                this.updateField({
                                    field: 'name',
                                    value: e.target.value,
                                });
                            }}
                            validator={() => name.value.length > 0}
                            error={!name.valid}
                            errorMessage={errorMessage}
                        />
                    </DialogContent>
                    <DialogActions classes={{ root: classes.actions }}>
                        <Button
                            color="primary"
                            onClick={this.handleSubmit}
                            loading={submitting}
                            text="Create"
                            variant="contained"
                            fullWidth={true}
                            {...button}
                        />
                    </DialogActions>
                    <input ref="submit" type="submit" style={{ display: 'none' }} />
                </form>
            </Dialog>
        );
    }
}

export default withStyles(styles)(connect(null, { setSnackbar })(EditEventTypeDialog));
