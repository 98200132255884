const styles = (theme) => ({
    root: {
        display: 'inline-block',
    },
    margin: {
        marginBottom: 20,
    },
    marginTop: {
        marginTop: 20,
    },

    primary: {
        color: '#4f5668',
        fontSize: 11,
        fontWeight: 700,
        textTransform: 'uppercase',
        opacity: 0.6,
        marginBottom: 5,
    },
    secondary: {
        color: '#4f5668',
        fontSize: 16,
        fontWeight: 400,
    },
});

export default styles;
