const styles = (theme) => ({
    root: {
        padding: 20,
        backgroundColor: 'orange',
    },
    bar: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    preview: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: 80,
    },

    logo: {
        display: 'flex',
        justifyContent: 'center',
        flexBasis: '34%',
        '& img': { height: 30 },
        [theme.breakpoints.down('sm')]: {
            '& img': { height: 20 },
        },
    },
    large: {
        '& img': {
            height: 50,
        },
        [theme.breakpoints.down('sm')]: {
            '& img': { height: 40 },
        },
    },
    left: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexBasis: '33%',
    },
    right: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexBasis: '33%',
    },
});

export default styles;
