import { SET_THEME } from 'redux/actions/theme';

import defaultTheme from 'theme';

export const INIT = defaultTheme;

export default function (state = INIT, { type, payload }) {
    let newState = Object.assign({}, state);

    switch (type) {
        case SET_THEME:
            // merge the theme settings together...
            // please use `primary` or `secondary` keys on payload,
            // as these are the keys we will use throughout the app.
            newState.settings = Object.assign({}, newState.settings, payload);

            // case for removing custom theme colors (for logout)
            if (!payload) {
                delete newState.settings;
            }

            return newState;

        default:
            return state;
    }
}
