import { colors } from 'global-styles';

const styles = (theme) => ({
    root: {
        display: 'block',
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    paper: {
        boxSizing: 'border-box',
        height: '100%',
        padding: '40px 20px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    dropdown: {
        position: 'absolute',
        top: 40,
        right: -12,
        display: 'flex',
        flexDirection: 'column',
        width: 185,
        borderWidth: 1,
        borderColor: colors.gray,
        borderStyle: 'solid',
        zIndex: 999,
    },
    button: {
        borderRadius: 0,
        color: colors.brandColors.grey,
        fontWeight: 700,
        height: 50,
    },
    middle: {
        borderTop: `1px solid ${colors.gray}`,
        borderBottom: `1px solid ${colors.gray}`,
    },
    bottom: {
        color: colors.brandColors.red,
    },
    triangle: {
        position: 'absolute',
        top: 28,
        right: 12,
        zIndex: 999,

        borderLeft: `${12}px solid transparent`,
        borderRight: `${12}px solid transparent`,

        borderBottom: `${12}px solid ${colors.gray}`,

        width: 0,
        height: 0,
    },
    triangleInner: {
        position: 'absolute',
        top: 29,
        right: 12,
        zIndex: 1000,

        borderLeft: `${12}px solid transparent`,
        borderRight: `${12}px solid transparent`,

        borderBottom: `${12}px solid white`,

        width: 0,
        height: 0,
    },
});

export default styles;
