import React, { useEffect, useState } from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import classNames from 'classnames';

import { Close, Warning, Info, ErrorOutline, CheckCircle } from '@material-ui/icons';
import { Snackbar, SnackbarContent, IconButton, withStyles, Button as MuiButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import styles from './styles';

const AUTO_HIDE_TIME = 5 * 1000; // 5 seconds
const variantIcon = {
    success: CheckCircle,
    warning: Warning,
    error: ErrorOutline,
    info: Info,
    prompt: Info,
};
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const StyledSnackbar = (props) => {
    const {
        className,
        classes,
        message,
        setSnackbar,
        options = {
            severity: null,
            disableAutoHide: false,
            snackbarButtonProps: null,
        },
    } = props;
    const { severity, disableAutoHide, snackbarButtonProps } = options;
    const [autoHide, setAutoHide] = useState(parseInt(AUTO_HIDE_TIME));
    const [resumeAutoHide, setResumeAutoHide] = useState(false);
    useEffect(() => {
        if (disableAutoHide) setAutoHide(null);
        if (resumeAutoHide) setAutoHide(1);
    }, [options, autoHide, resumeAutoHide]);

    function handleClose(event, reason) {
        // do not close on click away
        if (reason === 'clickaway') return;

        setSnackbar('');
    }

    function handleButtonClick(e) {
        setResumeAutoHide(true);
        if (typeof snackbarButtonProps.onClick === 'function') snackbarButtonProps.onClick();
    }

    let open = true;
    if (message === '') open = false;
    const snackbarClasses = classNames({
        [classes.snackbar]: true,
        [classes.warning]: severity === 'warning',
        [classes.error]: severity === 'error',
        [classes.info]: severity === 'info',
        [classes.info]: severity === 'success',
        [className]: !!className,
    });
    return (
        <Snackbar
            className={snackbarClasses}
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={autoHide}
            onClose={handleClose}
            action={
                <IconButton color="inherit" aria-label="close snackbar" onClick={handleClose}>
                    <Close />
                </IconButton>
            }>
            {severity ? (
                <Alert
                    classes={{
                        root: classes.snackbar,
                        filledInfo: classes.customInfo,
                        icon: classes.MuiAlertIcon,
                    }}
                    className={classes.muiAlert}
                    onClose={handleClose}
                    severity={severity}>
                    {message}

                    {snackbarButtonProps ? (
                        <MuiButton
                            classes={{
                                root: classes.snackbarButton,
                                label: classes.snackbarButtonLabel,
                                endIcon: classes.customEndIcon,
                            }}
                            endIcon={snackbarButtonProps.icon ? snackbarButtonProps.icon : null}
                            onClick={handleButtonClick}>
                            {snackbarButtonProps.label}
                        </MuiButton>
                    ) : null}
                </Alert>
            ) : (
                <SnackbarContent
                    action={
                        <IconButton color="inherit" aria-label="close snackbar" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    }
                    message={<span>{message}</span>}
                />
            )}
        </Snackbar>
    );
};

const mapStateToProps = ({ snackbar }) => ({ ...snackbar });

export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(StyledSnackbar));
