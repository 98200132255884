import React from 'react';
// Modules
import classNames from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Button as MaterialButton, CircularProgress } from '@material-ui/core/';
// Styles
import styles from './styles';

const Button = ({ classes, className, children, text, variant, variantType, loading, lowerCase, size, ...props }) => {
    let MaterialButtonClasses = classNames({
        [classes.root]: true,
        [classes[variantType]]: variantType,
    });

    let CircularProgressClasses = classNames({
        [classes.loadingContained]: loading && variant === 'contained',
    });

    return (
        <MaterialButton
            className={classNames(MaterialButtonClasses)}
            variant={variant}
            component="button"
            disableElevation
            classes={{ startIcon: classes.startIcon }}
            {...props}>
            {loading ? (
                <CircularProgress className={CircularProgressClasses} size={25} color="inherit" />
            ) : (
                <span className={classes.text}>
                    {text} {children}
                </span>
            )}
        </MaterialButton>
    );
};

export default withStyles(styles)(Button);
