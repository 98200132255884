const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    authForm: {
        margin: '0 auto',
        maxWidth: 400,
    },
    mt6: {
        marginTop: 6,
    },
    input: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

export default styles;
