import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { InputAdornment, Typography, TextField } from '@material-ui/core';

// Styles
import styles from './styles';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputType: props.type || 'text',
        };
    }

    toggleType() {
        this.setState({
            inputType: this.state.inputType === 'text' ? 'password' : 'text',
        });
    }

    render() {
        const {
            type,
            valid = true,
            errorMessage,
            validator,
            classes,
            marginBottom,
            maxLength,
            inputProps = {},
            variant = 'outlined',
            typography,
            ...rest
        } = this.props;

        if (type === 'password') {
            if (!rest.InputProps) {
                rest.InputProps = {};
            }
            rest.InputProps.endAdornment = (
                <InputAdornment className={classes.visibility} position="end">
                    {this.state.inputType === 'password' ? (
                        <Visibility className={classes.visibilityIcon} onClick={this.toggleType.bind(this)} />
                    ) : (
                        <VisibilityOff className={classes.visibilityIcon} onClick={this.toggleType.bind(this)} />
                    )}
                </InputAdornment>
            );
        }

        let bottomMargin = marginBottom ? { marginBottom } : {};
        if (maxLength) inputProps.maxLength = maxLength;

        return (
            // set defaults for text fields app-wide
            <>
                {typography && <Typography variant="h6">{typography}</Typography>}
                <TextField
                    style={bottomMargin}
                    type={this.state.inputType}
                    variant={variant}
                    error={!valid}
                    helperText={!valid && errorMessage}
                    inputProps={inputProps}
                    {...rest}
                />
            </>
        );
    }
}

export default withStyles(styles)(Input);
