import React from 'react';
// Modules
import JSONPretty from 'react-json-pretty';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
// Styles
import styles from './styles';
import { colors } from 'global-styles';

const IntegrationData = ({ classes, data, ...props }) => {
    return (
        <Paper className={classes.root}>
            <JSONPretty
                id="json-pretty"
                mainStyle="font-size: 16px"
                valueStyle={`color: ${colors.brandColors.red}`}
                stringStyle="color: green"
                data={data}
            />
        </Paper>
    );
};

export default withStyles(styles)(IntegrationData);
