const styles = (theme) => ({
    root: {},
    back: {
        marginBottom: 12,
    },
    paper: {
        padding: 20,
    },
    dateColumn: {
        marginBottom: 12,
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
    },
    dateText: {
        fontSize: 14,
        fontWeight: 700,
    },
    timeText: {
        fontSize: 12,
        fontWeight: 400,
        opacity: 0.5,
        color: '#2a2a2a',
    },
    hr: {
        flexGrow: 1,
        height: 1,
        backgroundColor: 'rgba(151, 151, 151, 0.3)',
        marginLeft: 12,
    },
    log: {
        display: 'flex',
        marginBottom: 12,
    },
    logInfo: {
        marginLeft: 12,
    },
    logTitle: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 4,
        color: '#2a2a2a',
    },
    logBody: {
        fontSize: 14,
        fontWeight: 400,
        color: '#2a2a2a',
        marginBottom: 8,
    },
});

export default styles;
