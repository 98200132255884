import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, TablePagination, TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from './styles';

const CustomizedTable = ({
    classes,
    onSort,
    count = 0,
    headers = [],
    rowsPerPage = 25,
    page,
    rows,
    onChangePage,
    onChangeRows,
    initialOrder = 'desc',
    initialOrderIndex = 2,
    ...props
}) => {
    const [order, setOrder] = useState(initialOrder);
    const [orderBy, setOrderBy] = useState(initialOrderIndex); // 2 is the index for the column 'date'

    function handleRequestSort(e, index) {
        let newOrder = order;
        if (orderBy === index) {
            if (order === 'desc') {
                newOrder = 'asc';
                setOrder(newOrder);
            } else {
                newOrder = 'desc';
                setOrder(newOrder);
            }
        }
        setOrderBy(index);

        if (typeof onSort === 'function') {
            onSort(newOrder, index);
            return;
        }
    }

    function handlePageChange(e, page) {
        if (typeof onChangePage !== 'undefined') {
            onChangePage(e, page);
        }
    }

    function handleChangeRowsPerPage(e) {
        if (typeof onChangeRows !== 'undefined') {
            onChangeRows(e);
        }
    }

    return (
        <React.Fragment>
            <TableContainer className={classes.rounded} component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell classes={{ body: classes.body, head: classes.head }} key={`header-${index}`}>
                                    <Tooltip title="Sort" enterDelay={300}>
                                        <TableSortLabel
                                            active={orderBy === index}
                                            direction={order}
                                            onClick={(e) => handleRequestSort(e, index)}
                                            classes={{
                                                root: classes.tableSortLabel,
                                                active: classes.tableSortLabelActive,
                                                icon: classes.tableSortLabelActive,
                                            }}>
                                            {header}
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rindex) => {
                            return (
                                <TableRow key={`row-${rindex}`}>
                                    {row.columns.map((column, cindex) => {
                                        return (
                                            <TableCell
                                                key={`${rindex}-col-${cindex}`}
                                                classes={{ body: classes.body, head: classes.head }}
                                                component="th"
                                                scope="row">
                                                {column}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <table className={classes.pagination}>
                <tbody>
                    <tr>
                        <TablePagination
                            count={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                root: classes.paginationRoot,
                            }}
                        />
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default withStyles(styles)(CustomizedTable);
