import React, { useState } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

// Components
import Button from 'components/Button';
import Input from 'components/Input';

// STyles
import styles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EmailDialog = ({ classes, open, onClose, onSubmit }) => {
    const [submitting, setSubmitting] = useState(false);
    const [input, setInput] = useState('');

    const handleClose = () => {
        if (!submitting && typeof onClose === 'function') onClose();
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        if (typeof onSubmit === 'function') {
            try {
                await onSubmit(input);
                setSubmitting(false);
                setInput('');
            } catch (error) {
                /** Do nothing */
            }
        }
    };

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle disableTypography classes={{ root: classes.title }}>
                {'Send Report'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText classes={{ root: classes.subtitle }}>
                    Provide a list of emails to send this report to.
                </DialogContentText>
                <Input
                    fullWidth
                    onChange={(e) => setInput(e.target.value)}
                    value={input}
                    placeholder="Provide a list of comma seperated emails."
                />
            </DialogContent>
            <DialogActions classes={{ root: classes.actions }}>
                <Button
                    text="Cancel"
                    classes={{ root: classes.action }}
                    variantType="outlinedOutlinedGrey"
                    disabled={submitting}
                    onClick={handleClose}
                />
                <Button
                    variantType="containedBlue"
                    classes={{ root: classes.action }}
                    onClick={handleSubmit}
                    loading={submitting}
                    text="Send"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(EmailDialog);
