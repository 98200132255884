import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

class HorizontalRulerItem extends React.Component {
    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                title: this.title,
                placeholder: this.placeholder,
                required: this.required,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.ruler} />
            </div>
        );
    }
}

export default withStyles(styles)(HorizontalRulerItem);
