import React from 'react';
// Mui-Core
import { withStyles, makeStyles } from '@material-ui/core/styles';
// Modules
import clsx from 'clsx';
// Components
import styles from './styles';
import { Tooltip, Typography } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { formatDate } from 'utils/string-utils';
const useCustomStyles = makeStyles((theme) => ({
    tooltip: {
        minWidth: 170,
        backgroundColor: 'rgba(0,0,0,.8)',
        color: '#fff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    arrow: {
        color: 'rgba(0,0,0,.8)',
    },
}));
function CustomTooltip(props) {
    const classes = useCustomStyles();

    return <Tooltip arrow classes={classes} {...props} />;
}

const ApprovalActivityToolbar = ({ classes, approvals, section, ...props }) => {
    let showApprovals = false;
    let showUnapprovals = false;

    if (approvals != null) {
        approvals.forEach((reviewer) => {
            if (reviewer.approved === true) showApprovals = true;
            if (reviewer.approved === false) showUnapprovals = true;
        });
    }

    // Refactor into a better function
    const generateNameList = () => {
        if (approvals != null) {
            let reviewCount = approvals.length;
            if (reviewCount === 1) {
                return approvals[0].name;
            }
            if (reviewCount === 2) {
                return (
                    <>
                        <span className={classes.listedNames}>{approvals[0].name}, and</span>
                        <span className={classes.listedNames}>{approvals[1].name}</span>
                    </>
                );
            }
            if (reviewCount === 3) {
                return (
                    <>
                        <span className={classes.listedNames}>{approvals[0].name}</span>,
                        <span className={classes.listedNames}>{approvals[1].name}, and </span>
                        <span className={classes.listedNames}>{approvals[2].name}</span>
                    </>
                );
            }
            if (reviewCount === 4) {
                return (
                    <>
                        <span className={classes.listedNames}>{approvals[0].name}</span>,
                        <span className={classes.listedNames}>{approvals[1].name}</span>,
                        <span className={classes.listedNames}>{approvals[2].name} and 1 other</span>
                    </>
                );
            }

            if (reviewCount > 4) {
                return (
                    <>
                        <span className={classes.listedNames}>{approvals[0].name}</span>,
                        <span className={classes.listedNames}>{approvals[1].name}</span>,
                        <span className={classes.listedNames}>{approvals[2].name} </span>
                        <span className={classes.listedNames}>and {approvals.length - 3} others</span>
                    </>
                );
            }
        }
    };
    generateNameList(approvals);

    return (
        <div className={classes.root}>
            {approvals != null ? (
                <div className={classes.toolbar}>
                    {showUnapprovals && (
                        <div className={clsx(classes.iconButton, classes.buttonDenied)}>
                            <CustomTooltip
                                disableFocusListener
                                title={
                                    <div className={classes.tooltipContent}>
                                        <div className={classes.tooltipTitle}>Denied</div>
                                        {approvals.map((reviewer, index) => {
                                            if (!reviewer.approved) {
                                                return (
                                                    <div key={`denied-${index}`} className={classes.tooltipItem}>
                                                        <span>{reviewer.name}</span>{' '}
                                                        {formatDate(reviewer.date, 'M/D/YY')}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                }>
                                <Cancel className={classes.iconDenied} />
                            </CustomTooltip>
                        </div>
                    )}

                    {showApprovals && (
                        <div className={clsx(classes.iconButton, classes.buttonApproved)}>
                            <CustomTooltip
                                disableFocusListener
                                title={
                                    <div className={classes.tooltipContent}>
                                        <div className={classes.tooltipTitle}>Approved</div>
                                        {approvals.map((reviewer, index) => {
                                            if (reviewer.approved) {
                                                return (
                                                    <div key={`approved-${index}`} className={classes.tooltipItem}>
                                                        <span>{reviewer.name}</span>
                                                        {formatDate(reviewer.date, 'M/D/YY')}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                }>
                                <CheckCircle className={classes.iconApproved} />
                            </CustomTooltip>
                        </div>
                    )}
                    <Typography className={classes.listedContainer} noWrap>
                        {generateNameList()}
                    </Typography>
                </div>
            ) : null}
        </div>
    );
};

export default withStyles(styles)(ApprovalActivityToolbar);
