import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const AuthForm = (props) => {
    const { classes, children, ...other } = props;

    return (
        <form className={classes.root} {...other}>
            {children}
        </form>
    );
};

export default withStyles(styles)(AuthForm);
