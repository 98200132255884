import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import {
    INIT,
    GET_CHAPTER_SAFETY_ALERT_SUCCESS,
    GET_CHAPTER_SAFETY_ALERT_ERROR,
    UPDATE_CHAPTER_SAFETY_ALERT_SUCCESS,
    SEND_CHAPTER_SAFETY_ALERT_MESSAGE_SUCCESS,
    SEND_CHAPTER_SAFETY_ALERT_MESSAGE_ERROR,
    DELETE_CHAPTER_SAFETY_ALERT_ERROR,
    DELETE_CHAPTER_SAFETY_ALERT_SUCCESS,
} from 'redux/reducers/chapterSafetyAlert';

export const get = ({ id, OrganizationId, ParentId }) => async (dispatch) => {
    let path = `/organizations/${OrganizationId}/safety-alerts/${id}?ParentId=${ParentId}`;

    try {
        const alert = await API.get('ClutchAPI', path);

        dispatch({
            type: GET_CHAPTER_SAFETY_ALERT_SUCCESS,
            payload: alert,
        });
    } catch (error) {
        dispatch({
            type: GET_CHAPTER_SAFETY_ALERT_ERROR,
        });
        throw error;
    }
};

export const patch = (chapterSafetyAlert) => (dispatch) => {
    dispatch({
        type: UPDATE_CHAPTER_SAFETY_ALERT_SUCCESS,
        payload: chapterSafetyAlert,
    });
};

export const sendMessage = (message) => async (dispatch, getState) => {
    let { chapterSafetyAlert } = getState();

    let users = chapterSafetyAlert.AlertResponses.filter((response) => response.selected).map(({ UserId }) => UserId);

    try {
        await API.post(
            'ClutchAPI',
            `/organizations/${chapterSafetyAlert.OrganizationId}/safety-alerts/${chapterSafetyAlert.id}/message?ParentId=${chapterSafetyAlert.ParentId}`,
            {
                body: {
                    message,
                    users,
                },
            }
        );

        dispatch({
            type: SEND_CHAPTER_SAFETY_ALERT_MESSAGE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SEND_CHAPTER_SAFETY_ALERT_MESSAGE_ERROR,
        });
        throw error;
    }
};

export const sendEmail = (emails) => async (dispatch, getState) => {
    let { chapterSafetyAlert } = getState();

    try {
        await API.post(
            'ClutchAPI',
            `/organizations/${chapterSafetyAlert.OrganizationId}/safety-alerts/${chapterSafetyAlert.id}/email?ParentId=${chapterSafetyAlert.ParentId}`,
            {
                body: {
                    emails,
                },
            }
        );

        dispatch({
            type: SEND_CHAPTER_SAFETY_ALERT_MESSAGE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SEND_CHAPTER_SAFETY_ALERT_MESSAGE_ERROR,
        });
        throw error;
    }
};

export const remove = () => async (dispatch, getState) => {
    let { chapterSafetyAlert } = getState();
    try {
        await API.del(
            'ClutchAPI',
            `/organizations/${chapterSafetyAlert.OrganizationId}/safety-alerts/${chapterSafetyAlert.id}?ParentId=${chapterSafetyAlert.ParentId}`
        );
        dispatch({
            type: DELETE_CHAPTER_SAFETY_ALERT_SUCCESS,
            payload: cloneDeep(INIT),
        });
    } catch (error) {
        dispatch({
            type: DELETE_CHAPTER_SAFETY_ALERT_ERROR,
            payload: cloneDeep(INIT),
        });
        throw error;
    }
};
