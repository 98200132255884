export const GET_INCIDENT_REPORT_SUCCESS = 'GET_INCIDENT_REPORT_SUCCESS';
export const GET_INCIDENT_REPORT_ERROR = 'GET_INCIDENT_REPORT_ERROR';
export const DELETE_INCIDENT_REPORT_SUCCESS = 'DELETE_INCIDENT_REPORT_SUCCESS';
export const DELETE_INCIDENT_REPORT_ERROR = 'DELETE_INCIDENT_REPORT_ERROR';
export const SEND_INCIDENT_REPORT_EMAIL_SUCCESS = 'SEND_INCIDENT_REPORT_EMAIL_SUCCESS';
export const SEND_INCIDENT_REPORT_EMAIL_ERROR = 'SEND_INCIDENT_REPORT_EMAIL_ERROR';

export const INIT = {
    User: '',
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_INCIDENT_REPORT_SUCCESS:
            return payload;
        case GET_INCIDENT_REPORT_ERROR:
        case SEND_INCIDENT_REPORT_EMAIL_SUCCESS:
        case SEND_INCIDENT_REPORT_EMAIL_ERROR:
            return state;
        default:
            return state;
    }
}
