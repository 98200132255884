import React, { Component } from 'react';

//Modules
import { Auth } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import validator from 'validator';
import FormValidator from 'utils/FormValidator';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import AuthForm from 'components/AuthForm';
import AuthHeader from 'components/AuthHeader';
import Button from 'components/Button';
import Input from 'components/Input';

// styles
import styles from './styles';

class ForgotPassword extends Component {
    INIT = {
        email: { value: '', valid: true },
    };
    constructor(props) {
        super(props);

        this.state = {
            ...cloneDeep(this.INIT),
            loading: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.mounted && this.setState({ [field]: form[field] });
    };

    async handleForgotPassword(e) {
        e.preventDefault();
        const { handleError } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField.bind(this));
        if (invalidFields.length > 0) {
            return handleError(new Error(invalidFields[0].message));
        }

        this.mounted && this.setState({ loading: true });
        try {
            await Auth.forgotPassword(this.state.email.value);
            this.props.history.push(`/reset-password?email=${this.state.email.value}`);
        } catch (error) {
            handleError(error);
        }
        this.mounted && this.setState({ loading: false });
    }

    render() {
        const { loading } = this.state;
        const { classes } = this.props;

        return (
            <AuthForm className={classes.authForm} onSubmit={this.handleForgotPassword.bind(this)}>
                <Input
                    id="email"
                    label="Email Address"
                    ref="email"
                    fullWidth={true}
                    marginBottom={12}
                    value={this.state.email.value}
                    classes={{ input: classes.input }}
                    onChange={(e) =>
                        this.updateField({
                            field: 'email',
                            value: e.target.value,
                        })
                    }
                    validator={() => validator.isEmail(this.state.email.value)}
                    error={!this.state.email.valid}
                    errorMessage="Please provide a valid email"
                />

                <input ref="formSubmit" type="submit" style={{ display: 'none' }} />

                <Button
                    color="primary"
                    disabled={loading}
                    loading={loading}
                    fullWidth={true}
                    onClick={this.handleForgotPassword.bind(this)}
                    text={'Request Password Reset'}
                    size="large"
                    variant="contained"
                />
                <Button
                    classes={{ root: classes.mt6 }}
                    disabled={loading}
                    loading={loading}
                    onClick={() => this.props.history.push('/')}
                    text={'Go Back'}
                    size="large"
                />
            </AuthForm>
        );
    }
}

export default withStyles(styles)(ForgotPassword);
