const styles = (theme) => ({
    gridRoot: {
        margin: 0,
        paddingBottom: 20,
        marginBottom: 20,
        borderBottom: '1px solid rgba(151, 151, 151, 0.4)',
        width: '100%',
    },
    buttonGrid: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        display: 'flex',
    },
    filterGrid: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '0px !important',
        },
    },
    filterButton: {
        height: 57,
    },

    listItem: {
        border: '1px solid rgba(151, 151, 151, 0.3)',
        backgroundColor: 'white',
        borderRadius: 6,
        minHeight: 77,
        marginBottom: 10,
        display: 'flex',
    },
    listText: {
        flexGrow: 1,
    },
    listActions: {
        flexShrink: 1,
    },

    reviewButton: {
        height: 42,
        marginRight: 5,
    },
    deleteButton: {
        height: 42,
        width: 42,
    },
    order: {
        marginRight: 8,
    },
});

export default styles;
