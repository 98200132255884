import { Auth } from "aws-amplify";

/* eslint-disable no-restricted-globals */
// AWS Project Constants
const awsmobile = {
    Auth: {
        identityPoolId: "us-east-1:d20dfaff-1835-4dbe-99f5-afabcb1ac3ba",
        userPoolId: "us-east-1_1Y5yPRKSN",
        userPoolWebClientId: "22flcs5pnff5162bsgfg0653t8",

        region: "us-east-1",
        oauth: {
            "domain": "clutch-staging.auth.us-east-1.amazoncognito.com",
            "scope": [
              "email",
              "profile",
              "openid",
            ],
            "redirectSignIn": `${location.origin}/authorizing`,
            "redirectSignOut": location.origin,
            "responseType": "code",
          }
    },
    APIV1Endpoint: "https://staging3.api.myclutch.online/api/v1",
    PublicAPIEndpoint: "https://staging3.api.myclutch.online/api/v1",
    API: {
        endpoints: [
            {
                name: "ClutchAPI",
                endpoint: "https://staging3.api.myclutch.online/api/v1",
                region: "us-east-1",
                custom_header: async () => {
                    const user = await Auth.currentSession();
                    return {
                        Authorization: user.idToken.jwtToken,
                    };
                },
            },
        ],
    },
};

if (typeof location !== "undefined") {
    awsmobile.Auth.cookieStorage = {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: location.hostname,
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: location.protocol === "https:",
    };
}

export default awsmobile;
