import { colors } from 'global-styles';
var colorManipulator = require('utils/style-utils');

const styles = (theme) => ({
    root: {
        margin: '20px ',
    },
    sectionBody: {
        padding: '24px 24px 5px 24px',
    },
    sectonContent: {
        display: 'grid',
        gridTemplateColumns: '75% auto auto',
        gridGap: 15,
    },
    valuesContainer: {
        display: 'grid',
        gridTemplateColumns: ' auto auto',
    },
    actionToolBar: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    actionButton: {
        height: 35,
        width: 35,
        borderRadius: 6,
        marginRight: 15,
        padding: 1,

        '&.Mui-disabled': {
            color: '#cbcbcb',

            backgroundColor: '#eeeeee',
            '& svg': {
                color: '#cbcbcb',
            },
        },
    },
    approveButton: {
        background: 'transparent',
        '&:hover': {
            background: 'rgba(0,0,0, 0.05)',
        },
    },
    unapproveButton: {
        background: 'transparent',
        '&:hover': {
            background: 'rgba(0,0,0, 0.05)',
        },
    },

    commentButton: {
        color: colors.brandColors.metallicBlue,
        background: 'transparent',
        '&:hover': {
            background: 'rgba(0,0,0, 0.05)',
        },
        '& svg': {
            color: '#9da1ad',
        },
    },
    activeUnapproveButton: {
        background: colors.event.error,
        border: 'none',
        '&:hover': {
            backgroundColor: colorManipulator.darken(colors.event.error, 0.2),
        },
        '& svg': {
            color: ' #fff',
        },
    },
    activeApproveButton: {
        background: `rgba(130,201,88,1)`,
        border: 'none',
        '&:hover': {
            backgroundColor: colorManipulator.darken(`rgba(130,201,88,1)`, 0.2),
        },
        '& svg': {
            color: ' #fff',
        },
    },
    activeCommentButton: {
        backgroundColor: colors.brandColors.metallicBlue,
        border: 'none',
        '&:hover': {
            backgroundColor: colorManipulator.darken(colors.brandColors.metallicBlue, 0.2),
        },
        '& svg': {
            color: ' #fff',
        },
    },
    approveIcon: {
        color: colors.event.success,
    },
    unapproveIcon: {
        color: colors.event.error,
    },
    activeIcon: {
        color: 'white',
    },
    comments: {
        padding: 10,
        borderTop: '1px solid #e3e2e2',
    },
    commentContainer: {
        width: '70%',
    },

    comment: {
        marginBottom: 12,
    },

    bubbleContainer: {
        display: 'flex',
        width: '80%',
        alignItems: 'start',
        flexDirection: 'column',
    },
    chatBubble: {
        marginTop: 10,
        padding: '8px 12px',
        background: '#f0f2f5',
        borderRadius: 18,
        transition: 'all 0.15s ease-in-out',
        animation: `$fadeNewMessage .5s ${theme.transitions.easing.easeInOut}`,
        animationFillMode: `forwards `,
    },

    '@keyframes fadeNewMessage': {
        '0%': {
            opacity: 0,
            transform: 'translateY(1rem)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0)',
        },
    },
    bubbleName: {
        fontSize: '.8125rem;',
        fontWeight: 600,
    },
    bubbleComment: {
        fontSize: '.8125rem;',
    },
    bubbleMeta: {
        fontSize: 12,
        color: 'rgb(101, 103, 107)',
        marginBottom: 15,
        marginLeft: 8,
        marginTop: 5,
    },
    progress: {
        height: 30,
        width: 30,
    },
    inputContainer: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '75%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    multilineInput: {
        padding: 11,
        borderRadius: 18,
        background: '#f0f2f5',
        '& fieldset': {
            borderWidth: '1px !important',
            borderColor: 'rgba(0, 0, 0, 0.23) !important',
        },
        '&.Mui-focused': {
            borderColor: 'red',

            '& fieldset': {
                borderWidth: '1px !important',
                borderColor: 'rgba(0, 0, 0, 0.23) !important',
            },
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
    },

    iconButton: {
        padding: 10,
    },

    badge: {
        right: -2,
        top: -4,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        background: '#787878',
    },
    chatButton: {
        height: 'unset',
        padding: '6px 18px',
        color: '#555555',
        marginLeft: 10,
    },
});

export default styles;
