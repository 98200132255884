const styles = (theme) => ({
    order: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'stretch',
    },
    orderOnlyUp: {
        marginTop: 8,
        justifyContent: 'flex-start',
    },
    orderOnlyDown: {
        justifyContent: 'flex-end',
        marginBottom: 8,
    },
    orderIcon: {
        height: 20,
        width: 20,
    },
});

export default styles;
