// React
import React, { Component } from 'react';

// Modules
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';
import { markupTypes } from './components/types';
import { OnChangeTimer } from 'global-constants';

// Draggable UI
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Custom Components
import EventStepSection from './EventStepSection';
import Input from 'components/Input';
import Button from 'components/Button';

// Styles
import styles from './styles';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class EventStepEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.defaultName || '',
            sections: props.defaultSections || [{ id: uuidv4(), markup: [] }],
        };

        this.sections = this.state.sections;
    }

    reset = () => {
        this.setState({
            name: '',
            sections: [{ id: uuidv4(), markup: [] }],
        });
    };

    handleAddSection = () => {
        if (this.props.specialized)
            return this.props.setSnackbar(
                `This is a specialized ${this.props.specialized} module, which can not have sections added`
            );

        const sections = cloneDeep(this.state.sections);
        sections.push({
            id: uuidv4(),
            markup: [],
        });
        this.setState({ sections }, () => {
            this.sections = cloneDeep(sections);
        });
    };

    handleRemoveSection = (index) => {
        if (this.props.specialized)
            return this.props.setSnackbar(
                `This is a specialized ${this.props.specialized} module, which can not have sections removed`
            );

        const sections = cloneDeep(this.state.sections);
        sections.splice(index, 1);
        this.setState({ sections }, () => {
            this.sections = cloneDeep(sections);
        });
    };

    handleRemoveItemInSection = (sectionIdx, itemIdx) => {
        if (this.props.specialized)
            return this.props.setSnackbar(
                `This is a specialized ${this.props.specialized} module, which can not have elements removed`
            );

        const sections = cloneDeep(this.state.sections);
        sections[sectionIdx].markup.splice(itemIdx, 1);
        this.setState({ sections }, () => {
            this.sections = cloneDeep(sections);
        });
    };

    handleChangeItem = (sectionIdx, itemIdx, updatedValues) => {
        const item = { ...this.sections[sectionIdx].markup[itemIdx], ...updatedValues };
        this.sections[sectionIdx].markup[itemIdx] = item;
        if (this.timeout) clearTimeout(this.timeout);
        setTimeout(() => this.setState({ sections: this.sections }), OnChangeTimer);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination || result.destination.droppableId === 'event-step-items') return;
        if (this.props.specialized)
            return this.props.setSnackbar(
                `This is a specialized ${this.props.specialized} module, which can not have new elements added`
            );

        if (result.type === 'SECTION') {
            const updated = reorder(this.state.sections, result.source.index, result.destination.index);

            const sections = {
                ...this.state.sections,
                updated,
            };

            this.setState({ sections: sections.updated });
            return;
        }

        if (result.type === 'ITEM') {
            const sections = cloneDeep(this.state.sections);
            let item;

            // Check if a moved item or newly created on
            if (result.source.droppableId === 'event-step-items') {
                for (let i = 0; i < markupTypes.length; i++) {
                    const itemType = markupTypes[i];

                    if (result.draggableId === itemType.id) {
                        item = cloneDeep(itemType.markup);
                        item.type = itemType.id;
                        item.id = uuidv4();
                        break;
                    }
                }
            } else {
                // If not find the item and remove from child list
                for (let i = 0; i < sections.length; i++) {
                    const section = sections[i];

                    if (section.id === result.source.droppableId) {
                        for (let j = 0; j < section.markup.length; j++) {
                            const child = section.markup[j];

                            if (child.id === result.draggableId) {
                                item = section.markup.splice(j, 1)[0];
                                break;
                            }
                        }
                        break;
                    }
                }
            }

            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];

                if (section.id === result.destination.droppableId) {
                    section.markup.splice(result.destination.index, 0, item);
                    break;
                }
            }

            this.setState({ sections }, () => {
                this.sections = cloneDeep(sections);
            });
            return;
        }
    };

    render = () => {
        const { classes } = this.props;
        const { sections } = this.state;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className={classes.root}>
                    <Droppable droppableId="event-step-items" type="ITEM">
                        {(dropProvided, dropSnapshot) => (
                            <div
                                className={classes.itemTypes}
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}>
                                {this.props.specialized && (
                                    <div className={classes.specialized}>
                                        <p>
                                            Specialized Event Modules can
                                            <br />
                                            not have elements added
                                        </p>
                                    </div>
                                )}
                                {markupTypes.map((itemType, index) => (
                                    <Draggable key={itemType.id} draggableId={itemType.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                className={classes.itemType}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <img src={itemType.icon} alt={itemType.title} />
                                                <Typography className={classes.itemTypeText}>
                                                    {itemType.title}
                                                </Typography>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {dropProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div className={classes.sections}>
                        <Input
                            placeholder="Write a Module Name..."
                            fullWidth
                            className={classes.moduleName}
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                        <Droppable droppableId="event-step-droppable" type="SECTION">
                            {(dropProvided, dropSnapshot) => (
                                <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                    {sections.map((section, index) => (
                                        <Draggable key={section.id} draggableId={section.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={classes.item}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}>
                                                    <EventStepSection
                                                        onRemove={this.handleRemoveSection.bind(this, index)}
                                                        onRemoveItem={(itemIndex) =>
                                                            this.handleRemoveItemInSection(index, itemIndex)
                                                        }
                                                        onChangeItem={(itemIndex, values) =>
                                                            this.handleChangeItem(index, itemIndex, values)
                                                        }
                                                        title={`Section ${index + 1}`}
                                                        section={section}
                                                        specialized={this.props.specialized}
                                                        dragHandleProps={provided.dragHandleProps}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {dropProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Button
                            text="ADD SECTION"
                            fullWidth
                            classes={{ root: classes.addSection }}
                            variantType="outlinedDashedGrey"
                            onClick={this.handleAddSection.bind(this)}
                        />
                    </div>
                </div>
            </DragDropContext>
        );
    };
}

export default withStyles(styles)(EventStepEditor);
