// import { InputRed, Red, IconGray } from 'global-styles';

const styles = (theme) => ({
    uploadedRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 24px',
        backgroundColor: 'white',
        border: '1px dashed #ccd0d7',
        height: 84,
        cursor: 'pointer',
        borderRadius: 6,

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            height: 'auto',
        },
    },
    uploadRoot: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "center",
        justifyContent: 'center',
        padding: '12px 24px',
        backgroundColor: 'white',
        border: '1px dashed #ccd0d7',
        height: 84,
        cursor: 'pointer',
        borderRadius: 6,
    },
    image: {
        height: 64,
        width: 64,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: {
            height: 240,
            width: 240,
            maxWidth: '100%',
            marginBottom: 10,
        },
    },
    document: {
        marginRight: 20,
        color: '#4f5668',
        fontSize: 58,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            marginBottom: 10,
        },
    },
    information: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: 20,
        marginRight: 5,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
            marginLeft: 0,
            marginRight: 0,
            textAlign: 'center',
        },
    },
    browse: {
        color: '#2f89eb',
        fontWeight: 700,
        textDecoration: 'underline',
    },
    title: {
        color: '#4a4a4a',
        fontSize: 16,
    },
    subtitle: {
        marginTop: 8,
        color: '#4a4a4a',
        opacity: 0.5,
        fontSize: 14,
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    dragger: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },

    removeButton: {
        padding: '20px',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        color: 'white',
        backgroundColor: '#d06767',
        cursor: 'pointer',
        fontWeight: 700,
        '& span': {
            marginLeft: 12,
            fontSize: 16,
        },
        [theme.breakpoints.up('xs')]: {
            padding: '20px 40px',
        },
    },
    label: {},
    errorMessage: {
        color: theme.palette.error.main,
        marginLeft: 14,
        marginRight: 14,
        marginTop: 3,
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
        fontFamil: '"Lato", "Roboto", "sans serif"',
    },
    error: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
});

export default styles;
