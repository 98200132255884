import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
// Styles
import styles from './styles';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputType: props.type || 'text',
        };
    }

    toggleType() {
        this.setState({
            inputType: this.state.inputType === 'text' ? 'password' : 'text',
        });
    }

    render() {
        const {
            type,
            valid = true,
            errorMessage,
            validator,
            classes,
            marginBottom,
            maxLength,
            variant = 'outlined',
            value = [],
            typography,
            ...rest
        } = this.props;

        let bottomMargin = marginBottom ? { marginBottom } : {};

        return (
            // set defaults for text fields app-wide
            <>
                {typography && <Typography variant="h6">{typography}</Typography>}
                <ChipInput
                    style={bottomMargin}
                    value={Array.isArray(value) ? value : [value]}
                    type={this.state.inputType}
                    variant={variant}
                    error={!valid}
                    helperText={!valid && errorMessage}
                    {...rest}
                />
            </>
        );
    }
}

export default withStyles(styles)(Input);
