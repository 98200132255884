const styles = (theme) => ({
    root: {},
    margin: {
        marginBottom: 20,
    },
    halfWidth: {
        width: '50%',
    },
});

export default styles;
