import { colors } from 'global-styles';
const styles = (theme) => ({
    ...colors,
    root: {
        flex: 1,
    },
    transitionAlertContainer: {
        margin: '0px 30px',
    },
    transitionAlert: {
        height: 50,
        marginTop: 30,
    },
    dropContainer: {
        minHeight: 400,
        background: 'red',
    },
    pageContent: {
        flex: 1,
        margin: 30,
        boxShadow: '0 2px 0 #e2e2e2',
        borderRadius: 6,
        border: '1px solid rgba(151, 151, 151, 0.28)',
        backgroundColor: '#ffffff',
    },
    header: {
        borderRadius: '6px 6px 0 0',
        backgroundColor: '#454c5d',
        color: '#ffffff',
        padding: '20px 15px',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '43% 8% 8% 8% 8% auto',
        gridColumnGap: 25,
        '& p': {
            fontWeight: 700,
        },
    },
    firstTitle: {
        display: 'flex',
    },
    infoIcon: {
        marginLeft: 12,
    },

    listWrapper: {
        display: 'grid',
        gridTemplateColumns: '43% 8% 8% 8% 8% auto',
        gridColumnGap: 25,
        alignItems: 'center',
        width: '100%',
    },
    listItem: {
        alignItems: 'center',
        background: '#fff',
        borderBottom: '1px #00000017 solid',
    },
    title: {
        display: 'flex',
        '& svg': {
            marginRight: 15,
            opacity: 0.3,
        },
    },
    alignCenter: {
        textAlign: 'center',
    },
    deleteIcon: {
        width: 50,
    },
    actionIcon: {
        textAlign: 'center',
    },
    svgStatus: {
        color: colors.warningRed,
        margin: '0px auto',
    },
    statusCircleGreen: {
        color: colors.successGreen,
    },
});

export default styles;
