import { SET_SNACKBAR } from 'redux/reducers/snackbar';

export function setSnackbar(payload, options = { severity: null, disableAutoHide: false, ContentProps: null }) {
    return {
        type: SET_SNACKBAR,
        payload: {
            message: payload,
            options,
        },
    };
}
