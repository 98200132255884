// React
import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import queryString from 'query-string';
import { DragDropContext } from 'react-beautiful-dnd';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Components
import SearchRoles from './SearchRoles';
import ProcessList from './ProcessList';
import Page from 'components/Page';
// Styles
import styles from './styles';

class EventReviewProcess extends React.Component {
    constructor(props) {
        super(props);

        this.query = queryString.parse(props.location.search);
        this.eventTypeId = props.match.params.id;
        this.queue = [];
        this.state = {
            unrelatedRoles: [],
            relatedRoles: [],
            roles: [],
            loading: false,
        };
    }

    componentDidMount() {
        this._getData();
    }

    async _getData() {
        this.setState({ loading: true });

        try {
            const [roles, relatedRoles] = await Promise.all([
                API.get('ClutchAPI', `/organizations/${this.props.match.params.id}/roles?reviewer=true`),
                API.get('ClutchAPI', `/organizations/${this.props.match.params.id}/approval-process`),
            ]);

            this._setRoles(roles, relatedRoles);
        } catch (error) {
            setSnackbar(checkError(error));
            this.props.history.goBack();
        }
    }

    _setRoles(roles, relatedRoles) {
        const unrelatedRoles = roles.data.filter((role) => {
            for (var i = 0; i < relatedRoles.length; i++) {
                if (relatedRoles[i].RoleId === role.id) {
                    return false;
                }
            }
            return true;
        });

        this.setState({
            roles,
            relatedRoles,
            unrelatedRoles,
            loading: false,
        });
    }

    runQueue = (callback) => {
        const { setSnackbar } = this.props;
        if (!this.queueHandler) {
            this.queueHandler = setInterval(async () => {
                if (!this.sendingRequest && this.queue.length > 0) {
                    this.sendingRequest = true;
                    let { method, path, body = {}, previousState } = this.queue.pop();

                    try {
                        const resp = await API[method]('ClutchAPI', path, { body });
                        Mixpanel.track('Event review process updated');
                        typeof callback == 'function' && callback(resp);
                    } catch (error) {
                        this.queue = []; // empty queue
                        setSnackbar(checkError(error)); // Set snackbar
                        this.setState(previousState); // reset state
                        typeof callback == 'function' && callback(null);
                    }
                    this.sendingRequest = false;
                }

                if (this.queue.length === 0) {
                    clearInterval(this.queueHandler);
                    this.queueHandler = undefined;
                }
            }, 200);
        }
    };

    onDelete = async (approvalRole) => {
        const { unrelatedRoles, relatedRoles, roles } = this.state;

        this.setState(
            {
                relatedRoles: relatedRoles.filter((item) => item.RoleId !== approvalRole.RoleId),
            },
            () => {
                this._setRoles(this.state.roles, this.state.relatedRoles);
            }
        );

        this.queue.push({
            method: 'del',
            path: `/organizations/${this.props.match.params.id}/approval-process/${approvalRole.RoleId}`,
            previousState: { unrelatedRoles, relatedRoles, roles },
        });
        this.runQueue();
    };

    onDragEnd = async (result) => {
        const { unrelatedRoles, relatedRoles, roles } = this.state;

        const { source, destination } = result;
        if (!destination || destination.droppableId !== 'roles-droppable') return;

        if (source.droppableId === 'event-module-roles' && destination.droppableId === 'roles-droppable') {
            const droppedItem = unrelatedRoles[source.index];
            const clonedRelations = cloneDeep(relatedRoles);
            clonedRelations.splice(destination.index, 0, {
                role: droppedItem,
                RoleId: droppedItem.id,
                loading: true,
            });

            const clonedUnrelatedRoles = cloneDeep(unrelatedRoles);
            clonedUnrelatedRoles.splice(source.index, 1);
            this.setState(
                {
                    unrelatedRoles: clonedUnrelatedRoles,
                    relatedRoles: clonedRelations,
                },
                () => {
                    this.queue.push({
                        method: 'post',
                        path: `/organizations/${this.props.match.params.id}/approval-process/${droppedItem.id}`,
                        previousState: { unrelatedRoles, relatedRoles, roles },
                        body: { order: destination.index + 1 },
                    });

                    this.runQueue((resp) => {
                        const reclonedTemplates = cloneDeep(this.state.relatedRoles);
                        for (var i = 0; i < reclonedTemplates.length; i++) {
                            if (reclonedTemplates[i].RoleId === resp.RoleId) {
                                reclonedTemplates[i] = resp;
                            }
                        }
                        this.setState({ relatedRoles: reclonedTemplates });
                    });
                }
            );
        } else if (source.droppableId === destination.droppableId) {
            if (source.index === destination.index) return;

            const droppedItem = relatedRoles[source.index];
            // On reorder
            const clonedRelations = cloneDeep(relatedRoles);
            var holder = clonedRelations.splice(source.index, 1)[0];
            clonedRelations.splice(destination.index, 0, holder);
            this.setState({ relatedRoles: clonedRelations });

            this.queue.push({
                method: 'patch',
                path: `/organizations/${this.props.match.params.id}/approval-process/${droppedItem.RoleId}`,
                previousState: { unrelatedRoles, relatedRoles, roles },
                body: { order: destination.index + 1 },
            });
            this.runQueue();
        }
    };

    render() {
        const { classes } = this.props;
        const { unrelatedRoles, relatedRoles } = this.state;

        return (
            <Page
                classes={{ root: classes.root }}
                topNavigationProps={{
                    pageTitle: 'Event Review Process',
                    helperText: 'This page is for setting the order of event reviewers',
                }}>
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                    <div className={classes.content}>
                        <SearchRoles roles={unrelatedRoles} />
                        <ProcessList approvalProcess={relatedRoles} onDelete={this.onDelete.bind(this)} />
                    </div>
                </DragDropContext>
            </Page>
        );
    }
}

export default connect(null, { setSnackbar })(withStyles(styles)(EventReviewProcess));
