import React from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Hidden, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Menu, KeyboardArrowLeft } from '@material-ui/icons';

// Images
import ClutchWhite from 'assets/images/ClutchWhite.svg';

// styles
import styles from './styles';

const MobileAppBar = ({ classes, ...props }) => {
    let history = useHistory();

    const handleMenuClick = () => {
        props.setMobileOpen(true);
    };

    return (
        <Hidden mdUp>
            <div className={classes.root}>
                <AppBar position="sticky" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton onClick={(e) => history.goBack()} aria-label="back" color="inherit">
                            <KeyboardArrowLeft />
                        </IconButton>
                        <img src={ClutchWhite} alt="deltazeta-logo" width="138px" />
                        <IconButton aria-label="menu" color="inherit" onClick={handleMenuClick}>
                            <Menu />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
        </Hidden>
    );
};

export default withStyles(styles)(MobileAppBar);
