const styles = (theme) => ({
    root: {
        flex: 1,
        margin: 30,
        boxShadow: '0 2px 0 #e2e2e2',
        borderRadius: 6,
        border: '1px solid rgba(151, 151, 151, 0.28)',
        backgroundColor: '#ffffff',
    },
    header: {
        borderRadius: '6px 6px 0 0',
        backgroundColor: '#454c5d',
        color: '#ffffff',
        padding: '20px 15px',
        display: 'grid',
        gridTemplateColumns: '50% 17% 26% 7%',
        '& p': {
            fontWeight: 700,
        },
    },
    delete: {
        right: '5%',
    },
    listWrapper: {
        display: 'grid',
        gridTemplateColumns: '50% 17% 26% 7%',
        alignItems: 'center',
        width: '100%',
    },
    listItem: {
        alignItems: 'center',
        padding: '20px 15px',
        background: '#fff',
        borderBottom: '1px #00000017 solid',
    },
    listItemStatic: {
        padding: '10px 15px',
        background: '#ccc',
        cursor: 'not-allowed',
    },
    staticMeta: {
        fontSize: 12,
        fontStyle: 'italic',
        color: '#595959',
    },

    title: {
        display: 'flex',
        '& svg': {
            marginRight: 15,
            opacity: 0.3,
        },
    },
    staticTitle: {
        display: 'flex',
        '& svg': {
            marginRight: 15,
            opacity: 0.3,
        },
    },
});

export default styles;
