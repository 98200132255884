import awsConfigTest from './awsconfig.test';
import awsConfigDev from './awsconfig.dev';
import awsConfigStage from './awsconfig.staging';
import awsConfigProd from './awsconfig.prod';

let awsConfig;
// load the correct aws config options

switch (process.env.REACT_APP_ENV) {
    case 'production':
        awsConfig = awsConfigProd;
        break;
    case 'staging':
        awsConfig = awsConfigStage;
        break;
    case 'development':
        awsConfig = awsConfigDev;
        break;
    case 'test':
        awsConfig = awsConfigTest;
        break;
    default:
        awsConfig = awsConfigDev;
}
export { awsConfig };
