import React from 'react';

// Modules
import classNames from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
// Styles
import styles from '../styles';

const EmailButton = ({ variantType = 'containedBlue', classes, ...props }) => {
    let MaterialButtonClasses = classNames({
        [classes.iconButtonRoot]: true,
        [classes[variantType]]: variantType,
    });

    return (
        <IconButton classes={{ root: MaterialButtonClasses }} tooltip="Email" {...props}>
            <EmailIcon />
        </IconButton>
    );
};

export default withStyles(styles)(EmailButton);
