const drawerWidth = 284;

const styles = (theme) => ({
    img: {
        margin: '15px auto 30px auto',
    },
    profileInfo: {
        borderRadius: 12,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        padding: '5px 15px',
        marginBottom: 20,
    },
    profileName: {
        fontSize: 14,
        fontWeight: 600,
    },
    profileEmail: {
        color: '#e3e3e3',
    },
    listAvatar: {
        backgroundColor: '#585e6b',
    },

    list: {
        display: 'flex',
        flexDirection: 'column',
        color: '#ffffff',
    },
    listIcon: {
        color: '#ffffff',
        minWidth: 0,
        marginRight: 15,
    },
    logout: {
        color: '#ffffff',
        display: 'flex',
        opacity: 0.5,
        paddingBottom: 60,
    },
    padding: {
        padding: '25px 0 75px 0',
    },
    logoutText: {
        margin: '0 20px 0 15px',
    },
    selected: {
        '&$selected': {
            borderRadius: 12,
            backgroundColor: '#303542',
        },
    },

    mobileDrawer: {
        marginTop: 70,
    },
    closeBtn: {
        position: 'absolute',
        top: 25,
        right: 25,
        color: 'white',
    },

    // OK
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    appBar: {
        backgroundColor: '#FFF',
        border: '2px solid #EDF0F3',
        boxShadow: 'none',
        position: 'absolute',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    toolBar: {
        justifyContent: 'space-between',
        ...theme.mixins.toolbar,
    },
    drawerDocked: {
        height: '100%',
    },
    drawerPaper: {
        width: drawerWidth,
        position: 'relative',
        padding: 20,
        backgroundColor: '#454c5d',
    },
    drawerPaperMobile: {
        backgroundColor: '#231f20',
        width: '100%',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        overflow: 'auto',
    },
    scrollableContent: {
        // height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
        height: '100%',
        overflow: 'auto',
        width: '100%',
    },
});

export default styles;
