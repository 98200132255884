import React from 'react';

// Material UI Icons
import {
    Event,
    Home,
    ViewModule,
    SupervisorAccount,
    TrendingUp,
    List,
    DirectionsRun,
    FolderSpecial,
    Settings,
    LocalHospital,
    LineStyle,
    Group,
} from '@material-ui/icons';
import { ReactComponent as OrgRole } from 'assets/icons/OrgRole.svg';
// Constants
import { policyIdentifiers } from 'global-constants';

export default (policies) => {
    const menuItems = [];
    menuItems.push({
        icon: <Event />,
        label: 'Events for Review',
        pathname: 'events-for-review',
    });
    if (policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <Group />,
            label: 'Users',
            pathname: 'org-users',
        });
    }
    if (policies[policyIdentifiers.MANAGE_ROLES_AND_PERMISSIONS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <OrgRole />,
            label: 'Roles & Permissions',
            pathname: 'org-roles',
        });
    }
    if (policies[policyIdentifiers.MANAGE_EVENT_TYPES] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <ViewModule />,
            label: 'Event Types',
            pathname: 'event-types',
        });
    }
    if (policies[policyIdentifiers.MANAGE_EVENT_STEPS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <LineStyle />,
            label: 'Event Steps',
            pathname: 'event-steps',
        });
    }
    if (policies[policyIdentifiers.MANAGE_CHAPTERS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <Home />,
            label: 'Chapters',
            pathname: 'chapters',
        });
    }
    // if (policies[policyIdentifiers.VIEW_PERSONAL_SAFETY_ALERTS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
    //     menuItems.push({
    //         icon: <LocalHospital />,
    //         label: 'Personal Safety',
    //         pathname: 'personal-safety-alerts',
    //     });
    // }
    if (policies[policyIdentifiers.VIEW_CHAPTER_SAFETY_ALERTS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <SupervisorAccount />,
            label: 'Chapter Safety',
            pathname: 'chapter-safety',
        });
    }
    if (policies[policyIdentifiers.VIEW_INCIDENT_REPORTS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <DirectionsRun />,
            label: 'Incident Reports',
            pathname: 'incidents',
        });
    }
    if (policies[policyIdentifiers.MANAGE_HOUSE_RULES] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <List />,
            label: 'House Rules',
            pathname: 'house-rules',
        });
    }
    if (policies[policyIdentifiers.MANAGE_EDUCATION] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <FolderSpecial />,
            label: 'Education',
            pathname: 'education',
        });
    }
    // if (policies[policyIdentifiers.VIEW_METRICS] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
    //     menuItems.push({
    //         icon: <TrendingUp />,
    //         label: 'Metrics',
    //         pathname: 'metrics',
    //     });
    // }
    if (policies[policyIdentifiers.MANAGE_SYSTEM_SETUP] || policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
        menuItems.push({
            icon: <Settings />,
            label: 'System Setup',
            pathname: 'system-setup',
        });
    }

    return menuItems;
};
