import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import queryString from 'query-string';
import { API } from 'aws-amplify';
import Formalize from '@fyresite/formalize';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import FormValidator from 'utils/FormValidator';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft, RadioButtonUnchecked, CheckCircle } from '@material-ui/icons';
import { List, ListItem, ListItemText, Divider, ListItemSecondaryAction, Typography, Paper } from '@material-ui/core';
// Components
import Button from 'components/Button';
import Input from 'components/Input';
import ChipInput from 'components/Input/ChipInput';
import Skeleton from 'components/Skeleton';
import Page from 'components/Page';

// Variables
import FORM_INIT from './init';
// styles
import styles from './styles';

class OrganizationRole extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);

        this.query = queryString.parse(this.props.history.location.search);
        this.state = {
            permissions: { data: [], count: 0 },
            loading: !isNaN(parseInt(props.match.params.id)),
            reviewerPolicies: [],
            adminPolicies: [],
            memberPolicies: [],
            ...cloneDeep(this.INIT),
        };
    }

    componentDidMount() {
        this.mounted = true;

        if (!this.query.OrganizationId) {
            setSnackbar('Permission denied');
            this.props.history.push('/org-roles');
        } else {
            this._getPolicies();
            if (this.props.match.params.id !== 'new') {
                this.setState({ pageTitle: 'Edit Role' });
                this._getRole();
            } else {
                this.setState({ pageTitle: 'New Role' });
            }
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async _getRole() {
        const { setSnackbar } = this.props;
        this.mounted && this.setState({ loading: true });

        try {
            const role = await API.get(
                'ClutchAPI',
                `/organizations/${this.query.OrganizationId}/roles/${this.props.match.params.id}`
            );

            // this one is a doozy...
            const formalizedRole = Formalize(this.INIT, role);
            if (formalizedRole.metadata && formalizedRole.metadata.source) {
                formalizedRole.source.value = formalizedRole.metadata.source;
            }
            const policyIds = {};
            formalizedRole.policies.forEach((policy) => (policyIds[policy.id] = true));
            formalizedRole.policyIds.value = policyIds;

            this.setState(formalizedRole);
        } catch (error) {
            setSnackbar(checkError(error));
            // this.props.history.push("/org-roles");
            // this.mounted && this.setState({ loading: false });
        }
        this.mounted && this.setState({ loading: false });
    }

    async _getPolicies() {
        try {
            const permissions = await API.get(
                'ClutchAPI',
                `/permissions/policies?metadata.organizationPolicy=1&OrganizationId=${this.query.OrganizationId}`
            );

            const adminPolicies = [];
            const reviewerPolicies = [];
            const memberPolicies = [];

            permissions.data.forEach((policy) => {
                if (!policy.metadata) return;
                if (policy.metadata.reviewer) {
                    reviewerPolicies.push(policy);
                } else if (policy.metadata.admin) {
                    adminPolicies.push(policy);
                } else if (policy.metadata.member) {
                    memberPolicies.push(policy);
                }
            });

            this.setState({ permissions, adminPolicies, reviewerPolicies, memberPolicies });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { setSnackbar, history } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return this.props.setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        try {
            const body = {
                name: this.state.name.value,
                source: this.state.source.value,
                policies: Object.keys(this.state.policyIds.value).map((key) => {
                    return parseInt(key);
                }),
            };

            if (this.state.id) {
                await API.patch(
                    'ClutchAPI',
                    `/organizations/${this.query.OrganizationId}/roles/${this.props.match.params.id}`,
                    { body }
                );
                Mixpanel.track('Role updated');
                setSnackbar('Role updated');
            } else {
                await API.post('ClutchAPI', `/organizations/${this.query.OrganizationId}/roles`, { body });
                Mixpanel.track('Role created');
                setSnackbar('Role added');
                this.setState({ ...cloneDeep(this.INIT) });
            }
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ submitting: false });
        if (!this.state.id) history.goBack();
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    addPolicy = (policy) => {
        const clonedPolicyIds = cloneDeep(this.state.policyIds.value);
        if (clonedPolicyIds[policy.id]) delete clonedPolicyIds[policy.id];
        else clonedPolicyIds[policy.id] = true;

        this.updateField({ field: 'policyIds', value: clonedPolicyIds });
    };

    render() {
        const { classes } = this.props;
        const { adminPolicies, reviewerPolicies, memberPolicies } = this.state;

        return this.state.loading ? (
            <Page maxWidth={900}>
                <Skeleton type="infoPage" />
            </Page>
        ) : (
            <Page maxWidth={900} topNavigationProps={{ pageTitle: this.state.pageTitle }}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Button
                        classes={{
                            root: classes.mb10,
                            startIcon: classes.chevronLeft,
                        }}
                        text="Return to Roles"
                        onClick={() =>
                            this.props.history.push(`/org-roles?OrganizationId=${this.query.OrganizationId}`)
                        }
                        variant="contained"
                        variantType="containedBlue"
                        disabled={this.state.submitting}
                        disableElevation
                        startIcon={<ChevronLeft />}
                    />

                    <Paper classes={{ root: classes.paper }}>
                        <Input
                            ref="name"
                            value={this.state.name.value}
                            valid={this.state.name.valid}
                            errorMessage="Role name is Required"
                            validator={() => !!this.state.name.value}
                            onChange={(e) =>
                                this.updateField({
                                    field: 'name',
                                    value: e.target.value,
                                })
                            }
                            marginBottom={10}
                            fullWidth
                            typography="Clutch Role"
                            placeholder="Name of Clutch Role"
                        />
                        <ChipInput
                            ref="source"
                            value={this.state.source.value}
                            alwaysShowPlaceholder={false}
                            onDelete={(_, index) => {
                                let sourceValue = cloneDeep(this.state.source.value);
                                sourceValue.splice(index, 1);
                                this.updateField({ field: 'source', value: sourceValue });
                            }}
                            onAdd={(chip) => {
                                let sourceValue = cloneDeep(this.state.source.value);
                                sourceValue.push(chip);
                                this.updateField({
                                    field: 'source',
                                    value: sourceValue,
                                });
                            }}
                            marginBottom={10}
                            fullWidth
                            typography="Source Name (click enter after each role input)"
                            placeholder="Role code from database integration, click enter after each role input"
                            blurBehavior={'add'}
                        />
                    </Paper>

                    <Paper classes={{ root: classes.paper }}>
                        <Typography variant="h4" className={classes.title}>
                            Event Reviewer Permissions
                        </Typography>
                        <Typography variant="body2">
                            Users attached to a role with reviewer policies are considered "Event Reviewers" throughout
                            the application.{' '}
                            <span className={classes.alert}>
                                <br />
                                Note: If a reviewer permission is removed, the role is no longer associated to the
                                Approval Process
                            </span>
                        </Typography>
                        <List dense>
                            {reviewerPolicies.map((policy, index) => {
                                return (
                                    <React.Fragment key={`policy-${index}`}>
                                        <ListItem
                                            button
                                            onClick={this.addPolicy.bind(this, policy)}
                                            classes={{ root: classes.listItem }}>
                                            <ListItemText primary={policy.name} secondary={policy.description} />
                                            <ListItemSecondaryAction
                                                onClick={this.addPolicy.bind(this, policy)}
                                                className={classes.pointer}>
                                                {this.state.policyIds.value[policy.id] ? (
                                                    <CheckCircle className={classes.checked} />
                                                ) : (
                                                    <RadioButtonUnchecked className={classes.unchecked} />
                                                )}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {reviewerPolicies.length - 1 !== index && (
                                            <Divider classes={{ root: classes.divider }} />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </Paper>
                    <Paper classes={{ root: classes.paper }}>
                        <Typography variant="h4" className={classes.title}>
                            Admin App Permissions
                        </Typography>
                        <Typography variant="body2">These are policies associated to the admin website</Typography>
                        <List dense>
                            {adminPolicies.map((policy, index) => {
                                return (
                                    <React.Fragment key={`policy-${index}`}>
                                        <ListItem
                                            button
                                            onClick={this.addPolicy.bind(this, policy)}
                                            classes={{ root: classes.listItem }}>
                                            <ListItemText primary={policy.name} secondary={policy.description} />
                                            <ListItemSecondaryAction
                                                onClick={this.addPolicy.bind(this, policy)}
                                                className={classes.pointer}>
                                                {this.state.policyIds.value[policy.id] ? (
                                                    <CheckCircle className={classes.checked} />
                                                ) : (
                                                    <RadioButtonUnchecked className={classes.unchecked} />
                                                )}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {adminPolicies.length - 1 !== index && (
                                            <Divider classes={{ root: classes.divider }} />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </Paper>
                    <Paper classes={{ root: classes.paper }}>
                        <Typography variant="h4" className={classes.title}>
                            User App Permissions
                        </Typography>
                        <Typography variant="body2">These are policies associated to the user website</Typography>
                        <List dense>
                            {memberPolicies.map((policy, index) => {
                                return (
                                    <React.Fragment key={`policy-${index}`}>
                                        <ListItem
                                            button
                                            onClick={this.addPolicy.bind(this, policy)}
                                            classes={{ root: classes.listItem }}>
                                            <ListItemText primary={policy.name} secondary={policy.description} />
                                            <ListItemSecondaryAction
                                                onClick={this.addPolicy.bind(this, policy)}
                                                className={classes.pointer}>
                                                {this.state.policyIds.value[policy.id] ? (
                                                    <CheckCircle className={classes.checked} />
                                                ) : (
                                                    <RadioButtonUnchecked className={classes.unchecked} />
                                                )}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {memberPolicies.length - 1 !== index && (
                                            <Divider classes={{ root: classes.divider }} />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </Paper>
                    {/* <input ref="formSubmit" type="submit" style={{ display: 'none' }} /> */}
                    <Button
                        text={this.state.id ? 'Update Role' : 'Add New Role'}
                        fullWidth
                        onClick={this.handleSubmit.bind(this)}
                        variant="contained"
                        variantType="containedBlue"
                        disableElevation
                        loading={this.state.submitting}
                    />
                </form>
            </Page>
        );
    }
}

const actions = { setSnackbar };

export default connect(null, actions)(withStyles(styles)(OrganizationRole));
