import React from 'react';

import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { templateTypes } from 'global-constants';
import moment from 'moment';
import clsx from 'classnames';
import dayjs from 'dayjs';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Check, Close, RateReviewOutlined } from '@material-ui/icons';
import { CircularProgress, Badge, IconButton, Collapse } from '@material-ui/core';
// Components
import RoundedSection from 'components/RoundedSection';
import RoundedSectionLabel from 'components/RoundedSection/RoundedSectionLabel';
import RoundedSectionBody from 'components/RoundedSection/RoundedSectionBody';
import ApprovalActivityToolbar from 'components/ApprovalActivityToolbar';
import Input from 'components/Input';
import Media from 'components/Media';
import Button from 'components/Button';

// styles
import styles from './styles';

class ReviewSection extends React.Component {
    constructor(props) {
        super(props);
        const { section } = props;

        // Get the users latest approval
        let approved = 'none';
        const approvals = section.review ? section.review.approvals : {};
        if (approvals[props.currentUserId] && approvals[props.currentUserId].approved === true) approved = 'true';
        else if (approvals[props.currentUserId] && approvals[props.currentUserId].approved === false)
            approved = 'false';

        this.state = {
            comment: { value: '', valid: true },
            showComments: false,
            submittingComment: false,
            submitting: false,
            approved,
            submittingItem: null,
        };
    }

    handleUpdate = async (value, id) => {
        if (value === this.state.approved) return;
        const previousValue = this.state.approved;

        this.setState({ submitting: true, approved: value, submittingItem: id });
        try {
            await this.props.onUpdateApproval(value === 'true');
            this.setState({ submitting: false, submittingItem: null });
        } catch (error) {
            this.setState({
                submitting: false,
                approved: previousValue,
                submittingItem: null,
            });
        }
    };

    handleAddComment = async () => {
        if (this.state.comment.value.length <= 0) {
            return this.setState({
                comment: { value: this.state.comment.value, valid: false },
            });
        }

        this.setState({ submittingComment: true });
        try {
            await this.props.onSubmitComment(this.state.comment.value);
            this.setState({
                submittingComment: false,
                comment: { value: '', valid: true },
            });
        } catch (error) {
            this.setState({ submittingComment: false });
        }
    };

    render() {
        const { comment } = this.state;
        // console.log("PROPS", this.props.section);

        const { classes, section, reviewing } = this.props;
        return (
            <>
                <section className={classes.root}>
                    <RoundedSection classes={{ root: classes.roundedSection }}>
                        <div className={classes.sectonContent}>
                            <RoundedSectionBody classes={{ root: classes.sectionBody }}>
                                <div className={classes.valuesContainer}>
                                    {section.markup.map((markup, index) => {
                                        let value = '';
                                        if (section.values && markup.type === 'checkBox') {
                                            value = section.values[markup.id] === true ? 'Yes' : 'No';
                                        } else if (markup.type === templateTypes.DATE_TIME_INPUT) {
                                            let date = 'No Value Provided';
                                            let time = 'No Value Provided';

                                            if (section.values && section.values[markup.id]) {
                                                date = moment(section.values[markup.id]).format('MMM DD YYYY');
                                                time = moment(section.values[markup.id]).format('h:mm A');
                                            }

                                            return (
                                                <React.Fragment key={`${markup.id}-${index}`}>
                                                    <RoundedSectionLabel
                                                        margin={markup.length - 1 !== index}
                                                        primary={markup.dateTitle || 'No Title'}
                                                        secondary={date || 'No value provided'}
                                                    />
                                                    <RoundedSectionLabel
                                                        margin={markup.length - 1 !== index}
                                                        primary={markup.timeTitle || 'No Title'}
                                                        secondary={time || 'No value provided'}
                                                    />
                                                </React.Fragment>
                                            );
                                        } else if (
                                            section.values &&
                                            section.values[markup.id] !== undefined &&
                                            section.values[markup.id] !== null
                                        ) {
                                            if (markup.type === 'fileUpload') {
                                                value = (
                                                    <Media
                                                        classes={{ image: classes.media }}
                                                        file={section.values[markup.id]}
                                                    />
                                                );
                                            } else if (markup.type === 'volunteer') {
                                                value = Object.keys(section.values[markup.id])
                                                    .map((key) => section.values[markup.id][key])
                                                    .join(', ');
                                            } else if (Array.isArray(section.values[markup.id])) {
                                                value = section.values[markup.id].join(', ');
                                            } else if (markup.type === 'checkBox') {
                                                if (section.values[markup.id] === false) value = 'No';
                                            } else value = section.values[markup.id];
                                        }

                                        return (
                                            <RoundedSectionLabel
                                                key={`${markup.id}-${index}`}
                                                margin={markup.length - 1 !== index}
                                                primary={markup.title || 'No Title'}
                                                secondary={value || 'No value provided'}
                                            />
                                        );
                                    })}
                                </div>
                            </RoundedSectionBody>
                            <div className={classes.actionToolBar}>
                                <IconButton
                                    className={clsx(classes.actionButton, {
                                        [classes.commentButton]: true,
                                        [classes.activeCommentButton]: this.state.showComments,
                                    })}
                                    onClick={() =>
                                        this.setState({
                                            showComments: !this.state.showComments,
                                        })
                                    }>
                                    <Badge
                                        classes={{ badge: classes.badge }}
                                        showZero={false}
                                        badgeContent={
                                            section.review && section.review.comments
                                                ? section.review.comments.length
                                                : 0
                                        }
                                        color="primary">
                                        <RateReviewOutlined
                                            className={clsx({
                                                [classes.commentIcon]: true,
                                                [classes.activeIcon]: this.state.showComments,
                                            })}
                                        />
                                    </Badge>
                                </IconButton>

                                {reviewing && (
                                    <IconButton
                                        classes={{ diabled: classes.buttonDisabled }}
                                        onClick={(e) => this.handleUpdate('false', 'unapprove')}
                                        className={clsx(classes.actionButton, {
                                            [classes.activeUnapproveButton]: this.state.approved === 'false',
                                        })}
                                        disabled={this.state.submittingItem === 'approve'}>
                                        {this.state.submittingItem != 'unapprove' ? (
                                            <Close
                                                disabled={this.state.submitting}
                                                className={clsx({
                                                    [classes.unapproveIcon]: true,
                                                    [classes.activeIcon]: this.state.approved === 'false',
                                                })}
                                            />
                                        ) : (
                                            <CircularProgress
                                                style={{ height: 24, width: 24 }}
                                                className={clsx({
                                                    [classes.unapproveIcon]: true,
                                                    [classes.activeIcon]: this.state.approved === 'false',
                                                })}
                                            />
                                        )}
                                    </IconButton>
                                )}
                                {reviewing && (
                                    <IconButton
                                        classes={{ root: classes.buttonDisabled }}
                                        onClick={() => this.handleUpdate('true', 'approve')}
                                        className={clsx(classes.actionButton, classes.approveButton, {
                                            [classes.activeApproveButton]: this.state.approved === 'true',
                                        })}
                                        disabled={this.state.submittingItem === 'unapprove'}>
                                        {this.state.submittingItem != 'approve' ? (
                                            <Check
                                                className={clsx({
                                                    [classes.approveIcon]: true,
                                                    [classes.activeIcon]: this.state.approved === 'true',
                                                })}
                                            />
                                        ) : (
                                            <CircularProgress
                                                style={{ height: 24, width: 24 }}
                                                className={clsx({
                                                    [classes.approveIcon]: true,
                                                    [classes.activeIcon]: this.state.approved === 'true',
                                                })}
                                            />
                                        )}
                                    </IconButton>
                                )}
                            </div>
                        </div>

                        {/* ACTIVITY INFO SECTION  */}
                        <ApprovalActivityToolbar
                            approvals={section.review ? Object.values(section.review.approvals) : null}
                            section={section}
                        />

                        {/* COMMENT SECTION  */}
                        <Collapse in={this.state.showComments} unmountOnExit>
                            <div className={classes.comments}>
                                <div className={classes.conversationContainer}>
                                    {section.review &&
                                        Array.isArray(section.review.comments) &&
                                        section.review.comments.map((comment, index) => (
                                            <div className={classes.bubbleContainer} key={`comment-${index}`}>
                                                <div className={classes.chatBubble}>
                                                    <div className={classes.bubbleName}>{comment.name}</div>

                                                    <div className={classes.bubbleComment}>{comment.comment}</div>
                                                </div>
                                                <div className={classes.bubbleMeta}>{`${dayjs(comment.date).format(
                                                    'MMMM D,'
                                                )} at ${dayjs(comment.date).format(' h:mm A')}`}</div>
                                            </div>
                                        ))}
                                </div>
                                <div className={classes.writeComment}>
                                    <div component="form" className={classes.inputContainer}>
                                        <Input
                                            InputProps={{
                                                classes: {
                                                    root: classes.multilineInput,

                                                    focused: classes.multilineInputFocused,
                                                },
                                            }}
                                            multiline
                                            fullWidth={true}
                                            placeholder="Add a comment..."
                                            errorMessage="Comment must be greater then one character"
                                            value={comment.value}
                                            valid={comment.valid}
                                            onChange={(e) =>
                                                this.setState({
                                                    comment: {
                                                        value: e.target.value,
                                                        valid: true,
                                                    },
                                                })
                                            }
                                        />
                                        <Button
                                            classes={{
                                                root: classes.chatButton,
                                            }}
                                            text="comment"
                                            variant="contained"
                                            onClick={this.handleAddComment.bind(this)}
                                            loading={this.state.submittingComment}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </RoundedSection>
                </section>
            </>
        );
    }
}

export default connect(null, { setSnackbar })(withStyles(styles)(ReviewSection));
