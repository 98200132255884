import { colors } from 'global-styles';

const styles = (theme) => ({
    ...colors,
    muiAlert: {
        width: '100%',
    },
    MuiAlertIcon: {
        padding: '10px 0',
    },
    snackbar: {
        width: 500,
        display: 'flex',
        // alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
    content: {
        marginTop: 5,
    },
    success: {
        backgroundColor: colors.successGreen,
    },
    error: {
        backgroundColor: colors.error,
    },
    customInfo: {
        color: '#4f5668',
        backgroundColor: '#edecea',
    },
    warning: {
        backgroundColor: colors.warningRed,
    },

    icon: {
        fontSize: 20,
    },
    customEndIcon: {
        marginLeft: 0,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },

    message: {
        display: 'flex',
        alignItems: 'center',
    },
    snackbarButton: {
        color: '#3367d6',
        padding: '0 6px',
        height: 'auto',
    },
    snackbarButtonLabel: {
        fontSize: 14,
        fontWeight: 500,
    },
});
export default styles;
