const styles = (theme) => ({
    paper: {
        padding: '30px 41px',
        width: 490,
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    content: {
        padding: 0,
    },
    title: {
        paddingBottom: 0,
        textAlign: 'center',
        fontSize: 20,
        color: '#454c5d',
        fontWeight: 700,
        fontFamily: '"Lato", "Roboto", "sans serif"',
    },
    action: {},
    subtitle: {
        marginBottom: 20,
        textAlign: 'center',
    },
    actions: {
        justifyContent: 'center',
        padding: 0,
    },
});

export default styles;
