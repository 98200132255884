const styles = (theme) => ({
    searchGrid: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        display: 'flex',
    },
    filterButton: {
        height: 57,
        width: 57,
    },
    reviewButton: {
        height: 42,
        marginRight: 5,
    },
    deleteButton: {
        height: 42,
        width: 42,
    },
    addNewButton: {
        height: 57,

        marginRight: 5,
    },
});

export default styles;
