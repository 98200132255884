const styles = (theme) => ({
    root: {},
    input: {
        fontSize: 18,
        textDecoration: 'underline',
        fontWeight: 700,
        fontFamily: 'Lato',
    },
    checkbox: {
        border: '1px solid rgba(151,151,151,0.5)',
        borderRadius: 8,
        padding: '8px 20px',
        marginBottom: 8,
    },
    specialTitle: {
        fontSize: 14,
        margin: '16px 0px',
    },
});

export default styles;
