const styles = (theme) => ({
    root: {
        padding: 0,
    },
    content: {
        display: 'flex',
    },
    search: {
        backgroundColor: theme.palette.tuna.main,
        height: '100%',
        flex: '0 1 330px',
        position: 'sticky',
        top: 0,
        padding: 30,
    },
    dropArea: {
        flex: 1,
        padding: 30,
    },
    blinker: {
        animation: '1s blinker linear infinite',
        fontWeight: 500,
        fontSize: 20,
    },
    saved: {
        color: theme.palette.success.main,
        fontWeight: 500,
        fontSize: 26,
    },

    '@keyframes blinker': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
});

export default styles;
