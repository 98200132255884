const init = {
    OrganizationId: { value: 0, valid: true },
    CategoryId: { value: 0, valid: true },
    title: { value: '', valid: true },
    media: { value: '', valid: true },
    videoUrl: { value: '', valid: true },
    information: { value: '', valid: true },
    file: { value: '', valid: true },
};

export default init;
