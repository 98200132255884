import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import queryString from 'query-string';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import { Search, DeleteForever, Add } from '@material-ui/icons';
// Components
import Select from 'components/Input/Select';
import Input from 'components/Input';
import Page from 'components/Page';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import BasicDialog from 'components/Dialogs/BasicDialog';
import CustomTable from 'components/CustomTable';
import StyledButton from 'components/Button';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
// styles
import styles from './styles';

const TableHeaders = ['Name', 'Chapters', ''];

const OrganizationUsers = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;

    const query = queryString.parse(props.history.location.search);

    const [deleteModalData, setDeleteModalData] = useState(null);
    const [users, setUsers] = useState({ count: 0, data: [] });
    const [loadingUsers, setLoadingUsers] = useState(false);
    // const [, setRemovingUsers] = useState(false);
    const userRemovals = React.useRef([]);
    const [search, setSearch] = useState('');
    // Org
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });
    const [organization, setOrganization] = useState(ParentOrgId || query.OrganizationId || -1);
    // chapter
    const [Chapters, setChapters] = useState({ count: 0, data: [] });
    const [chapter, setChapter] = useState('all');
    // role
    const [Roles, setRoles] = useState({ count: 0, data: [] });
    const [role, setRole] = useState('all');

    // Pagination
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState({
        orderBy: 'createdAt',
        order: 'desc',
    });

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        _getUsers();
    }, [page, perPage, order, organization]);

    useEffect(() => {
        async function _getChapters() {
            try {
                const chapters = await API.get('ClutchAPI', `/organizations?ParentId=${organization}`);
                mounted && setChapters(chapters);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        if (!!organization) {
            _getChapters();
        }
    }, [organization, dispatch]);

    useEffect(() => {
        async function _getRoles() {
            try {
                const roles = await API.get('ClutchAPI', `/organizations/${organization}/roles`);
                mounted && setRoles(roles);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }
        if (!!organization) {
            _getRoles();
        }
    }, [organization, dispatch]);

    async function _getUsers(query = {}, setLoadingState = true) {
        if (organization === -1) return;

        if (search) {
            query.search = search;
        }

        if (chapter && chapter !== 'all') {
            query.OrganizationId = chapter;
        }

        if (role && role !== 'all') {
            query.RoleId = role;
        }

        if (order.orderBy && order.order) {
            query.orderBy = order.orderBy;
            query.order = order.order;
        }

        query.page = page + 1;
        query.perPage = perPage;

        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingUsers(true);
        try {
            const users = await API.get('ClutchAPI', `/organizations/${organization}/users?${params}`);
            mounted && setUsers(users);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingUsers(false);
    }

    // Handle Functions
    // async function handleDeleteUser(user, index) {
    //     userRemovals.current[user.id] = true;
    //     mounted && setRemovingUsers(true);
    //     try {
    //         await API.del("ClutchAPI", `/users/${user.id}`);
    //         setDeleteModalData(null);
    //         /** Remove the user from the state, and requery without loading state */
    //         const clonedUsers = cloneDeep(users);
    //         clonedUsers.count -= 1;
    //         clonedUsers.data.splice(index, 0);
    //         setUsers(clonedUsers);
    //         /** Requery without loading state */
    //         await _getUsers({}, false);
    //     } catch (error) {
    //         dispatch(setSnackbar(checkError(error)));
    //     }
    //     delete userRemovals.current[user.id];
    //     mounted && setRemovingUsers(false);
    // }

    // Pagination
    function handleChangePage(e, page) {
        mounted && setPage(page);
    }

    function handleChangeRows(e) {
        mounted && setPerPage(e.target.value);
    }

    function handleSort(order, index) {
        let translation = {
            Name: 'firstName',
        };
        let translated = translation[TableHeaders[index]];
        mounted && setOrder({ orderBy: translated, order });
    }

    return (
        <Page
            topNavigationProps={{
                pageTitle: 'Organization Users',
                children: (
                    <StyledButton
                        text="Add User"
                        disabled={organization === -1}
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={() => props.history.push(`/org-users/new?OrganizationId=${organization}`)}
                    />
                ),
            }}>
            <BasicDialog
                open={!!deleteModalData}
                title={deleteModalData ? deleteModalData.title : ''}
                subtitle={'Are you sure you would like to delete this User?'}
                onClose={() => setDeleteModalData(null)}
                onSubmit={deleteModalData ? deleteModalData.onSubmit : null}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3} md={4}>
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        typography="Search by name"
                        fullWidth
                        placeholder="All"
                    />
                </Grid>
                {!ParentOrgId && (
                    <Grid item xs={12} lg={3} md={4}>
                        <Select
                            typography={'Organization'}
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => {
                                setOrganization(e.target.value);
                                setChapter('all');
                                setChapters({ data: [], count: 0 });
                                setRole('all');
                                setRoles({ data: [], count: 0 });
                            }}>
                            <MenuItem key={`org-all`} value={-1}>
                                Select an organization
                            </MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                )}
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Chapter'}
                        classes={{ root: classes.selectHeight }}
                        disabled={Chapters.data.length === 0}
                        value={chapter}
                        onChange={(e) => setChapter(e.target.value)}>
                        <MenuItem key={`chapter-all`} value={'all'}>
                            All
                        </MenuItem>
                        {Chapters.data.map((chapter) => {
                            return (
                                <MenuItem key={`chapter-${chapter.id}`} value={chapter.id}>
                                    {chapter.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Role'}
                        classes={{ root: classes.selectHeight }}
                        disabled={Roles.data.length === 0}
                        value={role}
                        onChange={(e) => setRole(e.target.value)}>
                        <MenuItem key={`role-all`} value={'all'}>
                            All
                        </MenuItem>
                        {Roles.data.map((role) => {
                            return (
                                <MenuItem key={`role-${role.id}`} value={role.id}>
                                    {role.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item xs={12} lg={1} md={4} className={classes.searchGrid}>
                    <Button
                        disableElevation
                        classes={{ root: classes.filterButton }}
                        onClick={() => _getUsers()}
                        variant="contained"
                        color={'primary'}>
                        <Search />
                    </Button>
                </Grid>
            </Grid>
            <CustomTable
                headers={TableHeaders}
                count={users.count}
                page={page}
                onSort={handleSort}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                rowsPerPage={perPage}
                initialOrderIndex={1}
                rows={(loadingUsers ? Array.from(new Array(6)) : users.data).map((user, index) => {
                    if (!user)
                        return {
                            columns: SkeletonTableRow({
                                frontElements: [],
                                count: 2,
                                backElements: [
                                    <HorizontalCell>
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.reviewButton,
                                            }}
                                            variant="contained"
                                            color={'primary'}
                                            text="Details"
                                        />
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.deleteButton,
                                            }}
                                            variant="contained"
                                            variantType="containedRed"
                                            color={'primary'}
                                            text={<DeleteForever />}
                                        />
                                    </HorizontalCell>,
                                ],
                            }),
                        };

                    return {
                        columns: [
                            `${user.firstName} ${user.lastName}`,
                            user.chaptersCount,
                            <HorizontalCell>
                                <StyledButton
                                    disableElevation
                                    disabled={!!userRemovals.current[user.id]}
                                    classes={{ root: classes.reviewButton }}
                                    variant="contained"
                                    color={'primary'}
                                    text="Details"
                                    onClick={() =>
                                        props.history.push(`/org-users/${user.id}?OrganizationId=${organization}`)
                                    }
                                />
                                {/* <StyledButton
                                    disableElevation
                                    disabled={!!userRemovals.current[user.id]}
                                    classes={{ root: classes.deleteButton }}
                                    variant="contained"
                                    variantType="containedRed"
                                    color={"primary"}
                                    text={<DeleteForever />}
                                    onClick={() =>
                                        setDeleteModalData({
                                            title: (
                                                <span>
                                                    Delete User
                                                    <br />
                                                    {`"${user.Organization.name}" - ${user.information}`}
                                                </span>
                                            ),
                                            onSubmit: () =>
                                                handleDeleteUser(user, index),
                                        })
                                    }
                                /> */}
                            </HorizontalCell>,
                        ],
                    };
                })}
            />
        </Page>
    );
};

export default withStyles(styles)(OrganizationUsers);
