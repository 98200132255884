import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Logo from 'assets/images/ClutchBlack.svg';
import config from 'config';

import styles from './styles';

const AuthHeader = (props) => {
    const { classes } = props;

    return <img src={Logo} alt={config.name} className={classes.root} />;
};

export default withStyles(styles)(AuthHeader);
