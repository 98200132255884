import React from 'react';
// Modules
import { AllFileTypes } from 'utils/file-types';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
// Components
import Input from 'components/Input';
import FileInput from 'components/Input/FileInput';
// Wysiwyg
import Wysiwyg from 'components/Input/Wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
// Styles
import styles from './styles';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.title = props.title || '';
        this.body = props.body
            ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.body)))
            : EditorState.createEmpty();
        this.helperText = props.helperText || '';
        this.required = props.required || false;

        // So that we don't get this ridiculous warning about changing default checked..
        this.defaultChecked = { required: this.required };
    }

    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                title: this.title,
                body: typeof this.body === 'string' ? this.body : '', // Body can initially be an object with the editor state this is no bueno,
                helperText: this.helperText,
                required: this.required,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                        <Input
                            placeholder="Type File Upload Title"
                            variant="standard"
                            fullWidth
                            onChange={(e) => this.onChange('title', e.target.value)}
                            defaultValue={this.title}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControlLabel
                            classes={{
                                label: classes.requiredLabel,
                            }}
                            control={
                                <Checkbox
                                    color="primary"
                                    defaultChecked={this.defaultChecked.required}
                                    onChange={(e) => this.onChange('required', e.target.checked)}
                                />
                            }
                            label={'Required'}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Wysiwyg
                            ref="body"
                            classes={{ editor: classes.editor }}
                            defaultEditorState={this.body}
                            placeholder="Type Body Text Here"
                            onEditorStateChange={(e) =>
                                this.onChange('body', draftToHtml(convertToRaw(e.getCurrentContent())))
                            }
                            toolbar={{
                                options: ['blockType', 'fontFamily', 'inline', 'list', 'link'], // This is where you can specify what options you need in
                                //the toolbar and appears in the same order as specified
                                fontFamily: {
                                    options: [
                                        'Lato',
                                        'Arial',
                                        'Georgia',
                                        'Impact',
                                        'Tahoma',
                                        'Times New Roman',
                                        'Verdana',
                                    ],
                                },
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <FileInput
                            disabled={true}
                            accept={[...AllFileTypes, 'video/mp4']}
                            classes={{ uploadRoot: classes.toolbarItem }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} classes={{ root: classes.helper }}>
                        <Input
                            placeholder="Helper Text (Optional)"
                            fullWidth
                            variant="standard"
                            defaultValue={this.helperText}
                            onChange={(e) => this.onChange('helperText', e.target.value)}
                            InputProps={{
                                classes: { input: classes.helperText },
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(FileUpload);
