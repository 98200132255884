import { combineReducers } from 'redux';

// Import reducers here
import snackbar from './snackbar';
import cognitoUser from './cognitoUser';
import theme from './theme';
import organization from './organization';
import chapterSafetyAlert from './chapterSafetyAlert';
import personalSafetyAlert from './personalSafetyAlert';
import incidentReport from './incidentReport';

const rootReducer = combineReducers({
    snackbar,
    cognitoUser,
    theme,
    organization,
    chapterSafetyAlert,
    personalSafetyAlert,
    incidentReport,
});

export default rootReducer;
