import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import Formalize from '@fyresite/formalize';
import checkError from 'utils/check-error';
import FormValidator from 'utils/FormValidator';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, Divider } from '@material-ui/core';
import { ChevronLeft, DeleteForever } from '@material-ui/icons';
// Components
import Page from 'components/Page';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Input from 'components/Input';
import AsyncSelect from 'components/Input/AsyncSelect';
import CustomTable from 'components/CustomTable';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import BasicDialog from 'components/Dialogs/BasicDialog/BasicDialog';
// Init
import FORM_INIT from './init';
// Constants
import { policyIdentifiers, reviewerPolicies } from 'global-constants';
// styles
import styles from './styles';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow/SkeletonTableRow';

const TableHeaders = ['Event Reviewers', ''];
const DEBOUNCE_TIMER = 500;

const selectPlaceHolder = 'Type to search for reviewer';

class Chapter extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);
        this.query = queryString.parse(this.props.location.search);
        this.state = {
            deleteModalData: null,
            loadingReviewers: false,
            submitting: false,
            perPage: 50,
            page: 0,
            assignee: { label: selectPlaceHolder, value: '' },
            ChapterReviewers: { count: 0, data: [] },
            EventReviewers: { count: 0, data: [] },
            form: cloneDeep(this.INIT),
        };
    }

    async componentDidMount() {
        this.mounted = true;

        this.mounted && this.setState({ loadingReviewers: true });
        const params = this.props.match.params;

        try {
            const [ChapterReviewers, EventReviewers, Chapter] = await Promise.all([
                API.get(
                    'ClutchAPI',
                    `/organizations/${this.query.ParentId}/users?OrganizationId=${
                        params.id
                    }&policies=${reviewerPolicies.join(',')}`
                ),
                API.get(
                    'ClutchAPI',
                    `/organizations/${this.query.ParentId}/users?&policies=${reviewerPolicies.join(
                        ','
                    )}&perPage=50&page=1`
                ),
                API.get('ClutchAPI', `/organizations/${params.id}?ParentId=${this.query.ParentId}`),
            ]);
            const formedChapter = Formalize(this.INIT, Chapter);

            this.setState({
                ChapterReviewers,
                EventReviewers,
                form: formedChapter,
                loadingReviewers: false,
            });
        } catch (error) {
            this.setState({ loadingReviewers: false });
            setSnackbar(checkError(error));
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleLoadEventReviewers(inputValue, callback) {
        clearTimeout(this.RequestTimer);
        this.RequestTimer = setTimeout(async () => {
            try {
                const reviewers = await API.get(
                    'ClutchAPI',
                    `/organizations/${this.query.ParentId}/users?&policies=${reviewerPolicies.join(
                        ','
                    )}&perPage=50&page=1&search=${inputValue}`
                );
                const values = reviewers.data.map((reviewer) => ({
                    label: `${reviewer.firstName} ${reviewer.lastName}`,
                    value: reviewer,
                }));
                callback(values);
            } catch (error) {
                callback([]);
            }
        }, DEBOUNCE_TIMER);
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const formClone = Object.assign({}, this.state.form);
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                formClone[field].value = value;
            }
        }
        formClone[field].valid = valid;

        this.setState({ form: formClone });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { setSnackbar } = this.props;
        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        const query = queryString.parse(this.props.location.search);
        const params = this.props.match.params;
        const { form } = this.state;
        try {
            await API.patch('ClutchAPI', `/organizations/${params.id}?ParentId=${query.ParentId}`, {
                body: {
                    university: form.university.value,
                    location: form.location.value,
                },
            });
            Mixpanel.track('Chapter updated');
            setSnackbar('Chapter details have been saved');
        } catch (error) {
            setSnackbar(checkError(error, 'error'));
        }
        this.mounted && this.setState({ submitting: false });
    };

    async handleAssignUser() {
        const { setSnackbar } = this.props;
        if (!this.state.assignee.value) return setSnackbar('Select a reviewer to assign to this chapter');

        this.setState({ addingAssignee: true });
        try {
            await API.post(
                'ClutchAPI',
                `/organizations/${this.query.ParentId}/users/${this.state.assignee.value.id}/assign-organization/${this.props.match.params.id}`
            );
            // Add role to user
            const reviewers = cloneDeep(this.state.ChapterReviewers);
            reviewers.data.push(this.state.assignee.value);
            reviewers.count += 1;
            this.setState({
                ChapterReviewers: reviewers,
                assignee: { value: selectPlaceHolder, label: '' },
                addingAssignee: false,
            });
        } catch (error) {
            this.setState({ addingAssignee: false });
            this.props.setSnackbar(checkError(error));
        }
    }

    async handleUnassignUser() {
        const { deleteModalData } = this.state;

        try {
            await API.del(
                'ClutchAPI',
                `/organizations/${this.query.ParentId}/users/${deleteModalData.reviewer.id}/assign-organization/${this.props.match.params.id}`
            );
            // Add role to user
            const reviewers = cloneDeep(this.state.ChapterReviewers);
            reviewers.data = reviewers.data.filter((reviewer) => reviewer.id !== deleteModalData.reviewer.id);
            reviewers.count -= 1;
            this.setState({ ChapterReviewers: reviewers, deleteModalData: null });
        } catch (error) {
            console.log(error);
            this.props.setSnackbar(checkError(error));
            throw error;
        }
    }

    render() {
        const { classes, history, cognitoUser } = this.props;
        const { deleteModalData, ChapterReviewers, EventReviewers, loadingReviewers, page, perPage, form } = this.state;

        return (
            <Page
                maxWidth={900}
                topNavigationProps={{
                    pageTitle: 'Chapter Detail',
                }}>
                <BasicDialog
                    open={!!deleteModalData}
                    {...(deleteModalData ? deleteModalData : {})}
                    subtitle="Are you sure you would like to unassign this user?"
                    onClose={() => this.setState({ deleteModalData: null })}
                    onSubmit={this.handleUnassignUser.bind(this)}
                />
                <Button
                    fullWidth={false}
                    text="Return to Chapters"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => history.goBack()}
                    startIcon={<ChevronLeft />}
                />
                <form onSubmit={this.handleSubmit}>
                    <Grid container justify="space-between" className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Input
                                ref="university"
                                value={form.university.value}
                                valid={form.university.valid}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'university',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="University"
                                placeholder="Name of university"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                ref="location"
                                value={form.location.value}
                                valid={form.location.valid}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'location',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Location"
                                placeholder="Location"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        type="submit"
                        text="Save Details"
                        variant="contained"
                        color="primary"
                        size="large"
                        loading={this.state.submitting}
                        onClick={this.handleSubmit.bind(this)}
                    />
                </form>
                <Divider light className={classes.divider} />
                <Grid container spacing={4} style={{ marginBottom: 20 }}>
                    <Grid item xs={9}>
                        <AsyncSelect
                            id="chapters"
                            async={true}
                            placeholder="Search by name"
                            defaultOptions={EventReviewers.data.map((reviewer) => ({
                                label: `${reviewer.firstName} ${reviewer.lastName}`,
                                value: reviewer,
                            }))}
                            loadOptions={this.handleLoadEventReviewers.bind(this)}
                            onChange={(assignee) => this.setState({ assignee })}
                            value={this.state.assignee.label}
                            // loading={!!this.state.value}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            variant="contained"
                            text="Add Existing Reviewer"
                            color="primary"
                            size="large"
                            classes={{ root: classes.buttonSize }}
                            loading={this.state.addingAssignee}
                            onClick={this.handleAssignUser.bind(this)}
                        />
                    </Grid>
                </Grid>
                {(cognitoUser.policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS] ||
                    cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY]) && (
                    <Button
                        fullWidth
                        text="Add New Reviewer"
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => this.props.history.push(`/org-users/new?OrganizationId=${this.query.ParentId}`)}
                    />
                )}
                <CustomTable
                    headers={TableHeaders}
                    count={ChapterReviewers.count}
                    page={page}
                    rowsPerPage={perPage}
                    rows={(loadingReviewers ? Array.from(new Array(6)) : ChapterReviewers.data).map((reviewer) => {
                        if (!reviewer)
                            return {
                                columns: SkeletonTableRow({
                                    count: 1,
                                    backElements: [
                                        <HorizontalCell>
                                            <Button
                                                disableElevation
                                                disabled
                                                classes={{
                                                    root: classes.reviewButton,
                                                }}
                                                variant="contained"
                                                color="primary"
                                                text="Review"
                                            />
                                        </HorizontalCell>,
                                    ],
                                }),
                            };
                        return {
                            columns: [
                                <Link
                                    className={classes.link}
                                    to={`/org-users/${reviewer.id}?OrganizationId=${this.query.ParentId}`}>
                                    {reviewer.firstName} {reviewer.lastName}
                                </Link>,
                                <HorizontalCell>
                                    <Button
                                        disableElevation
                                        onClick={() =>
                                            this.setState({
                                                deleteModalData: {
                                                    reviewer,
                                                    title: (
                                                        <span>
                                                            Unassign
                                                            <br />
                                                            {`"${reviewer.firstName} ${reviewer.lastName}"`}
                                                            <br />
                                                            From Chapter
                                                        </span>
                                                    ),
                                                },
                                            })
                                        }
                                        classes={{ root: classes.deleteButton }}
                                        variant="contained"
                                        variantType="containedRed"
                                        color="primary"
                                        text={<DeleteForever />}
                                    />
                                </HorizontalCell>,
                            ],
                        };
                    })}
                />
            </Page>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(Chapter));
