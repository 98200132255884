import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    get as getChapterSafetyAlert,
    remove as deleteChapterSafetyAlert,
    patch as updateChapterSafetyAlert,
    sendEmail as sendChapterSafetyEmail,
} from 'redux/actions/chapterSafetyAlert';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import checkError from 'utils/check-error';
import queryString from 'query-string';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Checkbox,
    Grid,
    Divider,
    List,
    ListItem,
    Collapse,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import { ChevronLeft, Search } from '@material-ui/icons';

// Components
import EmailDialog from 'components/Dialogs/EmailDialog';
import BasicDialog from 'components/Dialogs/BasicDialog';
import Autocomplete from 'components/Input/Autocomplete';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import DeleteButton from 'components/Button/DeleteButton';
import Page from 'components/Page';
// import DownloadButton      from 'components/Button/DownloadButton';
import EmailButton from 'components/Button/EmailButton';
import RoundedSection from 'components/RoundedSection';
import RoundedSectionBody from 'components/RoundedSection/RoundedSectionBody';
import RoundedSectionLabel from 'components/RoundedSection/RoundedSectionLabel';
import MemberMessenger from './MemberMessenger';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const ChapterSafetyAlert = ({ classes, ...props }) => {
    const query = queryString.parse(props.history.location.search);
    const dispatch = useDispatch();
    const { chapterSafetyAlert, cognitoUser } = useSelector(({ chapterSafetyAlert, cognitoUser }) => ({
        chapterSafetyAlert,
        cognitoUser,
    }));

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [emailListModalOpen, setEmailListModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [removing, setRemoving] = useState(false);
    const [selectUsers, setSelectUsers] = useState('');
    const [selectType, setSelectType] = useState('None');
    const [collapsed, setCollapsed] = useState({});
    const [search, setSearch] = useState('');

    useEffect(() => {
        async function _getAlert() {
            if (!query.OrganizationId || !query.ParentId) {
                dispatch(setSnackbar('Permission Denied'));
                return props.history.push('/chapter-safety');
            }

            setLoading(true);
            try {
                await dispatch(
                    getChapterSafetyAlert({
                        id: props.match.params.id,
                        ...query,
                    })
                );
                setLoading(false);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
                props.history.push('/chapter-safety');
            }
        }
        _getAlert();
    }, [dispatch, props.match.params.id, props.history]);

    function handleSelectUsersClick(e, value) {
        setSelectUsers(value);

        let selected = value === 'all';
        let cloned = cloneDeep(chapterSafetyAlert);
        cloned.AlertResponses.forEach((alert) => {
            alert.selected = selected;
        });

        dispatch(updateChapterSafetyAlert(cloned));
    }

    function handleAlertCheckboxClick(e, id) {
        setSelectUsers('');

        let cloned = cloneDeep(chapterSafetyAlert);

        cloned.AlertResponses.forEach((alert) => {
            if (alert.id === id) alert.selected = e.target.checked;
        });

        dispatch(updateChapterSafetyAlert(cloned));
    }

    async function handleDeleteSafetyAlert() {
        setRemoving(true);
        try {
            await dispatch(deleteChapterSafetyAlert(chapterSafetyAlert.id));
            Mixpanel.track('Safety alert removed');
            dispatch(setSnackbar('Safety Alert was removed!'));
            props.history.push('/chapter-safety');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        setRemoving(false);
    }

    async function handleSendEmails(emails) {
        try {
            await dispatch(sendChapterSafetyEmail(emails.replace(/ /g, '').split(',')));
            Mixpanel.track('Safety alert email sent');
            dispatch(setSnackbar('Emails sent'));
            setEmailListModalOpen(false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    function handleCollapseResponse(response) {
        let collapseCopy = Object.assign({}, collapsed);
        collapseCopy[response.id] = collapseCopy[response.id] ? !collapseCopy[response.id] : true;
        setCollapsed(collapseCopy);
    }

    const responses = chapterSafetyAlert.AlertResponses.filter((response) => {
        if (!!search) {
            return (
                `${response.User.firstName} ${response.User.lastName}`.toLowerCase().includes(search.toLowerCase()) &&
                response.status === selectType
            );
        }
        return response.status === selectType;
    });

    return loading ? (
        <Page maxWidth={720}>
            <Skeleton type="infoPage" />
        </Page>
    ) : (
        <Page maxWidth={720}>
            <EmailDialog
                open={emailListModalOpen}
                onClose={() => setEmailListModalOpen(false)}
                onSubmit={handleSendEmails}
            />

            <BasicDialog
                open={deleteModalOpen}
                title={
                    <span>
                        Delete Safety Alert
                        <br />
                        {`"${chapterSafetyAlert.Organization.name}" - ${chapterSafetyAlert.Creator.firstName} ${chapterSafetyAlert.Creator.lastName}`}
                    </span>
                }
                subtitle={'Are you sure you would like to delete this Safety Alert?'}
                onClose={() => setDeleteModalOpen(false)}
                onSubmit={handleDeleteSafetyAlert}
            />
            <div className={classes.topPageForm}>
                <Button
                    fullWidth={false}
                    text="Return to Chapter Safety Alerts"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => props.history.push('/chapter-safety')}
                    startIcon={<ChevronLeft />}
                />
                <div className={classes.rightButtons}>
                    {/* <DownloadButton className={classes.actionButton} /> */}
                    <EmailButton disabled={removing} onClick={() => setEmailListModalOpen(true)} />
                    <DeleteButton disabled={removing} onClick={() => setDeleteModalOpen(true)} tooltip="Delete Alert" />
                </div>
            </div>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel primary="Chapter" secondary={chapterSafetyAlert.Organization.name} />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel
                        margin
                        primary="Submitted By"
                        secondary={
                            cognitoUser.policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS] ||
                            cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY] ? (
                                <Link
                                    to={`/org-users/${chapterSafetyAlert.Creator.id}?OrganizationId=${query.OrganizationId}`}>
                                    {`${chapterSafetyAlert.Creator.firstName} ${chapterSafetyAlert.Creator.lastName}`}
                                </Link>
                            ) : (
                                `${chapterSafetyAlert.Creator.firstName} ${chapterSafetyAlert.Creator.lastName}`
                            )
                        }
                    />
                    <RoundedSectionLabel
                        margin
                        primary="Submitted Date"
                        secondary={dayjs(chapterSafetyAlert.createdAt).format('MMMM DD, YYYY')}
                    />
                    <RoundedSectionLabel
                        primary="Submitted Time"
                        secondary={dayjs(chapterSafetyAlert.createdAt).format('h:mm A')}
                    />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel primary="Alert Text" secondary={chapterSafetyAlert.information} />
                </RoundedSectionBody>
            </RoundedSection>
            <MemberMessenger classes={{ root: classes.roundedSection }} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variantType="containedGreen"
                        text={`Safe (${chapterSafetyAlert.TotalSafe}/${chapterSafetyAlert.TotalAlerts})`}
                        classes={{ root: classes.safeButton }}
                        onClick={(e) => setSelectType('Safe')}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variantType="containedRed"
                        text={`Not Safe (${chapterSafetyAlert.TotalNotSafe}/${chapterSafetyAlert.TotalAlerts})`}
                        classes={{ root: classes.notSafeButton }}
                        onClick={(e) => setSelectType('Not Safe')}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variantType="containedOuterSpace"
                        text={`Pending Safe (${chapterSafetyAlert.TotalNone}/${chapterSafetyAlert.TotalAlerts})`}
                        classes={{ root: classes.pendingSafeButton }}
                        onClick={(e) => setSelectType('None')}
                    />
                </Grid>
            </Grid>
            <div className={classes.selectHandlers}>
                <Autocomplete
                    options={responses.map((response) => `${response.User.firstName} ${response.User.lastName}`)}
                    getOptionLabel={(option) => option}
                    classes={{
                        root: classes.search,
                        inputRoot: classes.bgWhite,
                        popupIndicatorOpen: classes.searchOpen,
                    }}
                    popupIcon={<Search />}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    inputProps={{
                        placeholder: 'Search Members',
                    }}
                />
                <div className={classes.selectButtons}>
                    <Button
                        color={selectUsers === 'all' ? 'primary' : 'inherit'}
                        classes={{
                            text: selectUsers === 'all' ? classes.activeText : null,
                        }}
                        fullWidth={false}
                        variant="text"
                        text="Select All"
                        onClick={(e) => handleSelectUsersClick(e, 'all')}
                    />
                    <span className={classes.selectHandlersSlash}>/</span>
                    <Button
                        color={selectUsers === 'none' ? 'primary' : 'inherit'}
                        classes={{
                            text: selectUsers === 'none' ? classes.activeText : null,
                        }}
                        fullWidth={false}
                        variant="text"
                        text="Select None"
                        onClick={(e) => handleSelectUsersClick(e, 'none')}
                    />
                </div>
            </div>
            {responses.length > 0 ? (
                <List dense classes={{ root: classes.listRoot }}>
                    {responses.map((response, index) => {
                        return (
                            <React.Fragment key={`member-chapter-safety-alert-${index}`}>
                                <ListItem
                                    button
                                    onClick={(e) => handleCollapseResponse(response)}
                                    classes={{ button: classes.listItemButton }}>
                                    <ListItemText
                                        classes={{ primary: classes.username }}
                                        primary={`${response.User.lastName}, ${response.User.firstName}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={response.selected || false}
                                            onChange={(e) => handleAlertCheckboxClick(e, response.id)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Collapse in={collapsed[response.id]} timeout="auto" unmountOnExit>
                                    <ListItem className={classes.nested}>
                                        <ListItemText
                                            inset
                                            primary={response.note ? response.note : 'No notes available'}
                                        />
                                        <ListItemText
                                            inset
                                            primary={response.location ? response.location : 'No location provided'}
                                        />
                                        <ListItemSecondaryAction
                                            classes={{
                                                root: classes.listItemSecondaryAction,
                                            }}></ListItemSecondaryAction>
                                    </ListItem>
                                </Collapse>
                                {responses.length - 1 !== index && <Divider light />}
                            </React.Fragment>
                        );
                    })}
                </List>
            ) : null}
        </Page>
    );
};

export default withStyles(styles)(ChapterSafetyAlert);
