import { colors } from 'global-styles';

const styles = (theme) => ({
    mb10: {
        marginBottom: 10,
    },
    divider: {
        backgroundColor: 'rgba(151,151,151,0.41)',
    },
    listItem: {
        paddingLeft: 8,
        paddingTop: 12,
        paddingBottom: 12,
    },
    title: {
        marginBottom: 8,
    },
    checked: {
        color: colors.successGreen,
        fontSize: 32,
    },
    unchecked: {
        color: colors.brandColors.metallicBlue,
        fontSize: 32,
    },
    paper: {
        marginTop: 12,
        marginBottom: 12,
        padding: 24,
    },
    alert: {
        color: colors.warningRed,
    },
    pointer: {
        cursor: 'pointer',
    },
});

export default styles;
