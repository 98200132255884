const styles = (theme) => ({
    root: {},
    input: {
        fontSize: 20,
        fontWeight: 700,
        fontFamily: 'Lato',
    },
});

export default styles;
