import React from 'react';

// Modules
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// Components
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
import style from './styles';

const EventSquare = ({ date, title, classes, className, loading, onClick, empty, ...props }) => {
    const rootClasses = classNames({
        [classes.root]: true,
        [className]: !!className,
    });

    return (
        <Paper className={rootClasses} onClick={onClick}>
            <div className={classes.body}>
                <div className={classes.date}>
                    <Skeleton variant="rect" animation="wave" height={20} />
                </div>
                <div className={classes.title}>
                    <Skeleton variant="rect" animation="wave" height={20} />
                </div>
            </div>
        </Paper>
    );
};

export default withStyles(style)(EventSquare);
