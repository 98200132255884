import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const RoundedSection = ({ classes, ...props }) => {
    return (
        <div className={classes.root} {...props}>
            {props.children}
        </div>
    );
};

export default withStyles(styles)(RoundedSection);
