import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';

import styles from './styles';

class Select extends React.Component {
    render() {
        const {
            classes,
            valid = true,
            errorMessage,
            validator,
            children,
            marginBottom,
            InputLabelProps,
            typography,
            ...rest
        } = this.props;

        let bottomMargin = marginBottom ? { marginBottom } : {};
        return (
            <>
                {typography && <Typography variant="h6">{typography}</Typography>}
                <TextField
                    select
                    style={bottomMargin}
                    classes={{ root: classes.root }}
                    type="text"
                    fullWidth={true}
                    error={!valid}
                    variant="outlined"
                    helperText={!valid && errorMessage}
                    InputLabelProps={{
                        className: classes.label,
                        ...InputLabelProps,
                    }}
                    {...rest}>
                    {children}
                </TextField>
            </>
            // set defaults for text fields app-wide
        );
    }
}

export default withStyles(styles)(Select);
