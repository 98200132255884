const styles = (theme) => ({
    root: {},
    outlinedOuterSpace: {
        ...theme.buttons.outlined.outerSpace,
    },
    outlinedOutlinedGrey: {
        ...theme.buttons.outlined.grey,
    },
    outlinedDashedGrey: {
        ...theme.buttons.outlined.dashedGrey,
    },
    contained: {
        ...theme.buttons.contained,
    },
    containedOuterSpace: {
        ...theme.buttons.contained.outerSpace,
    },
    containedRed: {
        ...theme.buttons.contained.red,
    },
    containedGreen: {
        ...theme.buttons.contained.green,
    },
    containedBlue: {
        ...theme.buttons.contained.blue,
    },
    loadingContained: {
        '& svg.MuiCircularProgress-svg': {
            color: '#fff',
        },
    },

    iconButtonRoot: {
        color: 'white',
        borderRadius: 6,
        height: 60,
        width: 60,
        padding: 0,
        margin: '0 6px',
    },
});

export default styles;
