import React from 'react';

// Material UI
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

// Styles
import styles from './styles';

class Autocompleter extends React.Component {
    render() {
        const {
            valid = true,
            errorMessage,
            validator,
            classes,
            marginBottom,
            variant = 'outlined',
            value,
            onChange,
            inputProps,
            ...rest
        } = this.props;

        let bottomMargin = marginBottom ? { marginBottom } : {};
        return (
            <Autocomplete
                classes={classes}
                onChange={(_, value) => typeof onChange === 'function' && onChange({ target: { value: value || '' } })}
                value={value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant}
                        style={bottomMargin}
                        error={!valid}
                        helperText={!valid && errorMessage}
                        onChange={onChange}
                        value={value}
                        {...inputProps}
                    />
                )}
                {...rest}
            />
        );
    }
}

export default withStyles(styles)(Autocompleter);
