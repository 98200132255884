const styles = (theme) => ({
    root: {
        minHeight: 150,
    },
    loginButton: {
        marginBottom: 8,
        marginTop: 16,
    },
    bottomLinks: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    bottomLink: {
        textAlign: 'center',
    },
    menuRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    grid: {
        display: 'grid',
        gridAutoFlow: 'row',
        gridGap: '1rem',
        justifyContent: 'center',
        gridTemplateColumns: '40%,40%',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '35%',
        },
    },
    boxImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: '4px 4px 0 0',
        border: '1px solid #dfdfdf',
        background: '#fcfcfc',
        overflow: 'hidden',
        padding: '1rem',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 100,
        },
        '&::before': {
            content: '',
            display: 'block',
            paddingBottom: '100%',
        },
        '& img': {
            width: '100%',
            objectFit: 'contain',
            gridArea: '1 / 1 / 2 / 2',
        },
    },

    boxFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 35,
        borderRadius: ' 0 0 4px 4px',
        border: '1px solid #dfdfdf',
        borderTop: 0,
        fontWeight: 400,
        boxShadow: ' 0 2px 0 #dfdfdf',
        color: '#222222',
        fontSize: 14,
    },
});

export default styles;
