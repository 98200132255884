import { useEffect, useState } from 'react';
// Redux
import { setSnackbar } from 'redux/actions/snackbar';
// Module
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';

const useOrganizations = (mounted, dispatch, ParentOrgId) => {
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && mounted && _getOrganizations();
    }, [mounted, dispatch]);

    return [Organizations, setOrganizations];
};

export default useOrganizations;
