import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { awsConfig } from 'aws/aws_resource';
class S3MediaOperations {
    static fileToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    static dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    static async putObjectBase64(base64, Key) {
        const buffer = this.dataURLtoFile(base64, 'image');
        return S3MediaOperations.putObject(buffer, Key);
    }

    static async putObject(buffer, Key) {
        const credentials = await Auth.currentCredentials();
        const S3 = new AWS.S3({
            credentials: Auth.essentialCredentials(credentials),
            region: S3MediaOperations.region,
        });

        let response = await S3.putObject({
            Body: buffer,
            Bucket: awsConfig.Storage.AWSS3.bucket,
            Key,
        }).promise();
        return {
            ETag: response.ETag,
            bucket: awsConfig.Storage.AWSS3.bucket,
            key: Key,
        };
    }

    static async getDocumentUrl({ bucket, key }) {
        const credentials = await Auth.currentCredentials();
        const S3 = new AWS.S3({
            credentials: Auth.essentialCredentials(credentials),
            region: S3MediaOperations.region,
        });

        return S3.getSignedUrl('getObject', { Bucket: bucket, Key: key });
    }
}

S3MediaOperations.region = 'us-east-1';

export default S3MediaOperations;
