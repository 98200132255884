// React
import React from 'react';
// Redux
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Mixpanel from 'mixpanel-browser';
import Formalize from '@fyresite/formalize';
import checkError from 'utils/check-error';
import FormValidator from 'utils/FormValidator';
import phone from 'phone';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Grid, Paper } from '@material-ui/core';
import { stateAbbreviations } from 'global-constants';
// Components
import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Input/Select';
// Variables
import FORM_INIT from './init';
// styles
import styles from './styles';

class UserDetails extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);

        const { OrganizationId } = queryString.parse(props.location.search);

        this.state = {
            submitting: false,
            sendingInvitation: false,
            OrganizationId,
            ...Formalize(this.INIT, this.props.user),
        };
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { setSnackbar, organization } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        try {
            await API.patch('ClutchAPI', `/organizations/${organization}/users/${this.state.id}`, {
                body: {
                    firstName: this.state.firstName.value,
                    lastName: this.state.lastName.value,
                    phone: phone(this.state.phone.value)[0],
                    office: this.state.office.value,
                    address: this.state.address.value,
                    city: this.state.city.value,
                    state: this.state.state.value,
                    zip: this.state.zip.value,
                },
            });
            Mixpanel.track('User details updated');
            this.mounted && this.setState({ submitting: false });
            setSnackbar('User was updated');
        } catch (error) {
            setSnackbar(checkError(error));
            this.mounted && this.setState({ submitting: false });
        }
    }

    handleResendInvitation = async (e) => {
        this.mounted && this.setState({ sendingInvitation: true });
        try {
            await API.post(
                'ClutchAPI',
                `/users/${this.state.id}/resend-invitation-email?ParentId=${this.state.OrganizationId}`
            );
            this.mounted && this.setState({ sendingInvitation: false });
            this.props.setSnackbar(`Invitation Email has been sent to ${this.state.email.value.value}`);
        } catch (error) {
            console.log(error);
            this.props.setSnackbar(checkError(error));
            this.mounted && this.setState({ sendingInvitation: false });
        }
    };

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes, showDialog, user } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Input
                                ref="firstName"
                                value={this.state.firstName.value}
                                valid={this.state.firstName.valid}
                                errorMessage="Name is Required"
                                validator={() => !!this.state.firstName.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'firstName',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="First Name*"
                                placeholder="User first name"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="lastName"
                                value={this.state.lastName.value}
                                valid={this.state.lastName.valid}
                                errorMessage="Name is Required"
                                validator={() => !!this.state.lastName.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'lastName',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Last Name*"
                                placeholder="User Last name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                value={this.state.email.value.value}
                                disabled={true}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'email',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Email* (uneditable)"
                                placeholder="User Email"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="phone"
                                value={this.state.phone.value}
                                valid={this.state.phone.valid}
                                errorMessage="Phone number is Required"
                                validator={() => phone(this.state.phone.value).length > 0}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'phone',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Phone Number*"
                                placeholder="Users Phone Number"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="office"
                                value={this.state.office.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'office',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Office number"
                                placeholder="Number to office"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                ref="address"
                                value={this.state.address.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'address',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Street Address"
                                placeholder="Street Address"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="city"
                                value={this.state.city.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'city',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={20}
                                fullWidth
                                typography="City"
                                placeholder="City"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                ref="state"
                                value={this.state.state.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'state',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={20}
                                fullWidth
                                typography="State"
                                placeholder="State">
                                <MenuItem key={''} value={''}>
                                    state
                                </MenuItem>
                                {stateAbbreviations.map((state) => {
                                    return (
                                        <MenuItem key={state} value={state}>
                                            {state}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                ref="zip"
                                value={this.state.zip.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'zip',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={20}
                                fullWidth
                                typography="Zip Code"
                                placeholder="Zip Code"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                color="primary"
                                text="Save User"
                                variant="contained"
                                onClick={(e) => {
                                    if (user.integrationId) showDialog({ onSubmit: this.handleSubmit.bind(this, e) });
                                    else this.handleSubmit(e);
                                }}
                                loading={this.state.submitting}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                color="primary"
                                text="Resend Invitation Email"
                                variantType="outlinedOuterSpace"
                                onClick={this.handleResendInvitation.bind(this)}
                                loading={this.state.sendingInvitation}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const actions = { setSnackbar };

export default connect(null, actions)(withStyles(styles)(withRouter(UserDetails)));
