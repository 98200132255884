import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
import queryString from 'query-string';
import dayjs from 'dayjs';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import { Search, DeleteForever } from '@material-ui/icons';
// Components
import Select from 'components/Input/Select';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import CustomTable from 'components/CustomTable';
import BasicDialog from 'components/Dialogs/BasicDialog';
import StyledButton from 'components/Button';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import Page from 'components/Page';
// Others
import { eventStatus, eventStatusDropdownLabels } from 'global-constants';
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const EventHeaders = ['', 'Chapter', 'Event Name', 'Date', 'Reviewing Role', 'Status', ''];

const Events = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;
    const FirstChapterId = cognitoUser.FirstChapter ? cognitoUser.FirstChapter.id : null;

    const [deleteModalData, setDeleteModalData] = useState(null);
    const [status, setStatus] = useState('all');
    const [dateRange, setDateRange] = useState('all');
    const [Events, setEvents] = useState({ count: 0, data: [] });
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [, setRemovingEvents] = useState(false);
    const eventRemovals = React.useRef([]);
    // Org
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });
    const [organization, setOrganization] = useState(ParentOrgId || 'all');
    // chapter
    const [Chapters, setChapters] = useState({ count: 0, data: [] });
    const [chapter, setChapter] = useState('all');
    // Type
    const [EventTypes, setEventTypes] = useState({ count: 0, data: [] });
    const [eventType, setEventType] = useState('all');

    // Pagination
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState({
        orderBy: 'startDate',
        order: 'asc',
    });

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        _getEvents();
    }, [page, perPage, order]);

    useEffect(() => {
        async function _getChapters() {
            if (FirstChapterId)
                return setChapters({
                    data: cognitoUser.Organizations,
                    count: cognitoUser.Organizations.length,
                });

            try {
                const chapters = await API.get('ClutchAPI', `/organizations?ParentId=${organization}`);
                mounted && setChapters(chapters);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        async function _getEventTypes() {
            try {
                const types = await API.get('ClutchAPI', `/organizations/${organization}/event-types`);
                mounted && setEventTypes(types);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        if (!!organization) {
            _getChapters();
            _getEventTypes();
        }
    }, [organization, dispatch]);

    async function _getEvents(query = {}, setLoadingState = true) {
        if (organization && organization !== 'all') {
            query.ParentId = organization;
        }
        if (chapter && chapter !== 'all') {
            query.OrganizationId = chapter;
        }
        if (eventType && eventType !== 'all') {
            query.EventTypeId = eventType;
        }
        if (dateRange && dateRange !== 'all') {
            query.dateRange = dateRange;
        }
        // else if (dateRange === 'all') {
        //     query.dateRange = '365';
        // }
        if (status && status !== 'all') {
            query.reviewStatus = [status];
        } else if (status === 'all') {
            let statusCopy = Object.assign({}, eventStatus);
            delete statusCopy.Approved;
            delete statusCopy.Denied;
            query.reviewStatus = Object.keys(statusCopy).map((key) => statusCopy[key]);
        }

        if (order.orderBy && order.order) {
            query.orderBy = order.orderBy;
            query.order = order.order;
        }

        query.page = page + 1;
        query.perPage = perPage;

        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingEvents(true);
        try {
            const events = await API.get('ClutchAPI', `/events?${params}`);
            mounted && setEvents(events);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingEvents(false);
    }

    // Handle Functions
    async function handleDeleteEvent(event, index) {
        eventRemovals.current[event.id] = true;
        mounted && setRemovingEvents(true);
        try {
            await API.del(
                'ClutchAPI',
                `/events/${event.id}?OrganizationId=${event.OrganizationId}&ParentId=${event.ParentId}`
            );
            setDeleteModalData(null);
            /** Remove the event from the state, and requery without loading state */
            const clonedEvents = cloneDeep(Events);
            clonedEvents.count -= 1;
            clonedEvents.data.splice(index, 0);
            setEvents(clonedEvents);
            /** Requery without loading state */
            await _getEvents({}, false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        delete eventRemovals.current[event.id];
        mounted && setRemovingEvents(false);
    }
    // Pagination
    function handleChangePage(e, page) {
        mounted && setPage(page);
    }

    function handleChangeRows(e) {
        mounted && setPerPage(e.target.value);
    }
    function handleSort(order, index) {
        let translation = {
            Chapter: 'Chapter.name',
            'Event Name': 'title',
            Date: 'startDate',
            'Event Type': 'EventType.name',
            Status: 'reviewStatus',
        };
        let translated = translation[EventHeaders[index]];
        mounted && setOrder({ orderBy: translated, order });
    }

    return (
        <Page
            topNavigationProps={{
                pageTitle: 'Events for Review',
                hideButton: true,
            }}>
            <BasicDialog
                open={!!deleteModalData}
                title={deleteModalData ? deleteModalData.title : ''}
                subtitle={'Are you sure you would like to delete this Event?'}
                onClose={() => setDeleteModalData(null)}
                onSubmit={deleteModalData ? deleteModalData.onSubmit : null}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Status'}
                        classes={{ root: classes.selectHeight }}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}>
                        <MenuItem key={'status-all'} value="all">
                            In Review
                        </MenuItem>
                        {Object.keys(eventStatus).map((key) => {
                            return (
                                <MenuItem key={`status-${key}`} value={eventStatus[key]}>
                                    {eventStatusDropdownLabels[key]}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                {!ParentOrgId && (
                    <Grid item xs={12} lg={3} md={4}>
                        <Select
                            typography={'Organization'}
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => {
                                setChapter('all');
                                setChapters({ data: [], count: 0 });
                                setEventType('all');
                                setEventTypes({ data: [], count: 0 });
                                setOrganization(e.target.value);
                            }}>
                            <MenuItem key={`org-all`} value={'all'}>
                                All
                            </MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                )}
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Chapter'}
                        classes={{ root: classes.selectHeight }}
                        disabled={Chapters.data.length === 0}
                        value={chapter}
                        onChange={(e) => setChapter(e.target.value)}>
                        <MenuItem key={`chapter-all`} value={'all'}>
                            All
                        </MenuItem>
                        {Chapters.data.map((chapter) => {
                            return (
                                <MenuItem key={`chapter-${chapter.id}`} value={chapter.id}>
                                    {chapter.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Event Type'}
                        classes={{ root: classes.selectHeight }}
                        disabled={EventTypes.data.length === 0}
                        value={eventType}
                        onChange={(e) => setEventType(e.target.value)}>
                        <MenuItem key={`eventtypes-all`} value={'all'}>
                            All
                        </MenuItem>
                        {EventTypes.data.map((type) => {
                            return (
                                <MenuItem key={`type-${type.id}`} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Date Range'}
                        classes={{ root: classes.selectHeight }}
                        value={dateRange}
                        onChange={(e) => setDateRange(e.target.value)}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="7">Next 7 Days</MenuItem>
                        <MenuItem value="30">Next 30 Days</MenuItem>
                        <MenuItem value="0">Today</MenuItem>
                        <MenuItem value="-7">Last 7 Days</MenuItem>
                        <MenuItem value="-30">Last 30 Days</MenuItem>
                        <MenuItem value="-365">Last Year</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} lg={1} md={4} className={classes.searchGrid}>
                    <Button
                        disableElevation
                        classes={{ root: classes.filterButton }}
                        onClick={() => _getEvents()}
                        variant="contained"
                        color={'primary'}>
                        <Search />
                    </Button>
                </Grid>
            </Grid>
            <CustomTable
                headers={EventHeaders}
                count={Events.count}
                page={page}
                onSort={handleSort}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                initialOrder="asc"
                initialOrderIndex={3}
                rowsPerPage={perPage}
                rows={(loadingEvents ? Array.from(new Array(6)) : Events.data).map((event, index) => {
                    if (!event)
                        return {
                            columns: SkeletonTableRow({
                                frontElements: [<div className={classNames(classes.circle, classes.circleBlue)} />],
                                count: 5,
                                backElements: [
                                    <HorizontalCell>
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.reviewButton,
                                            }}
                                            variant="contained"
                                            color={'primary'}
                                            text="Review"
                                        />
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.deleteButton,
                                            }}
                                            variant="contained"
                                            variantType="containedRed"
                                            color={'primary'}
                                            text={<DeleteForever />}
                                        />
                                    </HorizontalCell>,
                                ],
                            }),
                        };

                    const circleClasses = classNames({
                        [classes.circle]: true,
                        [classes.circleWarning]: true,
                        [classes.circleRed]: [eventStatus.Rejected, eventStatus.Denied].includes(event.reviewStatus),
                        [classes.circleGreen]: [eventStatus.Approved].includes(event.reviewStatus),
                    });
                    return {
                        columns: [
                            <div className={circleClasses} />,
                            event.Organization.name,
                            event.information.title,
                            dayjs(event.information.startDate).format('M/D/YYYY'),
                            event.ParentOrganization.ApprovalProcesses[event.ApprovalStep - 1].role.name,
                            event.reviewStatus,
                            <HorizontalCell>
                                {(cognitoUser.policies[policyIdentifiers.COMMENT_ON_EVENTS] ||
                                    cognitoUser.policies[policyIdentifiers.EVENT_APPROVAL_REQUIRED] ||
                                    cognitoUser.policies[policyIdentifiers.VIEW_EVENTS] ||
                                    cognitoUser.policies[policyIdentifiers.FINAL_EVENT_APPROVER] ||
                                    cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY]) && (
                                    <StyledButton
                                        disableElevation
                                        disabled={!!eventRemovals.current[event.id]}
                                        classes={{ root: classes.reviewButton }}
                                        variant="contained"
                                        color={'primary'}
                                        onClick={() =>
                                            props.history.push(
                                                `/event/${event.id}?OrganizationId=${event.OrganizationId}&ParentId=${event.ParentId}`
                                            )
                                        }
                                        text="Review"
                                    />
                                )}
                                {(cognitoUser.policies[policyIdentifiers.REMOVE_EVENTS] ||
                                    cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY]) && (
                                    <StyledButton
                                        disableElevation
                                        disabled={!!eventRemovals.current[event.id]}
                                        classes={{ root: classes.deleteButton }}
                                        variant="contained"
                                        variantType="containedRed"
                                        color={'primary'}
                                        text={<DeleteForever />}
                                        onClick={() =>
                                            setDeleteModalData({
                                                title: (
                                                    <span>
                                                        Delete Event
                                                        <br />
                                                        {`"${event.Organization.name}" - ${event.information.title}`}
                                                    </span>
                                                ),
                                                onSubmit: () => handleDeleteEvent(event, index),
                                            })
                                        }
                                    />
                                )}
                            </HorizontalCell>,
                        ],
                    };
                })}
            />
        </Page>
    );
};

export default withStyles(styles)(Events);
