import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from '@material-ui/core';
import Button from 'components/Button';
// Styles
import { markupTypes } from 'containers/Secure/EventStep/EventStepEditor/components/types';
import style from './styles';

const SkeletonEventCreator = ({ classes }) => {
    return (
        <div className={classes.root}>
            <div className={classes.itemTypes}>
                {markupTypes.map((itemType, index) => (
                    <div className={classes.itemType} key={`skeleitem-${index}`}>
                        <img src={itemType.icon} alt={itemType.title} />
                        <Typography className={classes.itemTypeText}>{itemType.title}</Typography>
                    </div>
                ))}
            </div>
            <div className={classes.sections}>
                <Skeleton animation="wave" height={20} />
                <div className={classes.margin} />
                <Skeleton animation="wave" height={200} variant="rect" className={classes.margin} />
                <Button
                    text="ADD SECTION"
                    fullWidth
                    classes={{ root: classes.addSection }}
                    variantType="outlinedDashedGrey"
                />
            </div>
        </div>
    );
};

export default withStyles(style)(SkeletonEventCreator);
