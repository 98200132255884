import React, { useEffect, useState } from 'react';
import { Auth, Hub, API } from 'aws-amplify';
import { connect } from 'react-redux';

import { set as setCognitoUser, signOut } from './redux/actions/cognitoUser';

import FullscreenLoader from './components/FullscreenLoader';
import SecureStack from './containers/Secure';
import AuthStack from './containers/Auth';
import { policyIdentifiers } from 'global-constants';

import { useDispatch } from 'react-redux';

/**
 * A HOC that routes a user to the appropriate navigation stack based on the authorizing function.
 * Must have access to the history.
 * @param {*} props
 */

const Authorizer = (props) => {
    const { cognitoUser } = props;
    const [user, setUser] = useState(null);
    const [init, setInit] = useState(true);
    const [authorizing, setAuthorizing] = useState(props.location.pathname === '/authorizing' ? true : false);
    const dispatch = useDispatch();

    async function handleSignout(message) {
        console.error(message);
        await signOut();
        setUser(null);
        setInit(false);
    }

    useEffect(() => {
        async function authenticate() {
            try {
                Hub.listen('auth', async ({ payload: { event, data } }) => {
                    switch (event) {
                        case 'signIn':
                            setUser(data);
                            break;
                        case 'signOut':
                            await signOut();
                            setUser(null);
                    }
                    setInit(false);
                });
                const user = await Auth.currentAuthenticatedUser();
                setUser(user);
            } catch (error) {
                handleSignout(error);
            }
        }

        authenticate();
    }, []);

    async function handleAuth() {
        try {
            if (user === null) {
                dispatch(setCognitoUser({}));
                return;
            }
            const userData = await API.get('ClutchAPI', '/users/current');

            // Only site admins do not need a ParentOrg attached to them
            if (!userData.policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
                if (!userData.policies[policyIdentifiers.ADMIN_APP_LOGIN])
                    throw new Error('Account does not have permission to login to the Clutch Admin Dashboard');
                if (userData.ParentOrganizations.length === 0)
                    throw new Error('Account is not associated to an organization');
                userData.ParentOrganization = userData.ParentOrganizations[0];

                // If a userData does not have access to manage organization, they must have a firstchapter attached for dropdown chapter fields.
                if (!userData.policies[policyIdentifiers.MANAGE_ORGANIZATIONS]) {
                    if (userData.Organizations.length === 0)
                        throw new Error('Account is not associated to any chapters in which to manage.');
                    userData.FirstChapter = userData.Organizations[0];
                }
            }

            dispatch(
                setCognitoUser({
                    ...user,
                    ...userData,
                })
            );
            setInit(false);
            setAuthorizing(false);
        } catch (error) {
            handleSignout(error);
        }
    }

    useEffect(() => {
        handleAuth();
    }, [user]);

    let routes;

    if (init === true || authorizing === true) {
        routes = <FullscreenLoader />;
    } else if (JSON.stringify(cognitoUser) === '{}') {
        routes = <AuthStack {...props} />;
    } else if (cognitoUser && cognitoUser.sub) {
        routes = <SecureStack {...props} />;
    } else {
        routes = <FullscreenLoader />;
    }

    return routes;
};

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

export default connect(mapStateToProps, { signOut, setCognitoUser })(Authorizer);
