import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

class WhitespaceItem extends React.Component {
    onChange(field, value) {
        this[field] = value;
        if (typeof this.props.onChange === 'function') this.props.onChange({});
    }

    render() {
        const { classes, children } = this.props;
        return <div className={classes.root}>{children}</div>;
    }
}

export default withStyles(styles)(WhitespaceItem);
