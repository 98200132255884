// React
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Modules
import { API } from 'aws-amplify';
import queryString from 'query-string';
import checkError from 'utils/check-error';
import Mixpanel from 'mixpanel-browser';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';

// Components

import Select from 'components/Input/Select';
import BasicDialog from 'components/Dialogs/BasicDialog';
import UpsertEventTypeDialog from 'components/Dialogs/UpsertEventTypeDialog';
import SkeletonEventTypeSquare from 'components/Skeleton/SkeletonEventTypeSquare';
import Page from 'components/Page';
import EventTypeListItem from './EventTypeListItem';

// styles
import styles from './styles';

const EventTypes = ({ classes, history, location, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;

    const query = queryString.parse(location.search);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [selectedTypeData, setSelectedTypeData] = useState(null);
    const [EventTypes, setEventTypes] = useState({
        count: 0,
        data: [],
    });
    const [loadingEventTypes, setLoadingEventTypes] = useState(false);
    // Org
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });
    const [organization, setOrganization] = useState(ParentOrgId || query.organization || 0);

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        // Allow the state to be "saved" in the url
        history.push(
            `?${queryString.stringify({
                organization,
            })}`
        );
        _getEventTypes();
    }, [organization]);

    async function _getEventTypes(query = {}, setLoadingState = true) {
        if (organization === 0) return;

        query.isLatestVersion = true;
        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingEventTypes(true);
        try {
            const templates = await API.get('ClutchAPI', `/organizations/${organization}/event-types?${params}`);
            mounted && setEventTypes(templates);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingEventTypes(false);
    }

    // Handle Functions
    async function handleDeleteEventType() {
        try {
            await API.del('ClutchAPI', `/organizations/${organization}/event-types/${selectedTypeData.id}`);
            Mixpanel.track('Event type deleted');
            dispatch(setSnackbar(`${selectedTypeData.name} type deleted successfully`));
            _getEventTypes();
            setDeleteModalOpen(false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    async function handleCreateSubmit({ name, bypassApproval }) {
        try {
            await API.post('ClutchAPI', `/organizations/${organization}/event-types`, {
                body: { name, bypassApproval },
            });
            Mixpanel.track('Event type created');
            dispatch(setSnackbar(`${name.value} type added to Event Types`));
            _getEventTypes();
            setCreateModalOpen(false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    async function handleDuplicateSubmit({ name, bypassApproval }) {
        try {
            await API.put('ClutchAPI', `/organizations/${organization}/event-types/${selectedTypeData.id}`, {
                body: { name, bypassApproval },
            });
            Mixpanel.track('Event type duplicated');
            dispatch(setSnackbar(`Event Type duplicated successfully`));
            _getEventTypes();
            setDuplicateModalOpen(false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    async function handleEditSubmit({ name, bypassApproval }) {
        try {
            await API.patch('ClutchAPI', `/organizations/${organization}/event-types/${selectedTypeData.id}`, {
                body: { name, bypassApproval },
            });
            Mixpanel.track('Event type meta information updated');
            dispatch(setSnackbar(`${name} updated`));
            _getEventTypes();
            setEditModalOpen(false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    function handleDuplicateClick(index) {
        setSelectedTypeData(EventTypes.data[index]);
        setDuplicateModalOpen(true);
    }

    function handleEditClick(index) {
        setSelectedTypeData(EventTypes.data[index]);
        setEditModalOpen(true);
    }

    function handleDeleteClick(index) {
        setSelectedTypeData(EventTypes.data[index]);
        setDeleteModalOpen(true);
    }

    const TopNavButton = () => {
        return (
            <div>
                <Button
                    onClick={() => setCreateModalOpen(true)}
                    color="primary"
                    disabled={organization === 0}
                    startIcon={<Add />}
                    variant="contained">
                    New Type
                </Button>
            </div>
        );
    };

    console.log(selectedTypeData);

    return (
        <Page
            topNavigationProps={{
                pageTitle: 'Event Types',
                children: <TopNavButton />,
            }}>
            <UpsertEventTypeDialog
                open={createModalOpen}
                title="New Type"
                errorMessage="Please provide a valid name"
                placeholder="Enter an Event Type name"
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateSubmit}
            />
            <UpsertEventTypeDialog
                open={editModalOpen}
                title="Edit Type"
                errorMessage="Please provide a valid name"
                placeholder="Enter an Event Type name"
                value={selectedTypeData}
                onClose={() => setEditModalOpen(false)}
                onSubmit={handleEditSubmit}
            />
            <UpsertEventTypeDialog
                open={duplicateModalOpen}
                title="Duplicate Type"
                errorMessage="Please provide a valid name"
                placeholder="Enter an Event Type name"
                value={selectedTypeData}
                onClose={() => setDuplicateModalOpen(false)}
                onSubmit={handleDuplicateSubmit}
            />
            <BasicDialog
                open={deleteModalOpen}
                subtitle={'Are you sure you would like to delete this Event Type?'}
                onSubmit={handleDeleteEventType}
                onClose={() => setDeleteModalOpen(false)}
            />
            <Grid container spacing={2}>
                {!ParentOrgId && (
                    <Grid item xs={12} lg={3} md={4}>
                        <Select
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => {
                                setOrganization(e.target.value);
                            }}
                            typography={'Organization'}>
                            <MenuItem key={`org-none`} value={0}>
                                Select an Organization
                            </MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2}>
                {loadingEventTypes
                    ? Array.from(Array(3).keys()).map((n) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={n}>
                              <SkeletonEventTypeSquare />
                          </Grid>
                      ))
                    : EventTypes.data.map((type, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={type.id}>
                              <EventTypeListItem
                                  onClick={() => history.push(`/event-types/${type.id}?OrganizationId=${organization}`)}
                                  onDelete={() => handleDeleteClick(index)}
                                  onDuplicate={() => handleDuplicateClick(index)}
                                  onEdit={() => handleEditClick(index)}
                                  type={type}
                                  key={type.id}
                              />
                          </Grid>
                      ))}
            </Grid>
        </Page>
    );
};

export default withStyles(styles)(EventTypes);
