const styles = (theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 400,
        justifyContent: 'center',
        width: '100%',
    },
});

export default styles;
