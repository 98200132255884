// React
import React, { useState } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase, IconButton, Paper, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

// Custom Components
import Button from 'components/Button';

// Styles
import styles from './styles';

const EventTypeListItem = ({ classes, onDelete, onDuplicate, onEdit, type, ...props }) => {
    const { name } = type;
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.root}>
            <ButtonBase className={classes.root} focusRipple={true}>
                <Paper className={classes.paper} {...props}>
                    <Typography>{name}</Typography>
                </Paper>
            </ButtonBase>
            {/* Everything below is not inside buttonbase bc nested buttons throw an error */}
            <IconButton className={classes.iconButton} onClick={(_) => setOpen(!open)}>
                <MoreHoriz />
            </IconButton>
            {open && (
                <>
                    <Paper className={classes.dropdown} elevation={2}>
                        <Button
                            classes={{ root: classes.button }}
                            onClick={() => {
                                setOpen(false);
                                onEdit();
                            }}>
                            Edit
                        </Button>
                        <Button
                            classes={{
                                root: [classes.button, classes.middle].join(' '),
                            }}
                            onClick={() => {
                                setOpen(false);
                                onDuplicate();
                            }}>
                            Duplicate
                        </Button>
                        <Button
                            classes={{
                                root: [classes.button, classes.bottom].join(' '),
                            }}
                            onClick={() => {
                                setOpen(false);
                                onDelete();
                            }}>
                            Delete
                        </Button>
                    </Paper>
                    <div className={classes.triangle}></div>
                    <div className={classes.triangleInner}></div>
                </>
            )}
        </div>
    );
};

export default withStyles(styles)(EventTypeListItem);
