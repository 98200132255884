import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import dayjs from 'dayjs';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem, IconButton } from '@material-ui/core';
import { Edit, DeleteForever, FileCopy, Add } from '@material-ui/icons';

// Components
import Select from 'components/Input/Select';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import CustomTable from 'components/CustomTable';
import BasicDialog from 'components/Dialogs/BasicDialog';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import Page from 'components/Page';
// styles
import styles from './styles';

const TableHeaders = ['Title', 'Created', 'Last Updated', ''];

const EventSteps = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;

    const [deleteModalData, setDeleteModalData] = useState(null);
    const [copyModalData, setCopyModalData] = useState(null);
    const [ModuleTemplates, setModuleTemplates] = useState({ count: 0, data: [] });
    const [loadingModuleTemplates, setLoadingModuleTemplates] = useState(false);
    const [, setRemovingModuleTemplates] = useState(false);
    const templateRemovals = React.useRef([]);
    // Org
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });
    const [organization, setOrganization] = useState(ParentOrgId || 0);

    // Pagination
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState({
        orderBy: 'createdAt',
        order: 'desc',
    });

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        _getModuleTemplates();
    }, [page, perPage, order, organization]);

    async function _getModuleTemplates(query = {}, setLoadingState = true) {
        if (organization === 0) return;

        if (order.orderBy && order.order) {
            query.orderBy = order.orderBy;
            query.order = order.order;
        }

        query.isLatestVersion = true;
        query.page = page + 1;
        query.perPage = perPage;

        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingModuleTemplates(true);
        try {
            const templates = await API.get(
                'ClutchAPI',
                `/organizations/${organization}/event-module-templates?${params}`
            );
            mounted && setModuleTemplates(templates);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingModuleTemplates(false);
    }

    // Handle Functions
    async function handleDeleteTempate(template, index) {
        templateRemovals.current[template.versionId] = true;
        mounted && setRemovingModuleTemplates(true);
        try {
            await API.del(
                'ClutchAPI',
                `/organizations/${template.OrganizationId}/event-module-templates/${template.versionId}`
            );
            setDeleteModalData(null);
            /** Remove the template from the state, and requery without loading state */
            const clonedModuleTemplates = cloneDeep(ModuleTemplates);
            clonedModuleTemplates.count -= 1;
            clonedModuleTemplates.data.splice(index, 0);
            setModuleTemplates(clonedModuleTemplates);
            /** Requery without loading state */
            await _getModuleTemplates({}, false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        delete templateRemovals.current[template.versionId];
        mounted && setRemovingModuleTemplates(false);
    }
    async function handleCopyTemplate(template) {
        try {
            const response = await API.put(
                'ClutchAPI',
                `/organizations/${template.OrganizationId}/event-module-templates/${template.versionId}`
            );
            const clonedModuleTemplates = cloneDeep(ModuleTemplates);
            clonedModuleTemplates.count += 1;
            clonedModuleTemplates.data.unshift(response);
            setModuleTemplates(clonedModuleTemplates);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        setCopyModalData(null);
    }
    // Pagination
    function handleChangePage(e, page) {
        mounted && setPage(page);
    }

    function handleChangeRows(e) {
        mounted && setPerPage(e.target.value);
    }
    function handleSort(order, index) {
        let translation = {
            Title: 'name',
            Created: 'createdAt',
            'Last Updated': 'updatedAt',
        };
        let translated = translation[TableHeaders[index]];
        mounted && setOrder({ orderBy: translated, order });
    }

    const TopNavButton = () => {
        return (
            <div>
                <Button
                    onClick={() => props.history.push(`/event-steps/new?OrganizationId=${organization}`)}
                    color="primary"
                    disabled={organization === 0}
                    startIcon={<Add />}
                    variant="contained">
                    New Tempate
                </Button>
            </div>
        );
    };
    return (
        <Page
            topNavigationProps={{
                pageTitle: 'Event Steps',
                children: <TopNavButton />,
            }}>
            <BasicDialog
                open={!!copyModalData}
                {...(copyModalData ? copyModalData : {})}
                subtitle={'Are you sure you would like to copy this Tempate?'}
                onClose={() => setCopyModalData(null)}
            />
            <BasicDialog
                open={!!deleteModalData}
                {...(deleteModalData ? deleteModalData : {})}
                subtitle={'Are you sure you would like to delete this Tempate?'}
                onClose={() => setDeleteModalData(null)}
            />
            <Grid container spacing={2}>
                {!ParentOrgId && (
                    <Grid item xs={12} lg={3} md={4}>
                        <Select
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => {
                                setOrganization(e.target.value);
                            }}
                            typography={'Organization'}>
                            <MenuItem key={`org-none`} value={0}>
                                Select an Organization
                            </MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                )}
            </Grid>
            <CustomTable
                headers={TableHeaders}
                count={ModuleTemplates.count}
                page={page}
                onSort={handleSort}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                initialOrderIndex={2}
                rowsPerPage={perPage}
                rows={(loadingModuleTemplates ? Array.from(new Array(6)) : ModuleTemplates.data).map(
                    (template, index) => {
                        if (!template)
                            return {
                                columns: SkeletonTableRow({
                                    count: 3,
                                    backElements: [
                                        <HorizontalCell>
                                            <IconButton disabled={true}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton disabled={true}>
                                                <FileCopy />
                                            </IconButton>
                                            <IconButton disabled={true}>
                                                <DeleteForever />
                                            </IconButton>
                                        </HorizontalCell>,
                                    ],
                                }),
                            };
                        return {
                            columns: [
                                template.name,
                                dayjs(template.createdAt).format('M/D/YYYY'),
                                dayjs(template.updatedAt).format('M/D/YYYY'),
                                <HorizontalCell>
                                    <IconButton
                                        disabled={!!templateRemovals.current[template.versionId]}
                                        onClick={() =>
                                            props.history.push(
                                                `/event-steps/${template.versionId}?OrganizationId=${template.OrganizationId}`
                                            )
                                        }>
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        disabled={!!templateRemovals.current[template.versionId]}
                                        onClick={() =>
                                            setCopyModalData({
                                                rightButton: { variantType: 'containedOuterSpace', text: 'Copy' },
                                                title: (
                                                    <span>
                                                        Copy Template
                                                        <br />
                                                        {`"${template.name}"`}
                                                    </span>
                                                ),
                                                onSubmit: () => handleCopyTemplate(template, index),
                                            })
                                        }>
                                        <FileCopy />
                                    </IconButton>
                                    <IconButton
                                        disabled={!!templateRemovals.current[template.versionId]}
                                        onClick={() =>
                                            setDeleteModalData({
                                                title: (
                                                    <span>
                                                        Delete Tempate
                                                        <br />
                                                        {`"${template.name}"`}
                                                    </span>
                                                ),
                                                onSubmit: () => handleDeleteTempate(template, index),
                                            })
                                        }>
                                        <DeleteForever />
                                    </IconButton>
                                </HorizontalCell>,
                            ],
                        };
                    }
                )}
            />
        </Page>
    );
};

export default withStyles(styles)(EventSteps);
