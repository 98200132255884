import { useEffect, useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';

const useChapters = ({ organization, mounted, setLoading = () => {}, members }) => {
    const [Chapters, setChapters] = useState({ count: 0, data: [] });
    const dispatch = useDispatch();

    useEffect(() => {
        if (organization !== 'all') {
            _getChapters();
        }
    }, [organization]);

    async function _getChapters(setLoadingState = true) {
        mounted && setLoadingState && setLoading(true);
        let path = `/organizations?ParentId=${organization}`;
        if (members) path += '&members=1';
        try {
            const response = await API.get('ClutchAPI', path);
            mounted && setChapters(response);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoading(false);
    }

    return [Chapters, setChapters, _getChapters];
};

export default useChapters;
