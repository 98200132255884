const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '54px 20px',
    },
    title: {
        width: '100%',
    },
});

export default styles;
