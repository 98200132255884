import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './fullscreen-loader.css';

const FullscreenLoader = (props) => {
    return (
        <div className="fullscreen-loader-container">
            <CircularProgress size={100} />
        </div>
    );
};

export default FullscreenLoader;
