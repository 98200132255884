// Assets
import Heading from 'assets/icons/Heading.svg';
import Title from 'assets/icons/Title.svg';
import BodyText from 'assets/icons/BodyText.svg';
import CheckBox from 'assets/icons/CheckBox.svg';
import FileDownload from 'assets/icons/FileDownload.svg';
import FileUpload from 'assets/icons/FileUpload.svg';
import HorizontalRuler from 'assets/icons/HorizontalRuler.svg';
import ShortTextInput from 'assets/icons/ShortTextInput.svg';
import TextAreaInput from 'assets/icons/TextAreaInput.svg';
import WhiteSpace from 'assets/icons/WhiteSpace.svg';
import Volunteer from 'assets/icons/Volunteer.svg';

export const markupTypes = [
    {
        id: 'title',
        icon: Title,
        title: 'Title',
        markup: { title: '' },
    },
    {
        id: 'heading',
        icon: Heading,
        title: 'Heading',
        markup: { heading: '' },
    },
    {
        id: 'bodyText',
        icon: BodyText,
        title: 'Body Text',
        markup: { body: '' },
    },
    {
        id: 'checkBox',
        icon: CheckBox,
        title: 'CheckBox',
        markup: { title: '', body: '', helperText: '', required: false, initialChecked: false },
    },
    {
        id: 'fileDownload',
        icon: FileDownload,
        title: 'File Download',
        markup: { title: '', file: '', helperText: '' },
    },
    {
        id: 'fileUpload',
        icon: FileUpload,
        title: 'File Upload',
        markup: { title: '', body: '', helperText: '', required: false },
    },
    {
        id: 'shortTextInput',
        icon: ShortTextInput,
        title: 'Short Text Input',
        markup: { title: '', inputType: 'text', placeholder: '', required: false },
    },
    {
        id: 'textAreaInput',
        icon: TextAreaInput,
        title: 'Text Area Input',
        markup: { title: '', placeholder: '', required: false },
    },
    {
        id: 'volunteer',
        icon: Volunteer,
        title: 'Volunteer',
        markup: {
            title: '',
            body: '',
            heading: '',
            required: false,
            multipleRoles: true,
            emailSubject: '',
            emailAttachment: '',
            emailBody: '',
        },
    },
    {
        id: 'whiteSpace',
        icon: WhiteSpace,
        title: 'White Space',
        markup: { margin: 20 },
    },
    {
        id: 'horizontalRuler',
        icon: HorizontalRuler,
        title: 'Horizontal Ruler',
        markup: { height: 1 },
    },
    // {
    //     id: "selects",
    //     icon: HorizontalRuler,
    //     title: "Selection Step",
    //     markup: { selections: [{ moduleVersionId: 'none-selected', active: true }] }
    // },
];
