const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    authForm: {
        margin: '0 auto',
        maxWidth: 400,
    },
    loginButton: {
        marginBottom: 16,
        marginTop: 16,
    },
    bottomLinks: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    bottomLink: {
        textAlign: 'center',
    },
    input: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

export default styles;
