export const SET_SNACKBAR = 'SET_SNACKBAR';
export const INIT = { message: '' };

export default function (state = INIT, { type, payload }) {
    let newState = Object.assign({}, state);

    switch (type) {
        case SET_SNACKBAR:
            newState.message = payload.message;
            newState.options = payload.options;
            return newState;

        default:
            return state;
    }
}
