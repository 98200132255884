const styles = (theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        border: '1px solid rgba(151, 151, 151, 0.3)',
        borderRadius: 6,
        position: 'relative',
        overflow: 'hidden',
    },
});

export default styles;
