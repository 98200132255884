// React
import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import dayjs from 'dayjs';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { DeleteForever, Menu } from '@material-ui/icons';
// Styles
import styles from './styles';

const getItemStyle = (isDragging, draggableStyle) => ({
    border: isDragging ? '3px solid rgba(48,53,66, .5)' : '1px #00000017 solid',
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#3036424d' : 'transparent',
    height: '90%',
});

const TemplatesList = ({ classes, templates, ...props }) => {
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography>Title</Typography>
                <Typography>Created</Typography>
                <Typography>Last Updated</Typography>
                <Typography>Actions</Typography>
            </div>
            <div className={clsx(classes.listItem, classes.listItemStatic)}>
                <List>
                    <ListItem>
                        <div className={classes.listWrapper}>
                            <div className={classes.staticTitle}>
                                <ListItemIcon>
                                    <Menu />
                                </ListItemIcon>
                                <div>
                                    <Typography>Basic Information</Typography>
                                    <span className={classes.staticMeta}>
                                        Basic Information is automatically generated and can't be re-ordered.
                                    </span>
                                </div>
                            </div>
                            <ListItemSecondaryAction
                                classes={{
                                    root: classes.delete,
                                }}>
                                <IconButton disabled={true} className={classes.delete}>
                                    <DeleteForever />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </div>
                    </ListItem>
                </List>
            </div>
            <Droppable droppableId="templates-droppable">
                {(dropProvided, dropSnapshot) => (
                    <div
                        className={classes.list}
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                        style={getListStyle(dropSnapshot.isDraggingOver)}
                        {...dropProvided.droppableProps}>
                        {templates.map((template, index) => {
                            return (
                                <Draggable
                                    key={`template-${template.EventModuleTemplateId}`}
                                    draggableId={`template-${template.EventModuleTemplateId}`}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            className={classes.listItem}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                            <List>
                                                <ListItem>
                                                    <div className={classes.listWrapper}>
                                                        <div className={classes.title}>
                                                            <ListItemIcon>
                                                                <Menu />
                                                            </ListItemIcon>

                                                            <Typography>{template.EventModuleTemplate.name}</Typography>
                                                        </div>
                                                        <Typography>
                                                            {dayjs(template.createdAt).format('M/D/YYYY')}
                                                        </Typography>
                                                        <Typography>
                                                            {dayjs(template.updatedAt).format('M/D/YYYY')}
                                                        </Typography>
                                                        <ListItemSecondaryAction
                                                            classes={{
                                                                root: classes.delete,
                                                            }}>
                                                            <IconButton
                                                                disabled={template.loading}
                                                                className={classes.delete}
                                                                onClick={() => props.onDelete(template)}>
                                                                {template.loading ? (
                                                                    <CircularProgress
                                                                        style={{
                                                                            height: 24,
                                                                            width: 24,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <DeleteForever />
                                                                )}
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </div>
                                                </ListItem>
                                            </List>
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {dropProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default connect(null, { setSnackbar })(withStyles(styles)(TemplatesList));
