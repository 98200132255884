const styles = (theme) => ({
    topPageForm: {
        marginBottom: 20,
    },
    roundedSection: {
        margin: '20px 0',
    },
    buttonHeight: {
        height: 56,
    },
    alignBottom: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    actionButton: {
        cursor: 'pointer',
    },
    middleButton: {
        marginLeft: 8,
        marginRight: 8,
    },
    actionButtonIcon: {
        position: 'absolute',
        left: 20,
        top: 0,
        bottom: 0,
        margin: 'auto',
    },
});

export default styles;
