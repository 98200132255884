import React from 'react';
// Material UI
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
// Styles
import style from './styles';

const SkeletonEventItem = ({ classes, divider }) => {
    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                <Skeleton variant="circle" className={classes.iconBubble} animation="wave" />
                {divider ? (
                    <div className={classes.divider}>
                        <Skeleton variant="rect" animation="wave" style={{ height: '100%' }} />
                    </div>
                ) : null}
            </div>

            <div className={classes.eventContent}>
                <div className={classes.date} />

                <div className={classes.bubble}>
                    <div className={classes.bubbleContent}>
                        <Skeleton variant="rect" height={20} animation="wave" />

                        <div className={classes.time}>
                            <Skeleton height={20} variant="rect" animation="wave" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(style)(SkeletonEventItem);
