const styles = (theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
        [theme.breakpoints.up('md')]: {
            padding: 30,
        },
    },
});

export default styles;
