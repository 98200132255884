import React from 'react';
import { withRouter } from 'react-router';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Typography, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

// styles
import styles from './styles';

const TopNavigation = ({ classes, pageTitle, helperText, children, ...props }) => {
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <div className={classes.texts}>
                    <Typography className={classes.title}>{!!pageTitle ? pageTitle : null}</Typography>
                    <Typography className={classes.helperText} align="left">
                        {!!helperText ? helperText : null}
                    </Typography>
                </div>
                {children}
            </AppBar>
        </div>
    );
};

export default withRouter(withStyles(styles)(TopNavigation));
