const styles = (theme) => ({
    paper: {
        padding: '30px 41px',
        width: 490,
    },
    title: {
        paddingBottom: 0,
        textAlign: 'center',
        fontSize: 20,
        color: '#454c5d',
        fontWeight: 700,
        fontFamily: '"Lato", "Roboto", "sans serif"',
    },
    action: {
        width: 121,
    },
    subtitle: {
        marginBottom: 20,
        textAlign: 'center',
    },
    actions: {
        justifyContent: 'center',
    },
});

export default styles;
