import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// Components
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
import style from './styles';

const SkeletonEventTypeSquare = ({ classes, ...props }) => {
    return (
        <Paper className={classes.root} {...props}>
            <div className={classes.title}>
                <Skeleton variant="rect" animation="wave" height={20} />
            </div>
        </Paper>
    );
};

export default withStyles(style)(SkeletonEventTypeSquare);
