import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import queryString from 'query-string';
import { API } from 'aws-amplify';
import Formalize from '@fyresite/formalize';
import Upload from 'utils/Upload';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import FormValidator from 'utils/FormValidator';
import Mixpanel from 'mixpanel-browser';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
// Components
import Button from 'components/Button';
import Input from 'components/Input';
import Skeleton from 'components/Skeleton';
import Select from 'components/Input/Select';
import FileInput from 'components/Input/FileInput';
import Wysiwyg from 'components/Input/Wysiwyg';
import Page from 'components/Page';

// Wysiwyg
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
// Variables
import FORM_INIT from './init';
import { AllFileTypes } from 'utils/file-types';
// styles
import styles from './styles';

class HouseRule extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);

        const { cognitoUser } = props;
        this.query = queryString.parse(this.props.history.location.search);
        this.ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;
        if (this.ParentOrgId) this.INIT.OrganizationId.value = this.ParentOrgId;

        this.state = {
            Organizations: { data: [], count: 0 },
            editorState: EditorState.createEmpty(),
            submitting: false,
            loading: !isNaN(parseInt(props.match.params.id)),
            pageTitle: '',
            ...cloneDeep(this.INIT),
        };
    }

    componentDidMount() {
        !this.ParentOrgId && this._getOrganizations();
        this.mounted = true;
        if (this.props.match.params.id !== 'new') {
            this.setState({ pageTitle: 'Edit House Rule' });
            this._getRule();
        } else {
            this.setState({ pageTitle: 'New House Rule' });
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async _getRule() {
        const { setSnackbar } = this.props;
        this.mounted && this.setState({ loading: true });
        if (!this.query.OrganizationId) {
            setSnackbar('Permission denied');
            return this.props.history.push('/house-rules');
        }

        try {
            const rule = Formalize(
                this.INIT,
                await API.get(
                    'ClutchAPI',
                    `/organizations/${this.query.OrganizationId}/rules/${this.props.match.params.id}`
                )
            );
            this.mounted &&
                this.setState({
                    ...rule,
                    loading: false,
                    editorState: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(rule.curr_information))
                    ),
                });
        } catch (error) {
            setSnackbar(checkError(error));
            this.props.history.push('/house-rules');
            this.mounted && this.setState({ loading: false });
        }
    }

    async _getOrganizations() {
        try {
            const Organizations = await API.get('ClutchAPI', '/organizations');
            this.setState({ Organizations });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { setSnackbar } = this.props;

        const editorState = this.refs.information.refs.editor.state.editorState;
        let editorSourceHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.updateField({ field: 'information', value: editorSourceHTML });

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        const formData = new FormData();
        formData.append('title', this.state.title.value);
        formData.append('videoUrl', this.state.videoUrl.value);
        formData.append('information', this.state.information.value);
        if (typeof this.state.media.value === 'object' && !this.state.media.value.id)
            formData.append('media', this.state.media.value);
        if (typeof this.state.file.value === 'object' && !this.state.file.value.id)
            formData.append('file', this.state.file.value);

        this.mounted && this.setState({ submitting: true });
        try {
            if (this.state.id) {
                await Upload.patch(
                    `/organizations/${this.state.OrganizationId.value}/rules/${this.state.id}`,
                    formData
                );
                Mixpanel.track('House rule updated');
                setSnackbar('House rule updated');
            } else {
                await Upload.post(`/organizations/${this.state.OrganizationId.value}/rules`, formData);
                Mixpanel.track('House rule created');
                setSnackbar('House rule added');
                this.setState({ ...cloneDeep(this.INIT) });
                this.refs.information.refs.editor.editor.update(EditorState.createEmpty());
            }
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ submitting: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        const { Organizations } = this.state;

        return this.state.loading ? (
            <Page maxWidth={720}>
                <Skeleton type="infoPage" />
            </Page>
        ) : (
            <Page maxWidth={720} topNavigationProps={{ pageTitle: this.state.pageTitle }}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Button
                        classes={{
                            root: classes.mb10,
                            startIcon: classes.chevronLeft,
                        }}
                        text="Return to House Rules"
                        onClick={() => this.props.history.push('/house-rules')}
                        variant="contained"
                        variantType="containedBlue"
                        disabled={this.state.submitting}
                        disableElevation
                        startIcon={<ChevronLeft />}
                    />
                    {!this.ParentOrgId && (
                        <Select
                            ref="OrganizationId"
                            value={this.state.OrganizationId.value}
                            valid={this.state.OrganizationId.valid}
                            errorMessage="Organization Required"
                            validator={() => this.state.OrganizationId.value !== 0}
                            onChange={(e) =>
                                this.updateField({
                                    field: 'OrganizationId',
                                    value: e.target.value,
                                })
                            }
                            marginBottom={10}
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0 || !!this.state.id}
                            typography={'Organization'}>
                            <MenuItem value={0}>Select an Organization</MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                    <Input
                        ref="title"
                        value={this.state.title.value}
                        valid={this.state.title.valid}
                        errorMessage="Title is Required"
                        validator={() => !!this.state.title.value}
                        onChange={(e) =>
                            this.updateField({
                                field: 'title',
                                value: e.target.value,
                            })
                        }
                        marginBottom={10}
                        fullWidth
                        typography="Title"
                        placeholder="Title of page"
                    />
                    <FileInput
                        ref="media"
                        file={this.state.media.value}
                        valid={this.state.media.valid}
                        onChange={(file) =>
                            this.updateField({
                                field: 'media',
                                value: file,
                                expectedType: 'object',
                            })
                        }
                        marginBottom={10}
                        label="Upload Primary Image"
                    />
                    <Input
                        ref="videoUrl"
                        value={this.state.videoUrl.value}
                        valid={this.state.videoUrl.valid}
                        onChange={(e) =>
                            this.updateField({
                                field: 'videoUrl',
                                value: e.target.value,
                            })
                        }
                        marginBottom={10}
                        fullWidth
                        typography="video url (optional)"
                        placeholder="Youtube or Vimeo"
                    />

                    <Wysiwyg
                        ref="information"
                        // CL3-I397 Not required
                        // valid={this.state.information.valid}
                        // errorMessage={"Content is required"}
                        // validator={() =>
                        //     this.state.information.value !== "<p></p>\n"
                        // }
                        defaultEditorState={this.state.editorState}
                        placeholder="Type Body Text Here"
                        label="Content"
                        marginBottom={10}
                        onChange={() =>
                            this.updateField({
                                field: 'information',
                                valid: true,
                            })
                        }
                    />
                    <FileInput
                        ref="file"
                        file={this.state.file.value}
                        valid={this.state.file.valid}
                        onChange={(file) =>
                            this.updateField({
                                field: 'file',
                                value: file,
                                expectedType: 'object',
                            })
                        }
                        accept={AllFileTypes}
                        marginBottom={20}
                        label="Upload Document"
                    />

                    <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                    <Button
                        text={this.state.id ? 'Update House Rule' : 'Add New House Rule'}
                        fullWidth
                        onClick={this.handleSubmit.bind(this)}
                        variant="contained"
                        variantType="containedBlue"
                        disableElevation
                        loading={this.state.submitting}
                    />
                </form>
            </Page>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
const actions = { setSnackbar };

export default connect(mapStateToProps, actions)(withStyles(styles)(HouseRule));
