const grey = 'rgba(0,0,0,0.23)';
const borderRadius = 6;

const styles = (theme) => ({
    wrapper: {
        borderWidth: 1,
        borderColor: grey,
        borderStyle: 'solid',
        borderRadius,
    },
    toolbar: {
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomWidth: 1,
        borderBottomColor: grey,
        marginBottom: 0,
        backgroundColor: '#f8fafd',
    },
    editor: {
        background: 'white',
        padding: '10px 14px',
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
        minHeight: 240,
    },
    errorMessage: {
        color: theme.palette.error.main,
        marginLeft: 14,
        marginRight: 14,
        marginTop: 3,
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
        fontFamil: '"Lato", "Roboto", "sans serif"',
    },
    error: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
});

export default styles;
