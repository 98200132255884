import { colors } from 'global-styles';

const styles = (theme) => ({
    root: {},
    requiredLabel: {
        fontStyle: 'italic',
        fontSize: 16,
        color: '#4a4a4a',
    },
    helperText: {
        fontSize: 14,
    },
    checkIcon: {
        fontSize: 36,
        color: '#d2d2d2',
    },
    checkIconActive: {
        fontSize: 36,
        color: colors.successGreen,
    },
    helper: {
        paddingTop: 0,
    },
    toolbarItem: {
        backgroundColor: '#f8fafd',
        '& div': {
            backgroundColor: '#f8fafd',
        },
    },
    editor: {
        minHeight: 120,
    },
});

export default styles;
