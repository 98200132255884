import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, MenuItem } from '@material-ui/core';
// Components
import Select from 'components/Input/Select';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import CustomTable from 'components/CustomTable';
import StyledButton from 'components/Button';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import Page from 'components/Page';
// Hooks
import useChapters from 'hooks/useChapters';
// styles
import styles from './styles';

const TableHeaders = ['Chapter', 'University', 'Location', ''];

const Chapters = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;
    const mounted = React.useRef(false);

    const [loadingChapters, setLoadingChapters] = useState(false);
    // Org
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });
    const [organization, setOrganization] = useState(ParentOrgId || 'all');
    // Chapters
    const [Chapters, setChapters, _getChapters] = useChapters({
        organization,
        mounted,
        setLoading: setLoadingChapters,
    });
    // Pagination
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        _getChapters();
    }, [page, perPage, organization]);

    // Pagination
    function handleChangePage(e, page) {
        mounted && setPage(page);
    }
    function handleChangeRows(e) {
        mounted && setPerPage(e.target.value);
    }

    return (
        <Page
            topNavigationProps={{
                pageTitle: 'Chapters',
            }}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3} md={4}>
                    {!ParentOrgId && (
                        <Select
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => {
                                setOrganization(e.target.value);
                            }}
                            typography={'Organization'}>
                            <MenuItem key={`org-none`} value="all">
                                Select an Organization
                            </MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                </Grid>
            </Grid>
            <CustomTable
                headers={TableHeaders}
                count={Chapters.count}
                page={page}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                initialOrderIndex={2}
                rowsPerPage={perPage}
                rows={(loadingChapters ? Array.from(new Array(6)) : Chapters.data).map((chapter, index) => {
                    if (!chapter)
                        return {
                            columns: SkeletonTableRow({
                                count: 3,
                                backElements: [
                                    <HorizontalCell>
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.reviewButton,
                                            }}
                                            variant="contained"
                                            color={'primary'}
                                            text="View"
                                        />
                                    </HorizontalCell>,
                                ],
                            }),
                        };

                    return {
                        columns: [
                            chapter.name,
                            chapter.university,
                            chapter.location,
                            <HorizontalCell>
                                <StyledButton
                                    disableElevation
                                    classes={{ root: classes.reviewButton }}
                                    variant="contained"
                                    color={'primary'}
                                    text="View"
                                    onClick={() =>
                                        props.history.push(`/chapters/${chapter.id}?ParentId=${chapter.ParentId}`)
                                    }
                                />
                            </HorizontalCell>,
                        ],
                    };
                })}
            />
        </Page>
    );
};

export default withStyles(styles)(Chapters);
