import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';
import TopNavigation from 'components/TopNavigation';
const Page = ({ classes, maxWidth, topNavigationProps, children }) => {
    return (
        <>
            <TopNavigation {...topNavigationProps} />

            <div className={classes.root} style={{ maxWidth }}>
                {children}
            </div>
        </>
    );
};
Page.propTypes = {
    topNavigationProps: PropTypes.shape({
        pageTitle: PropTypes.string,
        helperText: PropTypes.string,
        children: PropTypes.node,
    }),
    children: PropTypes.node,
};
export default withStyles(styles)(Page);
