// React
import React, { useState } from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { Droppable, Draggable } from 'react-beautiful-dnd';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemIcon, Divider, IconButton } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
// Components
import Input from 'components/Input';
// Assets
import Move from 'assets/icons/Move.svg';
// Styles
import styles from './styles';

const SearchTemplates = ({ classes, templates, ...props }) => {
    const [search, setSearch] = useState('');

    return (
        <div className={classes.root}>
            <Input
                placeholder="Search global steps"
                fullWidth
                value={search}
                InputProps={{
                    startAdornment: <Search id="search" />,
                    endAdornment: (
                        <IconButton className={classes.closeBtn} onClick={() => setSearch('')}>
                            <Close />
                        </IconButton>
                    ),
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
                onChange={(e) => setSearch(e.target.value)}
            />
            <Droppable droppableId="event-module-templates">
                {(dropProvided, dropSnapshot) => (
                    <div ref={dropProvided.innerRef}>
                        <List className={classes.list}>
                            {templates
                                .filter((template) => template.name.toLowerCase().includes(search.toLowerCase()))
                                .map((template, index) => (
                                    <Draggable key={template.id} draggableId={`draggable-${template.id}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListItem className={classes.listItem}>
                                                    <ListItemIcon className={classes.icon}>
                                                        <img src={Move} alt="move template" />
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.text} primary={template.name} />
                                                </ListItem>
                                                <Divider className={classes.divider} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                        </List>
                        {dropProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default connect(null, { setSnackbar })(withStyles(styles)(SearchTemplates));
