import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, MenuItem, List, ListItem } from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
// Components
import StyledButton from 'components/Button';
import Order from 'components/Order';
import Skeleton from 'components/Skeleton';
import Page from 'components/Page';
import Select from 'components/Input/Select';
import BasicDialog from 'components/Dialogs/BasicDialog';
// hooks
import useOrganizations from 'hooks/useOrganizations';
// styles
import styles from './styles';

const PersonalSafetyRules = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;
    const mounted = React.useRef(false);

    const [deleteModalData, setDeleteModalData] = useState(null);
    const [rules, setRules] = useState({ count: 0, data: [] });
    const [loadingRules, setLoadingRules] = useState(false);
    const [, setRemovingRules] = useState(false);
    const ruleRemovals = React.useRef([]);
    // Org
    const [organization, setOrganization] = useState(ParentOrgId || 0);

    // Custom Hooks
    const [Organizations] = useOrganizations(mounted, dispatch, ParentOrgId);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (organization !== 0) {
            _getRules();
        }
    }, [organization]);

    async function _getRules(query = {}, setLoadingState = true) {
        if (organization && organization !== 'all') {
            query.OrganizationId = organization;
        }

        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingRules(true);
        try {
            const rules = await API.get('ClutchAPI', `/organizations/${organization}/rules?${params}`);
            mounted && setRules(rules);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingRules(false);
    }

    // Handle Functions
    async function handleDeleteRule(rule, index) {
        ruleRemovals.current[rule.id] = true;
        mounted && setRemovingRules(true);
        try {
            await API.del('ClutchAPI', `/organizations/${organization}/rules/${rule.id}`);
            setDeleteModalData(null);
            /** Remove the rule from the state, and requery without loading state */
            const clonedRules = cloneDeep(rules);
            clonedRules.count -= 1;
            clonedRules.data.splice(index, 0);
            setRules(clonedRules);
            /** Requery without loading state */
            await _getRules({}, false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        delete ruleRemovals.current[rule.id];
        mounted && setRemovingRules(false);
    }

    const queue = React.useRef([]);
    const sendingRequest = React.useRef(false);
    const queueHandler = React.useRef();
    async function handleOrderClicked(rule, index, direction) {
        const _rules = cloneDeep(rules);
        let order = rule.order;
        if (direction === 'up') {
            order -= 1;

            _rules.data[index].order -= 1;
            const holder = _rules.data[index - 1];
            holder.order += 1;

            _rules.data[index - 1] = _rules.data[index];
            _rules.data[index] = holder;
        } else if (direction === 'down') {
            order += 1;

            _rules.data[index].order += 1;
            const holder = _rules.data[index + 1];
            holder.order -= 1;

            _rules.data[index + 1] = _rules.data[index];
            _rules.data[index] = holder;
        }

        setRules(_rules);

        queue.current.push({ rule, order });
        if (!queueHandler.current) {
            queueHandler.current = setInterval(async () => {
                if (!sendingRequest.current && queue.current.length > 0) {
                    sendingRequest.current = true;
                    let { rule, order } = queue.current.pop();

                    await API.patch('ClutchAPI', `/organizations/${organization}/rules/${rule.id}`, {
                        body: {
                            order,
                        },
                    }).catch((error) => {
                        dispatch(setSnackbar(checkError(error)));
                    });

                    sendingRequest.current = false;
                }

                if (queue.current.length === 0) {
                    clearInterval(queueHandler.current);
                    queueHandler.current = undefined;
                }
            }, 200);
        }
    }
    function TopNavClick() {
        return props.history.push('/house-rules/new');
    }
    const TopNavButton = () => {
        return (
            <div>
                <Button
                    disableElevation
                    fullWidth
                    classes={{ root: classes.filterButton }}
                    variant="contained"
                    color={'primary'}
                    startIcon={<Add />}
                    onClick={TopNavClick}>
                    Add New House Rule
                </Button>
            </div>
        );
    };

    return (
        <Page
            maxWidth={720}
            topNavigationProps={{
                pageTitle: 'House Rules',
                children: <TopNavButton />,
            }}>
            <BasicDialog
                open={!!deleteModalData}
                title={deleteModalData ? deleteModalData.title : ''}
                subtitle={
                    <span>
                        Are you sure you would like to delete this house rule?
                        <br />
                        This action cannot be undone.
                    </span>
                }
                onClose={() => setDeleteModalData(null)}
                onSubmit={deleteModalData ? deleteModalData.onSubmit : null}
            />
            {!ParentOrgId && (
                <Grid container spacing={2} classes={{ root: classes.gridRoot }}>
                    <Grid item xs={12} lg={4} classes={{ root: classes.filterGrid }}>
                        <Select
                            typography={'Organization'}
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}>
                            <MenuItem value={0}>Select an Organization</MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
            )}
            <List dense classes={{ root: classes.listRoot }}>
                {(loadingRules ? Array.from(new Array(5)) : rules.data).map((rule, index) => {
                    if (!rule) return <Skeleton type="listItem" key={`skele-${index}`} />;
                    return (
                        <ListItem key={`rule-${index}`} classes={{ root: classes.listItem }}>
                            <Order
                                showUp={index !== 0}
                                showDown={index !== rules.data.length - 1}
                                classes={{ order: classes.order }}
                                // disabled={houseRuleOrderUpdating === index ? true : false}
                                handleUpClicked={(e) => handleOrderClicked(rule, index, 'up')}
                                handleDownClicked={(e) => handleOrderClicked(rule, index, 'down')}
                            />
                            <div className={classes.listText}>
                                <Typography variant="subtitle1">HOUSE RULE</Typography>
                                <Typography variant="subtitle2">{rule.title}</Typography>
                            </div>
                            <div className={classes.listActions}>
                                <StyledButton
                                    disableElevation
                                    disabled={!!ruleRemovals.current[rule.id]}
                                    classes={{ root: classes.reviewButton }}
                                    variant="contained"
                                    color={'primary'}
                                    text="Edit"
                                    onClick={() =>
                                        props.history.push(
                                            `/house-rules/${rule.id}?OrganizationId=${rule.OrganizationId}`
                                        )
                                    }
                                />
                                <StyledButton
                                    disableElevation
                                    disabled={!!ruleRemovals.current[rule.id]}
                                    classes={{ root: classes.deleteButton }}
                                    variant="contained"
                                    variantType="containedRed"
                                    color={'primary'}
                                    text={<DeleteForever />}
                                    onClick={() =>
                                        setDeleteModalData({
                                            title: (
                                                <span>
                                                    Delete House Rule
                                                    <br />
                                                    {`"${rule.title}"`}
                                                </span>
                                            ),
                                            onSubmit: () => handleDeleteRule(rule, index),
                                        })
                                    }
                                />
                            </div>
                        </ListItem>
                    );
                })}
            </List>
        </Page>
    );
};

export default withStyles(styles)(PersonalSafetyRules);
