const styles = (theme) => ({
    root: {},
    appBar: {
        flexDirection: 'row',
        height: 111,
        border: '1px solid rgba(151, 151, 151, 0.27)',
        backgroundColor: '#ffffff',
        color: '#454c5d',
        padding: 30,
        boxShadow: 'none',
        alignItems: 'center',
    },
    texts: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        letterSpacing: 0.29,
    },
    helperText: {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0.2,
        color: '#454c5d',
        opacity: 0.5,
        textTransform: 'uppercase',
    },
    btn: {
        maxWidth: 144,
        height: 52,
        backgroundColor: '#454c5d',
        color: '#ffffff',
        borderRadius: 5,
    },
});

export default styles;
