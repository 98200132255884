import { colors } from 'global-styles';

const styles = (theme) => ({
    root: {
        padding: 0,
    },
    content: {
        display: 'flex',
    },
    search: {
        backgroundColor: theme.palette.tuna.main,
        height: '100%',
        flex: '0 1 330px',
        position: 'sticky',
        top: 0,
        padding: 30,
    },
    dropArea: {
        flex: 1,
        padding: 30,
    },
    warning: {
        color: colors.warningRed,
    },
});

export default styles;
