// React
import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import queryString from 'query-string';
import { DragDropContext } from 'react-beautiful-dnd';
import checkError from 'utils/check-error';
import Mixpanel from 'mixpanel-browser';
// Material UI

import { withStyles } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
// Components
import { Dot } from 'react-animated-dots';
import SearchTemplates from './SearchTemplates';
import TemplatesList from './TemplatesList';
import Page from 'components/Page';
// Styles
import styles from './styles';

class EventType extends React.Component {
    constructor(props) {
        super(props);

        this.query = queryString.parse(props.location.search);
        this.eventTypeId = props.match.params.id;
        this.queue = [];
        this.state = {
            eventType: {},
            unrelatedTemplates: [],
            relatedTemplates: [],
            templates: [],
            loading: false,

            queueRunning: false,
        };
    }

    componentDidMount() {
        this._getData();
    }

    async _getData() {
        this.setState({ loading: true });

        try {
            const [eventType, templates, relatedTemplates] = await Promise.all([
                API.get('ClutchAPI', `/organizations/${this.query.OrganizationId}/event-types/${this.eventTypeId}`),
                API.get(
                    'ClutchAPI',
                    `/organizations/${this.query.OrganizationId}/event-module-templates?isLatestVersion=1`
                ),
                API.get(
                    'ClutchAPI',
                    `/organizations/${this.query.OrganizationId}/event-types/${this.eventTypeId}/modules`
                ),
            ]);

            this.setState({
                eventType,
            });

            this._setTemplates(templates, relatedTemplates);
        } catch (error) {
            setSnackbar(checkError(error));
            this.props.history.goBack();
        }
    }

    _setTemplates(templates, relatedTemplates) {
        const unrelatedTemplates = templates.data.filter((template) => {
            for (var i = 0; i < relatedTemplates.length; i++) {
                if (relatedTemplates[i].EventModuleTemplateId === template.versionId) {
                    return false;
                }
            }
            return true;
        });

        this.setState({
            templates,
            relatedTemplates,
            unrelatedTemplates,
            loading: false,
        });
    }

    runQueue = (callback) => {
        const { setSnackbar } = this.props;
        if (!this.queueHandler) {
            this.setState({ queueRunning: 'Updating' });
            this.queueHandler = setInterval(async () => {
                if (!this.sendingRequest && this.queue.length > 0) {
                    this.sendingRequest = true;
                    let { method, path, body = {}, previousState } = this.queue.pop();

                    try {
                        const resp = await API[method]('ClutchAPI', path, { body });
                        Mixpanel.track('Event type updated');
                        typeof callback == 'function' && callback(resp);
                    } catch (error) {
                        this.queue = []; // empty queue
                        setSnackbar(checkError(error)); // Set snackbar
                        this.setState(previousState); // reset state
                        typeof callback == 'function' && callback(null);
                    }
                    this.sendingRequest = false;
                }

                if (this.queue.length === 0 && this.sendingRequest === false) {
                    this.setState({ queueRunning: 'Saved' });
                    setTimeout(() => this.setState({ queueRunning: false }), 2000);

                    clearInterval(this.queueHandler);
                    this.queueHandler = undefined;
                }
            }, 200);
        }
    };

    onDelete = async (EventTypeModuleTemplate) => {
        const { unrelatedTemplates, relatedTemplates, templates } = this.state;

        this.setState(
            {
                relatedTemplates: relatedTemplates.filter(
                    (item) => item.EventModuleTemplateId !== EventTypeModuleTemplate.EventModuleTemplateId
                ),
            },
            () => {
                this._setTemplates(this.state.templates, this.state.relatedTemplates);
            }
        );

        this.queue.push({
            method: 'del',
            path: `/organizations/${this.query.OrganizationId}/event-types/${this.eventTypeId}/modules/${EventTypeModuleTemplate.EventModuleTemplateId}`,
            previousState: { unrelatedTemplates, relatedTemplates, templates },
        });
        this.runQueue();
    };

    onDragEnd = async (result) => {
        const { unrelatedTemplates, relatedTemplates, templates } = this.state;

        const { source, destination } = result;
        if (!destination || destination.droppableId !== 'templates-droppable') return;

        if (source.droppableId === 'event-module-templates' && destination.droppableId === 'templates-droppable') {
            const droppedItem = unrelatedTemplates[source.index];
            const clonedRelations = cloneDeep(relatedTemplates);
            clonedRelations.splice(destination.index, 0, {
                EventModuleTemplate: droppedItem,
                EventModuleTemplateId: droppedItem.versionId,
                loading: true,
            });

            const clonedUnrelatedTemplates = cloneDeep(unrelatedTemplates);
            clonedUnrelatedTemplates.splice(source.index, 1);
            this.setState(
                {
                    unrelatedTemplates: clonedUnrelatedTemplates,
                    relatedTemplates: clonedRelations,
                },
                () => {
                    this.queue.push({
                        method: 'post',
                        path: `/organizations/${this.query.OrganizationId}/event-types/${this.eventTypeId}/modules/${droppedItem.versionId}`,
                        previousState: { unrelatedTemplates, relatedTemplates, templates },
                        body: { order: destination.index + 1 },
                    });

                    this.runQueue((resp) => {
                        const reclonedTemplates = cloneDeep(this.state.relatedTemplates);
                        for (var i = 0; i < reclonedTemplates.length; i++) {
                            if (reclonedTemplates[i].EventModuleTemplateId === resp.EventModuleTemplateId) {
                                reclonedTemplates[i] = resp;
                            }
                        }
                        this.setState({ relatedTemplates: reclonedTemplates });
                    });
                }
            );
        } else if (source.droppableId === 'templates-droppable' && destination.droppableId === 'templates-droppable') {
            if (source.index === destination.index) return;

            const droppedItem = relatedTemplates[source.index];
            // On reorder
            const clonedRelations = cloneDeep(relatedTemplates);
            var holder = clonedRelations.splice(source.index, 1)[0];
            clonedRelations.splice(destination.index, 0, holder);
            this.setState({ relatedTemplates: clonedRelations });

            this.queue.push({
                method: 'patch',
                path: `/organizations/${this.query.OrganizationId}/event-types/${this.eventTypeId}/modules/${droppedItem.EventModuleTemplateId}`,
                previousState: { unrelatedTemplates, relatedTemplates, templates },
                body: { order: destination.index + 1 },
            });
            this.runQueue();
        }
    };

    render() {
        const { classes } = this.props;
        const { relatedTemplates, unrelatedTemplates } = this.state;

        return (
            <Page
                classes={{ root: classes.root }}
                topNavigationProps={{
                    pageTitle: this.state.eventType.name,

                    children:
                        this.state.queueRunning === 'Updating' ? (
                            <span className={classes.blinker}>
                                {this.state.queueRunning}
                                <Dot>.</Dot>
                                <Dot>.</Dot>
                                <Dot>.</Dot>
                            </span>
                        ) : this.state.queueRunning === 'Saved' ? (
                            <span className={classes.saved}>
                                <Check style={{ height: 30, width: 30 }} />
                            </span>
                        ) : null,
                }}>
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                    <div className={classes.content}>
                        <SearchTemplates templates={unrelatedTemplates} />
                        <TemplatesList templates={relatedTemplates} onDelete={this.onDelete.bind(this)} />
                    </div>
                </DragDropContext>
            </Page>
        );
    }
}

export default connect(null, { setSnackbar })(withStyles(styles)(EventType));
