export const eventStatus = {
    Submitted: 'submitted',
    Editing: 'editing',
    Approved: 'approved',
    Rejected: 'rejected',
    Denied: 'denied',
    'Update Required': 'update-required',
};

export const eventStatusDropdownLabels = {
    Submitted: 'Submitted',
    Editing: 'Editing',
    Approved: 'Approved or Reviewed',
    Rejected: 'Rejected',
    Denied: 'Denied',
    'Update Required': 'Update Required',
};

export const eventStatusReadable = {
    submitted: 'Submitted for approval',
    editing: 'Editing',
    approved: 'Approved',
    rejected: 'Rejected',
    denied: 'Denied',
    'update-required': 'Updates have been requested',
};

export const templateTypes = {
    TITLE: 'title',
    HEADING: 'heading',
    BODY: 'bodyText',
    CHECKBOX: 'checkBox',
    FILE_UPLOAD: 'fileUpload',
    FILE_DOWNLOAD: 'fileDownload',
    SHORT_TEXT_INPUT: 'shortTextInput',
    DATE_TIME_INPUT: 'dateTimeInput',
    TEXTAREA_INPUT: 'textAreaInput',
    VOLUNTEER_SELECT: 'volunteer',
    WHITE_SPACE: 'whiteSpace',
    HORIZONTAL_RULER: 'horizontalRuler',
    SELECTS: 'selects',
};

export const markupInputTypes = [
    templateTypes.TEXTAREA_INPUT,
    templateTypes.SHORT_TEXT_INPUT,
    templateTypes.DATE_TIME_INPUT,
    templateTypes.CHECKBOX,
    templateTypes.FILE_UPLOAD,
    templateTypes.VOLUNTEER_SELECT,
];

export const markupsTypesToHide = [
    templateTypes.BODY,
    templateTypes.WHITE_SPACE,
    templateTypes.FILE_DOWNLOAD,
    templateTypes.HORIZONTAL_RULER,
    templateTypes.SELECTS,
    templateTypes.TITLE,
    templateTypes.HEADING,
];

export const stateAbbreviations = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];

export const defaultRoles = ['default-admin', 'default-member', 'default-owner'];

export const OnChangeTimer = 1000;

export const policyIdentifiers = {
    SITE_ADMIN_POLICY: 'site-admin-policy',
    BASIC_POLICIES: 'basic-policies',
    ADMIN_APP_LOGIN: 'admin-app-login',
    MANAGE_ORGANIZATIONS: 'manage-organizations',
    MEMBER_APP_LOGIN: 'member-app-login',
    CHECK_IN_ATTENDEE_PERMISSIONS: 'check-in-attendee-permissions',
    EVENT_ATTENDEE_PERMISSIONS: 'event-attendee-permissions',
    EVENT_CREATOR: 'event-creator',
    REMOVE_EVENTS: 'remove-events',
    VIEW_EVENTS: 'view-events',
    COMMENT_ON_EVENTS: 'comment-on-events',
    EVENT_APPROVAL_REQUIRED: 'event-approval-required',
    FINAL_EVENT_APPROVER: 'final-event-approver',
    MANAGE_EVENT_TYPES: 'manage-event-types',
    MANAGE_EVENT_STEPS: 'manage-event-steps',
    MANAGE_CHAPTERS: 'manage-chapters',
    VIEW_PERSONAL_SAFETY_ALERTS: 'view-personal-safety-alerts',
    VIEW_CHAPTER_SAFETY_ALERTS: 'view-chapter-safety-alerts',
    SEND_CHAPTER_SAFETY_ALERT_ADMIN: 'send-chapter-safety-alert-admin',
    MANAGE_HOUSE_RULES: 'manage-house-rules',
    MANAGE_EDUCATION: 'manage-education',
    VIEW_INCIDENT_REPORTS: 'view-incident-reports',
    MANAGE_SYSTEM_SETUP: 'manage-system-setup',
    MANAGE_ORGANIZATION_USERS: 'manage-organization-users',
    MANAGE_ROLES_AND_PERMISSIONS: 'manage-roles-&-permissions',
    MANAGE_APPROVAL_PROCESS: 'manage-approval-process',
    VIEW_PRE_APPROVAL_EVENTS: 'view-pre-approval-events',
    PERSONAL_SAFETY_ALERTS: 'personal-safety-alerts',
    INCIDENT_REPORTS: 'incident-reports',
    CHAPTER_SAFETY_ALERTS: 'chapter-safety-alerts',
    SEND_CHAPTER_SAFETY_ALERT_MEMBER: 'send-chapter-safety-alert-member',
    HOUSE_RULES: 'house-rules',
    EDUCATION_RESOURCES: 'education-resources',
    VIEW_METRICS: 'view-metrics',
};

export const reviewerPolicies = [
    policyIdentifiers.VIEW_EVENTS,
    policyIdentifiers.COMMENT_ON_EVENTS,
    policyIdentifiers.EVENT_APPROVAL_REQUIRED,
    policyIdentifiers.FINAL_EVENT_APPROVER,
];
