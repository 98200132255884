import React from 'react';
//Modules
import clsx from 'classnames';
import { AllFileTypes } from 'utils/file-types';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    FormControlLabel,
    Typography,
    Checkbox,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
// Components
import Input from 'components/Input';
import FileInput from 'components/Input/FileInput';
// Wysiwyg
import Wysiwyg from 'components/Input/Wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
// Styles
import styles from './styles';

const fakeNames = ['Bethany Atom', 'Lisa Smith', 'Jane Doe', 'Sarah Smith', 'Ava Buck', 'Nora Sora'];

class Volunteer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAttachment: props.emailAttachment || '',
        };

        this.title = props.title || '';
        this.body = props.body
            ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.body)))
            : EditorState.createEmpty();
        this.heading = props.heading || '';
        this.required = props.required || false;
        this.multipleRoles = props.multipleRoles || false;
        this.emailSubject = props.emailSubject || '';
        this.emailBody = props.emailBody
            ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.emailBody)))
            : EditorState.createEmpty();
        this.emailAttachment = props.emailAttachment;
        // So that we don't get this ridiculous warning about changing default checked..
        this.defaultChecked = { required: this.required, multipleRoles: this.multipleRoles };
    }

    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                title: this.title,
                body: typeof this.body === 'string' ? this.body : '', // Body can initially be an object with the editor state this is no bueno
                emailBody: typeof this.emailBody === 'string' ? this.emailBody : '', // Body can initially be an object with the editor state this is no bueno
                heading: this.heading,
                required: this.required,
                multipleRoles: this.multipleRoles,
                emailAttachment: this.emailAttachment,
                emailSubject: this.emailSubject,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Input
                            placeholder="Type Member Select Title Here"
                            variant="standard"
                            fullWidth
                            onChange={(e) => this.onChange('title', e.target.value)}
                            defaultValue={this.title}
                            InputProps={{
                                classes: { input: classes.title },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Wysiwyg
                            ref="body"
                            classes={{ editor: classes.editor }}
                            defaultEditorState={this.body}
                            placeholder="Type Body Text Here"
                            onEditorStateChange={(e) =>
                                this.onChange('body', draftToHtml(convertToRaw(e.getCurrentContent())))
                            }
                            toolbar={{
                                options: ['blockType', 'fontFamily', 'inline', 'list', 'link'], // This is where you can specify what options you need in
                                //the toolbar and appears in the same order as specified
                                fontFamily: {
                                    options: [
                                        'Lato',
                                        'Arial',
                                        'Georgia',
                                        'Impact',
                                        'Tahoma',
                                        'Times New Roman',
                                        'Verdana',
                                    ],
                                },
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Input
                            placeholder="Type Heading Text Here"
                            fullWidth
                            variant="standard"
                            defaultValue={this.heading}
                            onChange={(e) => this.onChange('helperText', e.target.value)}
                            InputProps={{
                                classes: { input: classes.heading },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.checkBoxes}>
                            <FormControlLabel
                                classes={{
                                    label: classes.requiredLabel,
                                    labelPlacementStart: classes.placementStart,
                                }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        defaultChecked={this.defaultChecked.multipleRoles}
                                        onChange={(e) => this.onChange('multipleRoles', e.target.checked)}
                                    />
                                }
                                label={'Allowed Multiple Roles'}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                classes={{
                                    label: classes.requiredLabel,
                                    labelPlacementStart: classes.placementStart,
                                }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        defaultChecked={this.defaultChecked.required}
                                        onChange={(e) => this.onChange('required', e.target.checked)}
                                    />
                                }
                                label={'Required'}
                                labelPlacement="start"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Input
                            fullWidth
                            classes={{ root: classes.search }}
                            placeholder="Search Members"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search className={classes.searchIcon} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className={classes.listRoot}>
                            <List dense classes={{ root: classes.list }}>
                                {fakeNames.map((name, index) => (
                                    <React.Fragment key={`frag-${index}`}>
                                        <ListItem
                                            classes={{
                                                root: clsx({
                                                    [classes.listItem]: true,
                                                    [classes.firstItem]: index === 0,
                                                    [classes.lastItem]: index === fakeNames.length - 1,
                                                }),
                                            }}>
                                            <ListItemText primary={name} />
                                            <ListItemSecondaryAction>
                                                <Checkbox color="primary" />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {index !== fakeNames.length - 1 && (
                                            <Divider classes={{ root: classes.dividerColor }} />
                                        )}
                                    </React.Fragment>
                                ))}
                            </List>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.divider}>
                        <Divider className={clsx(classes.dividerColor, classes.emailSeperator)} />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h5" className={classes.underline}>
                            Volunteer Email Notification Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Input
                            placeholder="Email subject line"
                            fullWidth
                            variant="standard"
                            defaultValue={this.emailSubject}
                            marginBottom={8}
                            onChange={(e) => this.onChange('emailSubject', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Wysiwyg
                            ref="emailBody"
                            classes={{ editor: classes.editor }}
                            defaultEditorState={this.emailBody}
                            placeholder="Type Body Text Here"
                            onEditorStateChange={(e) =>
                                this.onChange('emailBody', draftToHtml(convertToRaw(e.getCurrentContent())))
                            }
                            toolbar={{
                                options: ['blockType', 'fontFamily', 'inline', 'list', 'link'], // This is where you can specify what options you need in
                                //the toolbar and appears in the same order as specified
                                fontFamily: {
                                    options: [
                                        'Lato',
                                        'Arial',
                                        'Georgia',
                                        'Impact',
                                        'Tahoma',
                                        'Times New Roman',
                                        'Verdana',
                                    ],
                                },
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <FileInput
                            accept={[...AllFileTypes]}
                            classes={{ uploadRoot: classes.uploadRoot }}
                            file={this.state.emailAttachment}
                            onChange={(emailAttachment) => {
                                this.setState({ emailAttachment });
                                this.onChange('emailAttachment', emailAttachment);
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Volunteer);
