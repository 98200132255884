import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Description } from '@material-ui/icons';

// Modules
import S3MediaOperations from 'utils/S3MediaOperations';
import isEqual from 'lodash/isEqual';
import { ImageFileTypes, bytesToSize, DocumentFileTypes } from 'utils/file-types';
// Styles
import styles from './styles';

class Media extends React.Component {
    constructor(props) {
        super(props);

        this.inputField = React.createRef();
        this.state = {
            src: '',
        };
    }

    componentDidMount() {
        this.setSource();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.file, this.props.file)) {
            this.setSource();
        }
    }

    async setSource() {
        const { file } = this.props;
        if (typeof file === 'object' && ImageFileTypes.includes(file.type)) {
            if (file.id) {
                if (file.url) this.setState({ src: file.url });
                else this.setState({ src: await S3MediaOperations.getDocumentUrl(file) });
            } else {
                const url = URL.createObjectURL(file);
                this.setState({ src: url });
            }
        } else if (typeof file === 'object' && DocumentFileTypes.includes(file.type)) {
            if (file.id) {
                if (file.url) this.setState({ src: file.url });
                else this.setState({ src: await S3MediaOperations.getDocumentUrl(file) });
            } else {
                const url = URL.createObjectURL(file);
                this.setState({ src: url });
            }
        } else {
            this.setState({ src: '' });
        }
    }

    render() {
        const { classes, marginBottom = 0, file = { name: 'Unknown', size: 0 } } = this.props;

        return ImageFileTypes.includes(file.type) ? (
            <div style={{ marginBottom }}>
                <img src={this.state.src} alt="file" className={classes.image} />
            </div>
        ) : (
            // set defaults for text fields app-wide
            <div className={classes.documentRoot} style={{ marginBottom }}>
                <Description className={classes.document} />
                <div className={classes.information}>
                    <a className={classes.browse} href={this.state.src} target="_blank">
                        {file.name}
                    </a>
                    <span className={classes.subtitle}>{bytesToSize(file.size)}</span>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Media);
