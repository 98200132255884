import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
// Components
import Input from 'components/Input';

// Styles
import styles from './styles';

class ShortTextInput extends React.Component {
    constructor(props) {
        super(props);

        this.heading = props.heading || '';
    }

    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                heading: this.heading,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Input
                    placeholder="Type Heading"
                    variant="standard"
                    fullWidth
                    onChange={(e) => this.onChange('heading', e.target.value)}
                    defaultValue={this.heading}
                    InputProps={{
                        classes: { input: classes.input },
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(ShortTextInput);
