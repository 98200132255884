const init = {
    firstName: { value: '', valid: true },
    lastName: { value: '', valid: true },
    email: { value: { value: '' }, valid: true },
    phone: { value: '', valid: true },
    office: { value: '', valid: true },
    address: { value: '', valid: true },
    city: { value: '', valid: true },
    state: { value: '', valid: true },
    zip: { value: '', valid: true },
};

export default init;
