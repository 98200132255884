const styles = (theme) => ({
    actions: {
        marginBottom: 12,
        display: 'flex',
        justifyContent: 'space-between',
    },
    tab: {
        marginTop: 20,
    },
    appbar: {
        backgroundColor: 'white',
        borderRadius: 6,
    },
});

export default styles;
