import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import checkError from 'utils/check-error';
import classNames from 'classnames';
import Mixpanel from 'mixpanel-browser';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, MenuItem, List, ListItem, Collapse, IconButton } from '@material-ui/core';
import { Add, DeleteForever, ExpandMore, ExpandLess } from '@material-ui/icons';
// Components
import StyledButton from 'components/Button';
import Order from 'components/Order';
import Skeleton from 'components/Skeleton';
import Page from 'components/Page';
import Select from 'components/Input/Select';
import BasicDialog from 'components/Dialogs/BasicDialog';
// styles
import styles from './styles';
// Hooks
import useQueue from './useQueue';
import useCategories from 'hooks/useCategories';
import useOrganizations from 'hooks/useOrganizations';

const Resources = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;

    const [deleteModalData, setDeleteModalData] = useState(null);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [open, setOpen] = useState({});
    const [, setRemovingCategories] = useState(false);
    const [, setRemovingEducations] = useState(false);
    const categoryRemovals = React.useRef([]);
    const educationRemovals = React.useRef([]);
    // Org
    const [organization, setOrganization] = useState(ParentOrgId || '0');

    const mounted = React.useRef(false);
    // Custom hooks
    const [categories, setCategories, _getCategories] = useCategories(organization, mounted, setLoadingCategories);
    const { handleCategoryOrderClicked, handleEducationOrderClicked } = useQueue(
        organization,
        categories,
        setCategories
    );
    const [Organizations] = useOrganizations(mounted, dispatch, ParentOrgId);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    async function handleDeleteCategory(category, index) {
        categoryRemovals.current[category.id] = true;
        mounted && setRemovingCategories(true);
        try {
            await API.del('ClutchAPI', `/organizations/${organization}/categories/${category.id}`);
            Mixpanel.track('Category removed');
            setDeleteModalData(null);
            /** Remove the category from the state, and requery without loading state */
            const clonedCategories = cloneDeep(categories);
            clonedCategories.count -= 1;
            clonedCategories.data.splice(index, 0);
            setCategories(clonedCategories);
            /** Requery without loading state */
            await _getCategories({}, false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        delete categoryRemovals.current[category.id];
        mounted && setRemovingCategories(false);
    }

    async function handleDeleteEducation(education, categoryIdx, educationIdx) {
        educationRemovals.current[education.id] = true;
        mounted && setRemovingEducations(true);
        try {
            await API.del('ClutchAPI', `/organizations/${organization}/education/${education.id}`);
            Mixpanel.track('Education removed');
            setDeleteModalData(null);
            /** Remove the education from the state, and requery without loading state */
            const clonedCategories = cloneDeep(categories);
            clonedCategories.data[categoryIdx].Education.splice(educationIdx, 0);
            setCategories(clonedCategories);
            /** Requery without loading state */
            await _getCategories({}, false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        delete educationRemovals.current[education.id];
        mounted && setRemovingCategories(false);
    }
    const handleTopNavButtonClick = () => {
        return props.history.push(`/categories/new`);
    };
    const TopNavButton = () => {
        return (
            <div>
                <Button
                    disableElevation
                    fullWidth
                    classes={{ root: classes.filterButton }}
                    variant="contained"
                    color={'primary'}
                    startIcon={<Add />}
                    onClick={handleTopNavButtonClick}>
                    Add Category
                </Button>
            </div>
        );
    };

    return (
        <Page
            maxWidth={720}
            topNavigationProps={{
                pageTitle: 'Education',
                children: <TopNavButton />,
            }}>
            <BasicDialog
                open={!!deleteModalData}
                title={deleteModalData ? deleteModalData.title : ''}
                subtitle={
                    <span>
                        Are you sure you would like to remove this Category?
                        <br />
                        This will also remove all Resources in the Category as well.
                    </span>
                }
                onClose={() => setDeleteModalData(null)}
                onSubmit={deleteModalData ? deleteModalData.onSubmit : null}
            />
            {!ParentOrgId && (
                <Grid container spacing={2} classes={{ root: classes.gridRoot }}>
                    <Grid item xs={12} lg={12} classes={{ root: classes.filterGrid }}>
                        <Select
                            typography={'Organization'}
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}>
                            <MenuItem value={'0'}>Select an Organization</MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
            )}
            <div className={classes.categories}>
                <div className={classes.fullWidth}>
                    <Button
                        disableElevation
                        fullWidth
                        classes={{ root: classes.pageButton }}
                        variant="contained"
                        color={'primary'}
                        startIcon={<Add />}
                        onClick={() => props.history.push(`/page/new`)}>
                        Add Page
                    </Button>
                </div>
                {(loadingCategories ? Array.from(new Array(5)) : categories.data).map((category, categoryIdx) => {
                    if (!category) return <Skeleton type="listItem" key={`skele-${categoryIdx}`} />;

                    return (
                        <List key={categoryIdx}>
                            <ListItem
                                key={`rule-${categoryIdx}`}
                                classes={{ root: classes.listItem }}
                                style={{
                                    borderRadius: open[categoryIdx] ? '6px 6px 0 0' : 6,
                                }}>
                                <Order
                                    showUp={categoryIdx !== 0}
                                    showDown={categoryIdx !== categories.data.length - 1}
                                    classes={{ order: classes.order }}
                                    handleUpClicked={(e) => handleCategoryOrderClicked(category, categoryIdx, 'up')}
                                    handleDownClicked={(e) => handleCategoryOrderClicked(category, categoryIdx, 'down')}
                                />
                                <div className={classes.listText}>
                                    <Typography variant="subtitle1">CATEGORY</Typography>
                                    <Typography variant="subtitle2">{category.name}</Typography>
                                </div>
                                <div className={classes.listActions}>
                                    <StyledButton
                                        disableElevation
                                        disabled={!!categoryRemovals.current[category.id]}
                                        classes={{ root: classes.reviewButton }}
                                        variant="contained"
                                        color={'primary'}
                                        text="Edit"
                                        onClick={() =>
                                            props.history.push(
                                                `/categories/${category.id}?OrganizationId=${organization}`
                                            )
                                        }
                                    />
                                    <StyledButton
                                        disableElevation
                                        disabled={!!categoryRemovals.current[category.id]}
                                        classes={{ root: classes.deleteButton }}
                                        variant="contained"
                                        variantType="containedRed"
                                        color={'primary'}
                                        text={<DeleteForever />}
                                        onClick={() =>
                                            setDeleteModalData({
                                                title: (
                                                    <span>
                                                        Delete Category
                                                        <br />
                                                        {`"${category.name}"`}
                                                    </span>
                                                ),
                                                onSubmit: () => handleDeleteCategory(category, categoryIdx),
                                            })
                                        }
                                    />
                                    {
                                        <IconButton
                                            onClick={() =>
                                                setOpen((prevState) => ({
                                                    ...prevState,
                                                    [categoryIdx]: !prevState[categoryIdx],
                                                }))
                                            }
                                            style={{
                                                visibility: category.Education.length ? 'visible' : 'hidden',
                                            }}>
                                            {open[categoryIdx] ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    }
                                </div>
                            </ListItem>
                            <Collapse in={open[categoryIdx]}>
                                <List disablePadding>
                                    {category.Education.map((education, educationIdx) => (
                                        <ListItem
                                            key={`rule-${educationIdx}`}
                                            classes={{
                                                root: classes.listItem,
                                            }}
                                            className={classNames({
                                                [classes.collapseItem]: true,
                                                [classes.lastEducation]: educationIdx === category.Education.length - 1,
                                            })}>
                                            <Order
                                                showUp={educationIdx !== 0}
                                                showDown={educationIdx !== category.Education.length - 1}
                                                classes={{
                                                    order: classes.order,
                                                }}
                                                // disabled={houseRuleOrderUpdating === educationIdx ? true : false}
                                                handleUpClicked={(e) =>
                                                    handleEducationOrderClicked(
                                                        education,
                                                        categoryIdx,
                                                        educationIdx,
                                                        'up'
                                                    )
                                                }
                                                handleDownClicked={(e) =>
                                                    handleEducationOrderClicked(
                                                        education,
                                                        categoryIdx,
                                                        educationIdx,
                                                        'down'
                                                    )
                                                }
                                            />
                                            <div className={classes.listText}>
                                                <Typography variant="subtitle1">PAGE</Typography>
                                                <Typography variant="subtitle2">{education.title}</Typography>
                                            </div>
                                            <div className={classes.listActions}>
                                                <StyledButton
                                                    disableElevation
                                                    disabled={!!categoryRemovals.current[education.id]}
                                                    classes={{
                                                        root: classes.reviewButton,
                                                    }}
                                                    variant="contained"
                                                    color={'primary'}
                                                    text="Edit"
                                                    onClick={() =>
                                                        props.history.push(
                                                            `/page/${education.id}?OrganizationId=${organization}`
                                                        )
                                                    }
                                                />
                                                <StyledButton
                                                    disableElevation
                                                    disabled={!!educationRemovals.current[education.id]}
                                                    classes={{
                                                        root: classes.deleteButton,
                                                    }}
                                                    variant="contained"
                                                    variantType="containedRed"
                                                    color={'primary'}
                                                    text={<DeleteForever />}
                                                    onClick={() =>
                                                        setDeleteModalData({
                                                            title: (
                                                                <span>
                                                                    Delete Resource
                                                                    <br />
                                                                    {`"${education.title}"`}
                                                                </span>
                                                            ),
                                                            onSubmit: () =>
                                                                handleDeleteEducation(
                                                                    education,
                                                                    categoryIdx,
                                                                    educationIdx
                                                                ),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </List>
                    );
                })}
            </div>
        </Page>
    );
};

export default withStyles(styles)(Resources);
