import React from 'react';

// Modules
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// Styles
import styles from '../styles';

const DeleteButton = ({ variantType = 'containedRed', classes, ...props }) => {
    let MaterialButtonClasses = classNames({
        [classes.iconButtonRoot]: true,
        [classes[variantType]]: variantType,
    });

    return (
        <IconButton classes={{ root: MaterialButtonClasses }} tooltip="Delete" {...props}>
            <DeleteIcon />
        </IconButton>
    );
};

export default withStyles(styles)(DeleteButton);
