import { markupInputTypes } from 'global-constants';

function reviewState(EventModules, currentUser) {
    const reviewState = [];
    let userSectionsApproved = 0;
    let numberOfReviewableSections = 0;

    EventModules.forEach((eModule) => {
        reviewState.push({
            id: eModule.id,
            name: eModule.name,
            sections: {},
        });

        const index = reviewState.length - 1;

        eModule.sections.forEach((section) => {
            reviewState[index].sections[section.id] = {
                id: section.id,
                values: section.values || {},
                review: section.review,
                markup: [],
            };

            if (
                section.review &&
                section.review.approvals[currentUser.id] &&
                section.review.approvals[currentUser.id].approved
            ) {
                userSectionsApproved += 1;
            }

            section.markup.forEach((markup) => {
                if (!markupInputTypes.includes(markup.type)) return null;

                reviewState[index].sections[section.id].markup.push(markup);
            });

            if (reviewState[index].sections[section.id].markup.length === 0) {
                delete reviewState[index].sections[section.id];
            }
        });

        const reviewableSections = Object.keys(reviewState[index].sections).length;
        if (reviewableSections === 0) {
            reviewState.splice(index, 1);
        } else {
            numberOfReviewableSections += reviewableSections;
        }
    });

    return { reviewState, approvedAll: numberOfReviewableSections === userSectionsApproved };
}

export default reviewState;
