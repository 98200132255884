import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// styles
import styles from './styles';

const LoginHeader = ({ classes, title, subTitle, loading, logo, onClick }) => {
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {loading ? (
                    <Skeleton variant="rect" height={20} width={200} />
                ) : (
                    <>
                        {logo && <img onClick={onClick} className={classes.logo} src={logo} alt={''} />}
                        <div className={classes.title}>{title}</div>
                        {subTitle && (
                            <Typography align="center" className={classes.subHeader}>
                                {subTitle}
                            </Typography>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default withStyles(styles)(LoginHeader);
