export const GET_CHAPTER_SAFETY_ALERT_SUCCESS = 'GET_CHAPTER_SAFETY_ALERT_SUCCESS';
export const GET_CHAPTER_SAFETY_ALERT_ERROR = 'GET_CHAPTER_SAFETY_ALERT_ERROR';
export const DELETE_CHAPTER_SAFETY_ALERT_SUCCESS = 'DELETE_CHAPTER_SAFETY_ALERT_SUCCESS';
export const DELETE_CHAPTER_SAFETY_ALERT_ERROR = 'DELETE_CHAPTER_SAFETY_ALERT_ERROR';
export const UPDATE_CHAPTER_SAFETY_ALERT_SUCCESS = 'UPDATE_CHAPTER_SAFETY_ALERT_SUCCESS';
export const SEND_CHAPTER_SAFETY_ALERT_MESSAGE_SUCCESS = 'SEND_CHAPTER_SAFETY_ALERT_MESSAGE_SUCCESS';
export const SEND_CHAPTER_SAFETY_ALERT_MESSAGE_ERROR = 'SEND_CHAPTER_SAFETY_ALERT_MESSAGE_ERROR';

export const INIT = {
    Organization: '',
    Creator: '',
    AlertResponses: [],
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_CHAPTER_SAFETY_ALERT_SUCCESS:
        case UPDATE_CHAPTER_SAFETY_ALERT_SUCCESS:
            return payload;
        case GET_CHAPTER_SAFETY_ALERT_ERROR:
        case SEND_CHAPTER_SAFETY_ALERT_MESSAGE_SUCCESS:
        case SEND_CHAPTER_SAFETY_ALERT_MESSAGE_ERROR:
            return state;
        default:
            return state;
    }
}
