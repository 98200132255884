// import { InputRed, Red, IconGray } from 'global-styles';

const styles = (theme) => ({
    documentRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    document: {
        color: '#4f5668',
        fontSize: 58,
    },
    information: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 12,
        overflowWrap: 'anywhere',
    },
    browse: {
        color: '#2f89eb',
        fontWeight: 700,
        textDecoration: 'underline',
    },
    title: {
        color: '#4a4a4a',
        fontSize: 16,
    },
    subtitle: {
        marginTop: 8,
        color: '#4a4a4a',
        opacity: 0.5,
        fontSize: 14,
    },
    uppercase: {
        textTransform: 'uppercase',
    },

    errorMessage: {
        color: theme.palette.error.main,
        marginLeft: 14,
        marginRight: 14,
        marginTop: 3,
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
        fontFamil: '"Lato", "Roboto", "sans serif"',
    },
    error: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
});

export default styles;
