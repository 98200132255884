import jsPDF from 'jspdf';
import { formatDate, titleCase } from 'utils/string-utils';

const ExportPDF = (eventGuests, eventDetails) => {
    console.log(eventDetails);
    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' });
    let fontSize = 12;
    let fontFamily = 'helvetica';

    doc.setFont(fontFamily);

    doc.setProperties({
        title: eventDetails.title,
        author: 'Clutch',
        keywords: 'generated, javascript, web 2.0, ajax',
        creator: 'Clutch',
    });
    // Event Name
    doc.setFontType('bold').setFontSize(fontSize).text('Event Name:', 10, 10);
    doc.setFontStyle('normal').setFontSize(fontSize).text(eventDetails.title, 40, 10);

    // Event Date
    doc.setFontType('bold').setFontSize(fontSize).text('Event Date:', 10, 18);
    doc.setFontStyle('normal').setFontSize(fontSize).text(formatDate(eventDetails.startDate, 'DD/MM/YYYY'), 40, 18);

    // Start Time
    doc.setFontType('bold').setFontSize(fontSize).text('Start Time:', 10, 26);
    doc.setFontStyle('normal').setFontSize(fontSize).text(formatDate(eventDetails.startDate, 'h:mm A'), 40, 26);

    // End Time
    doc.setFontType('bold').setFontSize(fontSize).text('End Time:', 10, 34);
    doc.setFontStyle('normal').setFontSize(fontSize).text(formatDate(eventDetails.endDate, 'h:mm A'), 40, 34);

    var table = [];
    eventGuests.forEach((attendee) => {
        var attendeeTableRow = {};
        attendeeTableRow.name = attendee.User.firstName + ' ' + attendee.User.lastName;
        attendeeTableRow.invitedBy = 'self';
        attendeeTableRow.dob =
            attendee.User.birthDate === null ? 'N/A' : formatDate(attendee.User.birthDate, 'MM/DD/YYYY');
        attendeeTableRow.rsvp = attendee.rsvp === 'invited' ? 'Pending' : titleCase(attendee.rsvp);
        attendeeTableRow.checkinDate = attendee.checkinDate !== null ? formatDate(attendee.checkinDate, 'h:mm A') : '';
        attendeeTableRow.checkoutDate =
            attendee.checkoutDate !== null ? formatDate(attendee.checkoutDate, 'h:mm A') : '';
        table.push(Object.assign({}, attendeeTableRow));

        attendee.guests.forEach((guest, index) => {
            var guestTableRow = {};
            guestTableRow.name = guest.name;
            guestTableRow.invitedBy = attendee.User.firstName + ' ' + attendee.User.lastName;
            guestTableRow.dob = guest.birthdate !== null ? formatDate(guest.User.birthDate, 'MM/DD/YYYY') : 'N/A';
            guestTableRow.rsvp = guest.rsvp === 'invited' ? 'Pending' : titleCase(attendee.rsvp);
            guestTableRow.checkinDate = guest.checkinDate !== null ? formatDate(guest.checkinDate, 'h:mm A') : '';
            guestTableRow.checkoutDate = guest.checkoutDate !== null ? formatDate(guest.checkoutDate, 'h:mm A') : '';
            table.push(Object.assign({}, guestTableRow));
        });
    });
    // Adding ID to entire datatable Array
    table.forEach((item, index) => {
        item.id = index + 1;
    });

    function createHeaders(keys) {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
            result.push({
                id: keys[i].id,
                name: keys[i].id,
                prompt: keys[i].prompt,
                width: 53,
                padding: 0,
            });
        }
        return result;
    }
    var headers = createHeaders([
        { id: 'id', prompt: '#' },
        { id: 'name', prompt: 'Name' },
        { id: 'invitedBy', prompt: 'Guest of' },
        { id: 'dob', prompt: 'DOB' },
        { id: 'rsvp', prompt: 'RSVP' },
        { id: 'checkinDate', prompt: 'Checkin Date' },
        { id: 'checkoutDate', prompt: 'Checkout Date' },
    ]);
    doc.table(10, 40, table, headers);
    doc.output('dataurlnewwindow');
    doc.save('Guest-List.pdf');
};

export default ExportPDF;
