const ITEM_TYPE_WIDTH = 130;
const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    margin: {
        margin: '10px 0px',
    },
    itemTypes: {
        backgroundColor: theme.palette.tuna.main,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 30,
        flex: '0 1 330px',
        minWidth: '330px',
        height: '100%',
        position: 'sticky',
        top: 0,
    },
    itemType: {
        alignItems: 'center',
        alignSelf: 'start',
        backgroundColor: theme.palette.shark.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 6,
        borderStyle: 'solid',
        borderWidth: 1,
        boxSizing: 'border-box',
        display: 'flex',
        flex: `0 1 calc(50% - 5px)`,
        flexDirection: 'column',
        justifyContent: 'center',
        // padding: 24,
        marginBottom: 10,
        height: ITEM_TYPE_WIDTH,
        '& img': {
            maxWidth: '75%',
        },
    },
    itemTypeText: {
        color: theme.palette.shark.contrastText,
        marginTop: 10,
        fontWeight: 700,
    },
    sections: {
        flexGrow: 1,
        padding: 30,
    },
    addSection: {
        marginTop: 20,
        height: 132,
    },
});

export default styles;
