import React from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';
import { cloneDeep } from 'lodash';

const useQueue = (organization, categories, setCategories) => {
    const dispatch = useDispatch();

    const queue = React.useRef([]);
    const sendingRequest = React.useRef(false);
    const queueHandler = React.useRef();

    function handleCategoryOrderClicked(category, index, direction) {
        const _categories = cloneDeep(categories);
        let order = category.order;
        if (direction === 'up') {
            order -= 1;

            _categories.data[index].order -= 1;
            const holder = _categories.data[index - 1];
            holder.order += 1;

            _categories.data[index - 1] = _categories.data[index];
            _categories.data[index] = holder;
        } else if (direction === 'down') {
            order += 1;

            _categories.data[index].order += 1;
            const holder = _categories.data[index + 1];
            holder.order -= 1;

            _categories.data[index + 1] = _categories.data[index];
            _categories.data[index] = holder;
        }

        setCategories(_categories);

        queue.current.push({ category, order });
    }

    function handleEducationOrderClicked(education, categoryIdx, educationIdx, direction) {
        const _categories = cloneDeep(categories);
        let order = education.order;
        if (direction === 'up') {
            order -= 1;

            _categories.data[categoryIdx].Education[educationIdx].order -= 1;
            const holder = _categories.data[categoryIdx].Education[educationIdx - 1];
            holder.order += 1;

            _categories.data[categoryIdx].Education[educationIdx - 1] =
                _categories.data[categoryIdx].Education[educationIdx];
            _categories.data[categoryIdx].Education[educationIdx] = holder;
        } else if (direction === 'down') {
            order += 1;

            _categories.data[categoryIdx].Education[educationIdx].order += 1;
            const holder = _categories.data[categoryIdx].Education[educationIdx + 1];
            holder.order -= 1;

            _categories.data[categoryIdx].Education[educationIdx + 1] =
                _categories.data[categoryIdx].Education[educationIdx];
            _categories.data[categoryIdx].Education[educationIdx] = holder;
        }

        setCategories(_categories);

        queue.current.push({ education, order });
    }

    if (!queueHandler.current) {
        queueHandler.current = setInterval(async () => {
            if (!sendingRequest.current && queue.current.length > 0) {
                sendingRequest.current = true;
                let { category, education, order } = queue.current.pop();

                if (category) {
                    await API.patch('ClutchAPI', `/organizations/${organization}/categories/${category.id}`, {
                        body: {
                            order,
                        },
                    }).catch((error) => {
                        dispatch(setSnackbar(checkError(error)));
                    });
                } else if (education) {
                    await API.patch(
                        'ClutchAPI',
                        `/organizations/${organization}/education/${education.id}?CategoryId=${education.CategoryId}`,
                        {
                            body: {
                                order,
                            },
                        }
                    ).catch((error) => {
                        dispatch(setSnackbar(checkError(error)));
                    });
                }

                sendingRequest.current = false;
            }

            if (queue.current.length === 0) {
                clearInterval(queueHandler.current);
                queueHandler.current = undefined;
            }
        }, 200);
    }

    return { handleCategoryOrderClicked, handleEducationOrderClicked };
};

export default useQueue;
