import React, { useEffect, useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';
import dayjs from 'dayjs';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
// Components
import Page from 'components/Page';
import Button from 'components/Button';
// styles
import styles from './styles';

const EventLog = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const [logs, setLogs] = useState({ data: [], count: 0 });

    useEffect(() => {
        async function _getLogs() {
            try {
                const response = await API.get(
                    'ClutchAPI',
                    `/events/${props.match.params.id}/logs${props.location.search}&perPage=200&page=1`
                );
                Mixpanel.track('Event log viewed');
                setLogs(response);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        _getLogs();
    }, []);

    const logByDate = {};
    logs.data.forEach((log) => {
        const dateKey = dayjs(log.createdAt).format('MMMM DD, YYYY');
        if (!logByDate[dateKey]) logByDate[dateKey] = [];

        logByDate[dateKey].push(log);
    });

    return (
        <Page
            maxWidth={900}
            topNavigationProps={{
                pageTitle: 'Event Log',
            }}>
            <Button
                fullWidth={false}
                classes={{ root: classes.back }}
                text="Return to Event Review"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => props.history.goBack()}
                startIcon={<ChevronLeft />}
            />
            <Paper className={classes.paper}>
                {Object.keys(logByDate).map((key) => {
                    const logs = logByDate[key];

                    return (
                        <div className={classes.dateColumn} key={key}>
                            <div className={classes.date}>
                                <Typography className={classes.dateText}>{key}</Typography>
                                <div className={classes.hr} />
                            </div>
                            {logs.map((log) => {
                                return (
                                    <div className={classes.log} key={`log-${log.id}`}>
                                        <Typography className={classes.timeText}>
                                            {dayjs(log.createdAt).format('h:mm a')}
                                        </Typography>
                                        <div className={classes.logInfo}>
                                            <Typography className={classes.logTitle} variant="h5">
                                                {log.title}
                                            </Typography>
                                            <Typography className={classes.logBody}>{log.information}</Typography>
                                            <Typography className={classes.timeText}>
                                                by {`${log.User.firstName} ${log.User.lastName}`}
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </Paper>
        </Page>
    );
};

export default withStyles(styles)(EventLog);
