import React from 'react';
import { withRouter } from 'react-router-dom';

// Modules
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import { API } from 'aws-amplify';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, MenuItem } from '@material-ui/core';
// Components
import Select from 'components/Input/Select';
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';

// Styles
import styles from './styles';

class SelectModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: { data: [], count: 0 },
            selections: props.selections || [0],
            templateFastAccess: {},
        };

        this.query = queryString.parse(this.props.history.location.search);
        this._getTemplates();
    }

    async _getTemplates() {
        try {
            const templates = await API.get(
                'ClutchAPI',
                `/organizations/${this.query.OrganizationId}/event-module-templates`
            );
            const templateFastAccess = {};
            templates.data.forEach((template) => (templateFastAccess[template.versionId] = template));
            this.setState({ templates, templateFastAccess });
        } catch (error) {
            console.log(error);
        }
    }

    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                heading: this.heading,
            });
    }

    handleAddSelection() {
        this.setState({
            selections: [...this.state.selections, { value: 0, active: true }],
        });
    }

    handleRemoveSelection(index) {
        const selections = cloneDeep(this.state.selections);
        selections.splice(index, 1);
        this.setState({
            selections,
        });
    }

    async handleChange(index, e) {
        const selections = cloneDeep(this.state.selections);
        selections[index].moduleVersionId = e.target.value;
        this.setState({ selections });

        if (typeof this.props.onChange === 'function') this.props.onChange({ selections });
    }

    async handleSpecializedChange(index, e) {
        const selections = cloneDeep(this.state.selections);
        selections[index].active = e.target.checked;
        this.setState({ selections });

        if (typeof this.props.onChange === 'function') this.props.onChange({ selections });
    }

    render() {
        const { classes } = this.props;
        const { templates, templateFastAccess } = this.state;

        return (
            <div className={classes.root}>
                {this.props.specialized && (
                    <Typography variant="h3" className={classes.specialTitle}>
                        Check transportation options to make active below
                    </Typography>
                )}
                {this.state.selections.map((select, index) =>
                    this.props.specialized ? (
                        <Checkbox
                            key={`checkbox-${index}`}
                            marginBottom={10}
                            classes={{ root: classes.checkbox }}
                            label={
                                templateFastAccess[select.moduleVersionId]
                                    ? templateFastAccess[select.moduleVersionId].name
                                    : ''
                            }
                            checked={select.active}
                            onChange={this.handleSpecializedChange.bind(this, index)}
                        />
                    ) : (
                        <React.Fragment key={`select-${index}`}>
                            <Select
                                classes={{ root: classes.selectHeight }}
                                value={select.moduleVersionId}
                                onChange={this.handleChange.bind(this, index)}>
                                <MenuItem key={`none-selected`} value={0}>
                                    Select Template
                                </MenuItem>
                                {templates.data.map((template) => {
                                    return (
                                        <MenuItem key={`template-${template.id}-${index}`} value={template.id}>
                                            {template.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <Button text="Remove" onClick={this.handleRemoveSelection.bind(this, index)} />
                        </React.Fragment>
                    )
                )}
                {!this.props.specialized && (
                    <Button text="Add Selection" onClick={this.handleAddSelection.bind(this)} />
                )}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(SelectModule));
