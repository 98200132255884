// React
import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import Upload from 'utils/Upload';
import queryString from 'query-string';
import Mixpanel from 'mixpanel-browser';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Custom Components
import Skeleton from 'components/Skeleton';
import EventStepEditor from './EventStepEditor';
import Page from 'components/Page';
import Button from 'components/Button';
import { Add } from '@material-ui/icons';
import styles from './styles';

class EventStep extends React.Component {
    constructor(props) {
        super(props);
        const state = { submitting: false, loading: false, eventModuleTemplate: {} };

        this.query = queryString.parse(props.history.location.search);
        if (!this.query.OrganizationId) {
            props.history.push('/');
            props.setSnackbar('Permission denied (OrganizationId needed in query params)');
        }
        if (props.match.params.id !== 'new') {
            this._getEventStep();
            state.loading = true;
        }

        this.state = state;
    }

    async _getEventStep() {
        const { setSnackbar } = this.props;

        try {
            const response = await API.get(
                'ClutchAPI',
                `/organizations/${this.query.OrganizationId}/event-module-templates/${this.props.match.params.id}`
            );
            this.setState({
                loading: false,
                eventModuleTemplate: response,
            });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    handleSubmit = async () => {
        const { setSnackbar } = this.props;

        if (this.refs.editor.state.name === '') return setSnackbar('Module name cannot be empty');

        const sections = cloneDeep(this.refs.editor.state.sections);
        const formData = new FormData();
        sections.forEach((section) => {
            section.markup.forEach((markup) => {
                if (markup.type === 'fileDownload' && typeof markup.file === 'object' && !markup.file.id) {
                    formData.append(`${markup.id}`, markup.file);
                } else if (
                    markup.type === 'volunteer' &&
                    typeof markup.emailAttachment === 'object' &&
                    !markup.emailAttachment.id
                ) {
                    formData.append(`${markup.id}`, markup.emailAttachment);
                }
            });
        });
        formData.append('sections', JSON.stringify(sections));
        formData.append('name', this.refs.editor.state.name);

        this.setState({ submitting: true });
        try {
            if (this.state.eventModuleTemplate.id) {
                await Upload.patch(
                    `/organizations/${this.query.OrganizationId}/event-module-templates/${this.props.match.params.id}`,
                    formData
                );
                Mixpanel.track('Event step updated');
                setSnackbar('Event Type Module Template Saved');
            } else {
                await Upload.post(`/organizations/${this.query.OrganizationId}/event-module-templates`, formData);
                setSnackbar('Success! Event Step Module Template Created');
                this.refs.editor.reset();
            }
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.setState({ submitting: false });
    };

    TopNavButton = () => {
        return (
            <div>
                <Button
                    onClick={this.handleSubmit.bind(this)}
                    color="primary"
                    startIcon={<Add />}
                    variant="contained"
                    text={this.state.eventModuleTemplate.id ? 'Save Step' : 'Add Step'}
                    loading={this.state.submitting}
                />
            </div>
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <Page
                classes={{ root: classes.root }}
                topNavigationProps={{
                    pageTitle: 'Event Step',
                    children: this.TopNavButton(),
                }}>
                {this.state.loading ? (
                    <Skeleton type="eventCreator" />
                ) : (
                    <EventStepEditor
                        ref="editor"
                        defaultName={this.state.eventModuleTemplate.name}
                        defaultSections={this.state.eventModuleTemplate.sections}
                        specialized={this.state.eventModuleTemplate.specialized}
                        setSnackbar={this.props.setSnackbar}
                    />
                )}
            </Page>
        );
    }
}

export default connect(null, { setSnackbar })(withStyles(styles)(EventStep));
