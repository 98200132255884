const styles = (theme) => ({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'none',
        paddingBottom: '2rem',
    },
    header: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
        letterSpacing: '  0.29px',
    },
    subHeader: {
        opacity: 0.5,
        color: '#ffffff',
        marginTop: 9,
        lineHeight: '19px',
    },
    logo: {
        width: 147,
        marginTop: 60,
    },
    title: {
        marginTop: 80,
    },
});

export default styles;
