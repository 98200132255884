const styles = (theme) => ({
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    paper: {
        padding: 20,
        flex: 1,
    },
});

export default styles;
