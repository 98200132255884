import React from 'react';

import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules

import Mixpanel from 'mixpanel-browser';
import moment from 'moment';
import checkError from 'utils/check-error';
import queryString from 'query-string';
import { API } from 'aws-amplify';
import Upload from 'utils/Upload';
import { eventStatusReadable } from 'global-constants';
import EventToReviewState from './review-state';
import ExportPDF from 'utils/export-pdf';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Check, Close, ChevronRight } from '@material-ui/icons';
import { Typography, Grid, MenuItem } from '@material-ui/core';
// Components
import Page from 'components/Page';
import Select from 'components/Input/Select';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import ReviewSection from './ReviewSection';

import ChangeEventDateDialog from 'components/Dialogs/ChangeEventDateDialog';
import RoundedSection from 'components/RoundedSection';
import RoundedSectionLabel from 'components/RoundedSection/RoundedSectionLabel';
import RoundedSectionBody from 'components/RoundedSection/RoundedSectionBody';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

class EventReview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            users: { data: [], count: 0 },
            event: { EventModules: [] },
            reviewState: {},
            approvalStep: { role: {} },
            submitting: false,
            updatingOwner: false,
            approvedAll: false,
            changeEventDialogOpen: false,
            obtainingAttendees: false,
        };

        this.query = queryString.parse(this.props.history.location.search);
        this.queue = [];
        this._getEvent();
    }

    async _getEvent() {
        const { setSnackbar, cognitoUser } = this.props;

        try {
            const [event, users] = await Promise.all([
                API.get(
                    'ClutchAPI',
                    `/events/${this.props.match.params.id}?OrganizationId=${this.query.OrganizationId}&ParentId=${this.query.ParentId}`
                ),
                API.get(
                    'ClutchAPI',
                    `/organizations/${this.query.ParentId}/users?policies=event-creator&OrganizationId=${this.query.OrganizationId}`
                ),
            ]);

            let approvalStep = { role: {} };

            if (event.reviewStatus === 'submitted' || event.reviewStatus === 'update-required') {
                approvalStep = await API.get(
                    'ClutchAPI',
                    `/events/${this.props.match.params.id}/approval-step?OrganizationId=${this.query.OrganizationId}&ParentId=${this.query.ParentId}`
                );
            }

            const { reviewState, approvedAll } = EventToReviewState(event.EventModules, cognitoUser);

            this.setState({
                loading: false,
                event,
                reviewState,
                approvedAll,
                approvalStep: approvalStep,
                users,
            });
        } catch (error) {
            this.props.history.goBack();
            setSnackbar(checkError(error), {
                severity: 'info',
                disableAutoHide: true,
                snackbarButtonProps: {
                    label: 'Manage Approval Process',
                    icon: <ChevronRight />,
                    onClick: (e) => this.props.history.push(`/event-review-process/${this.query.ParentId}`),
                },
            });
        }
    }

    async handleUpdateOwner() {
        const { setSnackbar } = this.props;
        if (this.state.event.OwnerId === 0) return setSnackbar('Please select an event creator');

        this.setState({ updatingOwner: true });
        try {
            await API.patch(
                'ClutchAPI',
                `/events/${this.props.match.params.id}/owner/${this.state.event.OwnerId}?OrganizationId=${this.query.OrganizationId}&ParentId=${this.query.ParentId}`
            );
            Mixpanel.track('Event Owner updated');
            setSnackbar('Owner updated');
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.setState({ updatingOwner: false });
    }

    handleApproveOrComment({ eModule, section, approved, comment }) {
        if (comment) Mixpanel.track('Event step section commented on');
        else Mixpanel.track(`Event step section ${approved ? 'approved' : 'unapproved'}`);
        return new Promise((resolve, reject) => {
            this.queue.push({
                method: 'patch',
                path: `/events/${this.props.match.params.id}/modules/${eModule.id}/section-approval?OrganizationId=${this.query.OrganizationId}&ParentId=${this.query.ParentId}`,
                body: {
                    sections: {
                        [section.id]: {
                            approved,
                            comment,
                        },
                    },
                },
                callback: async (resp) => {
                    if (!resp) return reject();

                    await this._getEvent();
                    resolve();
                },
            });
            this.runQueue();
        });
    }

    runQueue = () => {
        const { setSnackbar } = this.props;

        if (!this.queueHandler) {
            this.queueHandler = setInterval(async () => {
                if (!this.sendingRequest && this.queue.length > 0) {
                    this.sendingRequest = true;
                    let { method, path, body = {}, callback } = this.queue.pop();

                    try {
                        const resp = await API[method]('ClutchAPI', path, { body });
                        typeof callback == 'function' && callback(resp);
                    } catch (error) {
                        this.queue = []; // empty queue
                        setSnackbar(checkError(error)); // Set snackbar
                        typeof callback == 'function' && callback(null);
                    }
                    this.sendingRequest = false;
                }

                if (this.queue.length === 0 && this.sendingRequest === false) {
                    clearInterval(this.queueHandler);
                    this.queueHandler = undefined;
                }
            }, 200);
        }
    };

    async handleUpdateStatus(status) {
        const { setSnackbar } = this.props;
        this.setState({ submitting: status });

        try {
            await API.patch(
                'ClutchAPI',
                `/events/${this.props.match.params.id}/status/${status}?OrganizationId=${this.query.OrganizationId}&ParentId=${this.query.ParentId}`
            );
            Mixpanel.track(`Event status updated to ${status}`);
            await this._getEvent();
            setSnackbar(`Event status updated`);
            this.props.history.goBack();
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.setState({ submitting: false });
    }

    async handleChangeDates({ startDate, endDate }) {
        const { setSnackbar } = this.props;

        const { event } = this.state;
        const values = {
            dates: {
                startDate: moment.utc(new Date(startDate)).toISOString(),
                endDate: moment.utc(new Date(endDate)).toISOString(),
            },
        };

        const formData = new FormData();
        formData.append('sections', JSON.stringify(values));

        try {
            await Upload.patch(
                `/events/${event.id}/modules/${event.information.id}?OrganizationId=${this.query.OrganizationId}&ParentId=${this.query.ParentId}`,
                formData
            );
            await this._getEvent();
            Mixpanel.track('Event dates changed');
            setSnackbar('Event Date Successfully Updated');
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    async handleExport() {
        const { event } = this.state;
        const { setSnackbar } = this.props;

        this.setState({ obtainingAttendees: true });
        try {
            const attendees = await API.get(
                'ClutchAPI',
                `/events/${event.id}/attendees?OrganizationId=${event.OrganizationId}&ParentId=${event.ParentId}`
            );
            ExportPDF(attendees.data, event.information);
        } catch (error) {
            console.log(error);
            setSnackbar('There was an error obtaining the user information');
        }
        this.setState({ obtainingAttendees: false });
    }

    render() {
        const { classes, cognitoUser } = this.props;
        const { loading, event, approvalStep, users, reviewState } = this.state;

        const reviewing =
            !!cognitoUser.roles[approvalStep.role.id] ||
            cognitoUser.policies[policyIdentifiers.FINAL_EVENT_APPROVER] || // Final Reviewer can override anything
            cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY]; // Site admins have access to everything

        const allowActionableButtons =
            cognitoUser.policies[policyIdentifiers.FINAL_EVENT_APPROVER] ||
            cognitoUser.policies[policyIdentifiers.EVENT_APPROVAL_REQUIRED] ||
            cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY];

        const allowDenyButton =
            cognitoUser.policies[policyIdentifiers.FINAL_EVENT_APPROVER] ||
            cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY];

        return loading ? (
            <Page maxWidth={720} topNavigationProps={{ pageTitle: 'Event Review' }}>
                <Skeleton type="infoPage" />
            </Page>
        ) : (
            <Page maxWidth={720} topNavigationProps={{ pageTitle: 'Event Review' }}>
                <ChangeEventDateDialog
                    value={event.information}
                    open={this.state.changeEventDialogOpen}
                    onClose={() => this.setState({ changeEventDialogOpen: false })}
                    onSubmit={this.handleChangeDates.bind(this)}
                />
                <Grid container spacing={1} className={classes.topPageForm}>
                    <Grid item xs={allowActionableButtons ? 8 : 12}>
                        <Select
                            classes={{ root: classes.selectHeight }}
                            value={0}
                            typography={'Event Owner'}
                            disabled={!allowActionableButtons}
                            value={event.OwnerId}
                            onChange={(e) =>
                                this.setState({
                                    event: {
                                        ...event,
                                        OwnerId: e.target.value,
                                    },
                                })
                            }>
                            <MenuItem value={0}>Select Owner</MenuItem>
                            {users.data.map((user, index) => {
                                return (
                                    <MenuItem value={user.id} key={`user-${user.id}`}>
                                        {user.firstName} {user.lastName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                    {allowActionableButtons && (
                        <Grid item xs={4} className={classes.alignBottom}>
                            <Button
                                fullWidth
                                onClick={this.handleUpdateOwner.bind(this)}
                                classes={{ root: classes.buttonHeight }}
                                loading={this.state.updatingOwner}
                                variant="contained"
                                text="Update Owner"
                                color="primary"
                                size="large"
                            />
                        </Grid>
                    )}
                    <Grid item lg={allowActionableButtons ? 4 : 6} xs={12}>
                        <Button
                            fullWidth
                            classes={{ root: classes.buttonHeight }}
                            text="View Event Log"
                            variantType="outlinedOuterSpace"
                            onClick={() =>
                                this.props.history.push(
                                    `${this.props.location.pathname}/log${this.props.location.search}`
                                )
                            }
                            loading={this.state.submitting === 'approved'}
                            disabled={this.state.submitting !== false && this.state.submitting !== 'approved'}
                            color="primary"
                            size="large"
                        />
                    </Grid>
                    <Grid item lg={allowActionableButtons ? 4 : 6} xs={12}>
                        <Button
                            fullWidth
                            classes={{ root: classes.buttonHeight }}
                            text="Export Attendee List"
                            variantType="outlinedOuterSpace"
                            loading={this.state.obtainingAttendees}
                            onClick={this.handleExport.bind(this)}
                            color="primary"
                            size="large"
                        />
                    </Grid>
                    {allowActionableButtons && (
                        <Grid item lg={4} xs={12}>
                            <Button
                                fullWidth
                                onClick={() => this.setState({ changeEventDialogOpen: true })}
                                classes={{ root: classes.buttonHeight }}
                                text="Change Date"
                                variantType="outlinedOuterSpace"
                                color="primary"
                                size="large"
                            />
                        </Grid>
                    )}
                </Grid>
                <RoundedSection style={{ marginBottom: 20 }}>
                    <RoundedSectionBody>
                        <RoundedSectionLabel
                            margin
                            primary={'Event Status'}
                            secondary={`${eventStatusReadable[event.reviewStatus]}`}
                        />
                        <RoundedSectionLabel
                            primary={'Current reviewing role'}
                            secondary={
                                event.reviewStatus === 'submitted' || event.reviewStatus === 'update-required'
                                    ? approvalStep.role.name
                                    : 'N/A'
                            }
                        />
                    </RoundedSectionBody>
                </RoundedSection>
                {reviewState.map((eModule) => {
                    return (
                        <div key={`mod-${eModule.id}`} className={classes.module}>
                            <Typography variant="h4">{eModule.name}</Typography>
                            {Object.keys(eModule.sections).map((sectionId, index) => {
                                const section = eModule.sections[sectionId];

                                return (
                                    <ReviewSection
                                        sectionName={eModule.name}
                                        key={section.id}
                                        section={section}
                                        reviewing={reviewing}
                                        currentUserId={cognitoUser.id}
                                        onSubmitComment={(comment) =>
                                            this.handleApproveOrComment({
                                                eModule,
                                                section,
                                                comment,
                                            })
                                        }
                                        onUpdateApproval={(approved) =>
                                            this.handleApproveOrComment({
                                                eModule,
                                                section,
                                                approved,
                                            })
                                        }
                                    />
                                );
                            })}
                        </div>
                    );
                })}

                {reviewing && (
                    <Grid container spacing={1} className={classes.topPageForm}>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                classes={{ root: classes.buttonHeight }}
                                variant="contained"
                                variantType="containedGreen"
                                text="Event Reviewed"
                                color="primary"
                                size="large"
                                startIcon={<Check className={classes.actionButtonIcon} />}
                                loading={this.state.submitting === 'approved'}
                                disabled={
                                    (this.state.submitting !== false && this.state.submitting !== 'approved') ||
                                    !this.state.approvedAll
                                }
                                onClick={() => this.handleUpdateStatus('approved')}
                            />
                        </Grid>
                        <Grid item lg={allowDenyButton ? 6 : 12} xs={12}>
                            <Button
                                fullWidth
                                classes={{ root: classes.buttonHeight }}
                                variant="contained"
                                text="Submission requires changes"
                                color="primary"
                                size="large"
                                loading={this.state.submitting === 'update-required'}
                                disabled={
                                    this.state.submitting !== false && this.state.submitting !== 'update-required'
                                }
                                onClick={() => this.handleUpdateStatus('update-required')}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            {allowDenyButton && (
                                <Button
                                    fullWidth
                                    classes={{ root: classes.buttonHeight }}
                                    variant="contained"
                                    variantType="containedRed"
                                    text="Eliminate the Event"
                                    color="primary"
                                    size="large"
                                    startIcon={<Close className={classes.actionButtonIcon} />}
                                    loading={this.state.submitting === 'rejected'}
                                    disabled={this.state.submitting !== false && this.state.submitting !== 'rejected'}
                                    onClick={() => this.handleUpdateStatus('rejected')}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Page>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(EventReview));
