export const AllowedFileTypes = {
    'image/*': 'PNG, JPG, JPEG, GIF',
    'image/png': 'PNG',
    'image/jpg': 'JPG',
    'image/jpeg': 'JPEG',
    'image/gif': 'GIF',
    'text/csv': 'CSV',
    'application/pdf': 'PDF',
    'application/msword': 'Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Doc/Docx',
    'video/mp4': 'MP4',
    'video/quicktime': 'QuickTime',
    'application/vnd.ms-excel': 'XLS',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
};
export const AllFileTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'text/csv',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const VideoFileTypes = ['video/mp4', 'video/quicktime'];
export const DocumentFileTypes = [
    'text/csv',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const ImageFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}
