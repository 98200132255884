import { Auth, API } from 'aws-amplify';
import { cloneDeep } from 'lodash';
import checkError from 'utils/check-error';
import Mixpanel from 'mixpanel-browser';
import {
    INIT,
    SET_COGNITO_USER,
    GET_COGNITO_USER_SUCCESS,
    GET_COGNITO_USER_ERROR,
    COGNITO_USER_SIGN_OUT_SUCCESS,
    COGNITO_USER_SIGN_OUT_ERROR,
} from 'redux/reducers/cognitoUser';
// Constants
import { policyIdentifiers } from 'global-constants';

export const get = () => async (dispatch, getState) => {
    try {
        await Auth.currentAuthenticatedUser();
        const response = await Auth.currentSession();
        const payload = response.idToken.payload;

        const user = await API.get('ClutchAPI', '/users/current');

        // Only site admins do not need a ParentOrg attached to them
        if (!user.policies[policyIdentifiers.SITE_ADMIN_POLICY]) {
            if (!user.policies[policyIdentifiers.ADMIN_APP_LOGIN])
                throw new Error('Account does not have permission to login to the Clutch Admin Dashboard');
            if (user.ParentOrganizations.length === 0) throw new Error('Account is not associated to an organization');
            user.ParentOrganization = user.ParentOrganizations[0];

            // If a user does not have access to manage organization, they must have a firstchapter attached for dropdown chapter fields.
            if (!user.policies[policyIdentifiers.MANAGE_ORGANIZATIONS]) {
                if (user.Organizations.length === 0)
                    throw new Error('Account is not associated to any chapters in which to manage.');
                user.FirstChapter = user.Organizations[0];
            }
        }

        dispatch({
            type: GET_COGNITO_USER_SUCCESS,
            payload: {
                ...payload,
                ...user,
            },
        });

        Mixpanel.identify(user.sub);
        Mixpanel.register({
            email: user.email.value,
            firstName: user.firstName,
            lastName: user.lastName,
        });
        Mixpanel.people.set({
            $email: user.email.value,
            firstName: user.firstName,
            lastName: user.lastName,
            USER_ID: user.sub,
        });
        Mixpanel.track('User Login');

        return payload;
    } catch (error) {
        console.log(checkError(error));

        await dispatch(signOut());
        dispatch({
            type: GET_COGNITO_USER_ERROR,
        });

        throw error;
    }
};

export const signOut = () => async (dispatch) => {
    try {
        await dispatch({
            type: COGNITO_USER_SIGN_OUT_SUCCESS,
            payload: cloneDeep(INIT),
        });
        await Auth.signOut();
        await Auth.cleanCachedItems();

        /** Remove all local storage cognito items. */
        Object.keys(localStorage).forEach((key) => {
            if (key.toLowerCase().includes('cognito')) {
                localStorage.removeItem(key);
            }
        });

        return true;
    } catch (error) {
        console.log(error);
        dispatch({
            type: COGNITO_USER_SIGN_OUT_ERROR,
        });

        return false;
    }
};

export const set = (user) => {
    if (user) {
        return {
            type: SET_COGNITO_USER,
            payload: user,
        };
    }
    // Empty user in store if no user is sent
    return {
        type: SET_COGNITO_USER,
        payload: cloneDeep(INIT),
    };
};
