import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    get as getPersonalSafetyAlert,
    remove as deletePersonalSafetyAlert,
    sendEmail as sendPersonalSafetyEmail,
} from 'redux/actions/personalSafetyAlert';
import { setSnackbar } from 'redux/actions/snackbar';

// Modules
import Mixpanel from 'mixpanel-browser';
import dayjs from 'dayjs';
import checkError from 'utils/check-error';
import queryString from 'query-string';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
// Components
import Page from 'components/Page';
import EmailDialog from 'components/Dialogs/EmailDialog';
import BasicDialog from 'components/Dialogs/BasicDialog';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import DeleteButton from 'components/Button/DeleteButton';
// import DownloadButton      from 'components/Button/DownloadButton';
import EmailButton from 'components/Button/EmailButton';
import RoundedSection from 'components/RoundedSection';
import RoundedSectionBody from 'components/RoundedSection/RoundedSectionBody';
import RoundedSectionLabel from 'components/RoundedSection/RoundedSectionLabel';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const PersonalSafetyAlert = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { personalSafetyAlert, cognitoUser } = useSelector(({ personalSafetyAlert, cognitoUser }) => ({
        personalSafetyAlert,
        cognitoUser,
    }));

    const query = queryString.parse(props.history.location.search);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [emailListModalOpen, setEmailListModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        async function _getAlert() {
            if (!query.OrganizationId || !query.ParentId) {
                dispatch(setSnackbar('Permission Denied'));
                return props.history.push('/personal-safety-alerts');
            }

            setLoading(true);
            try {
                await dispatch(
                    getPersonalSafetyAlert({
                        id: props.match.params.id,
                        ...query,
                    })
                );
                Mixpanel.track('Personal safety alert viewed');
                setLoading(false);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
                props.history.push('/personal-safety-alerts');
            }
        }
        _getAlert();
    }, [dispatch, props.match.params.id, props.history]);

    async function handleDeleteSafetyAlert() {
        setRemoving(true);
        try {
            await dispatch(deletePersonalSafetyAlert(personalSafetyAlert.id));
            Mixpanel.track('Personal safety alert removed');
            dispatch(setSnackbar('Safety Alert was removed!'));
            props.history.push('/personal-safety-alerts');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        setRemoving(false);
    }

    async function handleSendEmails(emails) {
        try {
            await dispatch(sendPersonalSafetyEmail(emails.replace(/ /g, '').split(',')));
            Mixpanel.track('Personal safety alert email sent');
            dispatch(setSnackbar('Emails sent'));
            setEmailListModalOpen(false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    return loading ? (
        <Page maxWidth={720}>
            <Skeleton type="infoPage" />
        </Page>
    ) : (
        <Page maxWidth={720} topNavigationProps={{ pageTitle: 'Personal Safety Alert' }}>
            <EmailDialog
                open={emailListModalOpen}
                onClose={() => setEmailListModalOpen(false)}
                onSubmit={handleSendEmails}
            />
            <BasicDialog
                open={deleteModalOpen}
                title={
                    <span>
                        Delete Safety Alert
                        <br />
                        {`"${personalSafetyAlert.Organization.name}" - ${personalSafetyAlert.User.firstName} ${personalSafetyAlert.User.lastName}`}
                    </span>
                }
                subtitle={'Are you sure you would like to delete this Safety Alert?'}
                onClose={() => setDeleteModalOpen(false)}
                onSubmit={handleDeleteSafetyAlert}
            />
            <div className={classes.topPageForm}>
                <Button
                    fullWidth={false}
                    text="Return to Personal Safety Alerts"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={(e) => props.history.push('/personal-safety-alerts')}
                    startIcon={<ChevronLeft />}
                />
                <div className={classes.rightButtons}>
                    {/* <DownloadButton className={classes.actionButton} /> */}
                    <EmailButton disabled={removing} onClick={() => setEmailListModalOpen(true)} />
                    <DeleteButton disabled={removing} onClick={() => setDeleteModalOpen(true)} tooltip="Delete Alert" />
                </div>
            </div>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel primary="Organization" secondary={personalSafetyAlert.Organization.name} />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel
                        margin
                        primary="Submitted By"
                        secondary={
                            cognitoUser.policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS] ||
                            cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY] ? (
                                <Link
                                    to={`/org-users/${personalSafetyAlert.User.id}?OrganizationId=${query.OrganizationId}`}>
                                    {`${personalSafetyAlert.User.firstName} ${personalSafetyAlert.User.lastName}`}
                                </Link>
                            ) : (
                                `${personalSafetyAlert.User.firstName} ${personalSafetyAlert.User.lastName}`
                            )
                        }
                    />
                    <RoundedSectionLabel
                        margin
                        primary="Submitted Date"
                        secondary={dayjs(personalSafetyAlert.createdAt).format('MMMM DD, YYYY')}
                    />
                    <RoundedSectionLabel
                        primary="Submitted Time"
                        secondary={dayjs(personalSafetyAlert.createdAt).format('h:mm A')}
                    />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel primary="Phone Number" secondary={personalSafetyAlert.User.phone} />
                </RoundedSectionBody>
            </RoundedSection>
            <RoundedSection classes={{ root: classes.roundedSection }}>
                <RoundedSectionBody>
                    <RoundedSectionLabel primary="Alert Text" secondary={personalSafetyAlert.information} />
                </RoundedSectionBody>
            </RoundedSection>
        </Page>
    );
};

export default withStyles(styles)(PersonalSafetyAlert);
