import React from "react";
import { Route, Router, Switch } from "react-router-dom";

import { history } from "./redux/config";

import Snackbar from "./components/Snackbar";
import Authorizer from "./Authorizer";

const _Router = (props) => {
    return (
        <React.Fragment>
            <Snackbar />
            {/* <ConnectedModal /> */}

            <Router history={history}>
                <Switch>
                    {/* TODO: Change with authorizer once we have authentication */}
                    <Route path="/" component={Authorizer} />
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default _Router;
