// React
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI
import { withStyles } from '@material-ui/core/styles';
// Components
import SkeletonEventSquare from './SkeletonEventSquare';
import SkeletonEventItem from './SkeletonEventItem';
import SkeletonListItem from './SkeletonListItem';
import SkeletonTableColumn from './SkeletonTableColumn';
import SkeletonInfoPage from './SkeletonInfoPage';
import SkeletonEventCreator from './SkeletonEventCreator';
import FullscreenLoader from 'components/FullscreenLoader';
// Styles
import styles from './styles';

const Skeleton = ({ type = null, ...props }) => {
    return type !== null ? (
        <>
            {type === 'eventItem' && <SkeletonEventItem {...props} />}
            {type === 'eventSquare' && <SkeletonEventSquare {...props} />}
            {type === 'eventCreator' && <SkeletonEventCreator {...props} />}
            {type === 'listItem' && <SkeletonListItem {...props} />}
            {type === 'tableColumn' && <SkeletonTableColumn {...props} />}
            {type === 'infoPage' && <SkeletonInfoPage {...props} />}
        </>
    ) : (
        <FullscreenLoader className={props.classes.root} />
    );
};

Skeleton.propTypes = {
    className: PropTypes.string,
};

export default withStyles(styles)(Skeleton);
