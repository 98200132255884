import { Auth } from 'aws-amplify';

/* eslint-disable no-restricted-globals */
// AWS Project Constants
const awsmobile = {
    Auth: {
        identityPoolId: "us-east-1:691e6ae9-ee64-4be3-a237-c7a9ceeab81b",
        userPoolId: "us-east-1_jorqRkZJe",
        userPoolWebClientId: "1uv57754e0u77h604ueeddvluh",

        region: "us-east-1",
        oauth: {
            "domain": "clutch-test.auth.us-east-1.amazoncognito.com",
            "scope": [
              "email",
              "profile",
              "openid",
              "refresh"
            ],
            "redirectSignIn": `${location.origin}/authorizing`,
            "redirectSignOut": location.origin,
            "responseType": "code",
          }
    },
    APIV1Endpoint: "http://localhost:8080/api/v1",
    PublicAPIEndpoint: "http://localhost:8080/api/v1",
    API: {
        endpoints: [
            {
                name: "ClutchAPI",
                endpoint: "http://localhost:8080/api/v1",
                region: "us-east-1",
                custom_header: async () => {
                    const user = await Auth.currentSession()
                    return {
                        Authorization: user.idToken.jwtToken,
                    }
                },
            },
        ],
    },
};

if (typeof location !== "undefined") {
    awsmobile.Auth.cookieStorage = {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: location.hostname,
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: location.protocol === "https:",
    };
}

export default awsmobile;
