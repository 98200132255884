import { Auth } from 'aws-amplify';

/* eslint-disable no-restricted-globals */
// AWS Project Constants
const awsmobile = {
    Auth: {
        identityPoolId: 'us-east-1:7e25a3af-91b3-4fa1-a916-63cc1dcbccd9',
        userPoolId: 'us-east-1_ywcKU79rC',
        userPoolWebClientId: '43lcaiirs1dsogsefq1cldjia6',

        region: 'us-east-1',
        oauth: {
            "domain": "clutch.auth.us-east-1.amazoncognito.com",
            "scope": [
              "email",
              "profile",
              "openid",
            ],
            "redirectSignIn": `${location.origin}/authorizing`,
            "redirectSignOut": location.origin,
            "responseType": "code",
          }
    },
    APIV1Endpoint: 'https://api.myclutch.online/api/v1',
    PublicAPIEndpoint: 'https://api.myclutch.online/api/v1',
    API: {
        endpoints: [
            {
                name: 'ClutchAPI',
                endpoint: 'https://api.myclutch.online/api/v1',
                region: 'us-east-1',
                custom_header: async () => {
                    const user = await Auth.currentSession();
                    return {
                        Authorization: user.idToken.jwtToken,
                    };
                },
            },
        ],
    },
};

if (typeof location !== 'undefined') {
    awsmobile.Auth.cookieStorage = {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: location.hostname,
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: location.protocol === 'https:',
    };
}

export default awsmobile;
