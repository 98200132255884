const styles = (theme) => ({
    root: {},
    gridContainer: {
        margin: '20px 0',
    },
    divider: {
        margin: '20px 0',
    },
    filterButton: {
        height: 57,
        width: 57,
    },
    buttonSize: {
        height: 57,
    },
    link: {
        textDecoration: 'none',
    },
});

export default styles;
