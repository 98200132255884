import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
// Modules
import moment from 'moment';
// Redux
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// Components
import Button from 'components/Button';
import Input from 'components/Input';

// Data
import FORM_INIT from './init';

// Styles
import styles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ChangeEventDateDialog extends React.Component {
    constructor(props) {
        super(props);

        this.INIT = FORM_INIT; // Init is needed for update field event though i'm setting the fieldvalues

        const value = props.value;
        const startDate = moment(value.startDate).format('yyyy-MM-DD');
        const startTime = moment(value.startDate).format('HH:mm:ss');
        const endDate = moment(value.endDate).format('yyyy-MM-DD');
        const endTime = moment(value.endDate).format('HH:mm:ss');

        this.state = {
            submitting: false,
            startDate: { value: startDate, valid: true },
            startTime: { value: startTime, valid: true },
            endDate: { value: endDate, valid: true },
            endTime: { value: endTime, valid: true },
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            const value = this.props.value;

            const startDate = moment(value.startDate).format('yyyy-MM-DD');
            const startTime = moment(value.startDate).format('HH:mm:ss');
            const endDate = moment(value.endDate).format('yyyy-MM-DD');
            const endTime = moment(value.endDate).format('HH:mm:ss');

            this.setState({
                startDate: { value: startDate, valid: true },
                startTime: { value: startTime, valid: true },
                endDate: { value: endDate, valid: true },
                endTime: { value: endTime, valid: true },
            });
        }
    }

    handleClose = () => {
        if (!this.state.submitting && typeof this.props.onClose === 'function') this.props.onClose();
    };

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ submitting: true });

        const startDate = moment.utc(new Date(`${this.state.startDate.value}T${this.state.startTime.value}`));
        const endDate = moment.utc(new Date(`${this.state.endDate.value}T${this.state.endTime.value}`));
        if (startDate.isAfter(endDate)) {
            this.updateField({ field: 'startDate', valid: false });
            this.updateField({ field: 'startTime', valid: false });
            this.updateField({ field: 'endDate', valid: false });
            this.updateField({ field: 'endTime', valid: false });
            return setSnackbar('Start date can not be after end date');
        }

        if (typeof this.props.onSubmit === 'function') {
            try {
                await this.props.onSubmit({
                    startDate,
                    endDate,
                });
                this.setState({ submitting: false }, () => {
                    this.handleClose();
                });
            } catch (error) {
                this.setState({ submitting: false });
            }
        }
    };

    render() {
        const { classes, open, button = {} } = this.props;

        return (
            <Dialog
                open={open}
                classes={{ paper: classes.paper }}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <IconButton className={classes.closeButton} onClick={this.handleClose}>
                    <Close />
                </IconButton>
                <DialogTitle disableTypography classes={{ root: classes.title }}>
                    Change Event Dates
                </DialogTitle>
                <form onSubmit={this.handleSubmit}>
                    <DialogContent className={classes.content}>
                        <Input
                            ref="startDate"
                            value={this.state.startDate.value}
                            error={!this.state.startDate.valid}
                            errorMessage="Start date is required"
                            validator={() => !!this.state.startDate.value}
                            onChange={(e) => this.updateField({ field: 'startDate', value: e.target.value })}
                            label="Start Date"
                            variant="standard"
                            type="date"
                            fullWidth
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <Input
                            ref="startTime"
                            value={this.state.startTime.value}
                            error={!this.state.startTime.valid}
                            errorMessage="Start time is required"
                            validator={() => !!this.state.startTime.value}
                            onChange={(e) => this.updateField({ field: 'startTime', value: e.target.value })}
                            label="Start Time*"
                            variant="standard"
                            type="time"
                            fullWidth
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <Input
                            ref="endDate"
                            value={this.state.endDate.value}
                            error={!this.state.endDate.valid}
                            errorMessage="End date is required"
                            validator={() => !!this.state.endDate.value}
                            onChange={(e) => this.updateField({ field: 'endDate', value: e.target.value })}
                            label="End Date*"
                            variant="standard"
                            type="date"
                            fullWidth
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <Input
                            ref="endTime"
                            value={this.state.endTime.value}
                            error={!this.state.endTime.valid}
                            errorMessage="End time is required"
                            validator={() => !!this.state.endTime.value}
                            onChange={(e) => this.updateField({ field: 'endTime', value: e.target.value })}
                            label="End Time*"
                            variant="standard"
                            type="time"
                            fullWidth
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                    </DialogContent>
                    <DialogActions classes={{ root: classes.actions }}>
                        <Button
                            color="primary"
                            onClick={this.handleSubmit}
                            loading={this.state.submitting}
                            text="Submit"
                            variant="contained"
                            fullWidth={true}
                            {...button}
                        />
                    </DialogActions>
                    <input ref="submit" type="submit" style={{ display: 'none' }} />
                </form>
            </Dialog>
        );
    }
}

export default withStyles(styles)(connect(null, { setSnackbar })(ChangeEventDateDialog));
