import React from 'react';
// Modules
import { AllFileTypes } from 'utils/file-types';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// Components
import Input from 'components/Input';
import FileInput from 'components/Input/FileInput';
// Styles
import styles from './styles';

class FileDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: props.file || '',
        };

        this.title = props.title || '';
        this.file = props.file || '';
        this.helperText = props.helperText || '';
    }

    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                title: this.title,
                file: this.file,
                helperText: this.helperText,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                        <Input
                            placeholder="Type File Download Title"
                            variant="standard"
                            fullWidth
                            onChange={(e) => this.onChange('title', e.target.value)}
                            defaultValue={this.title}
                            marginBottom={8}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <FileInput
                            accept={[...AllFileTypes]}
                            classes={{ uploadRoot: classes.toolbarItem }}
                            file={this.state.file}
                            onChange={(file) => {
                                this.setState({ file });
                                this.onChange('file', file);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} classes={{ root: classes.helper }}>
                        <Input
                            placeholder="Helper Text (Optional)"
                            fullWidth
                            variant="standard"
                            defaultValue={this.helperText}
                            onChange={(e) => this.onChange('helperText', e.target.value)}
                            InputProps={{
                                classes: { input: classes.helperText },
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(FileDownload);
