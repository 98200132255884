import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import {
    INIT,
    GET_INCIDENT_REPORT_SUCCESS,
    GET_INCIDENT_REPORT_ERROR,
    SEND_INCIDENT_REPORT_EMAIL_SUCCESS,
    SEND_INCIDENT_REPORT_EMAIL_ERROR,
    DELETE_INCIDENT_REPORT_ERROR,
    DELETE_INCIDENT_REPORT_SUCCESS,
} from 'redux/reducers/incidentReport';

export const get = ({ id, EventId, OrganizationId, ParentId }) => async (dispatch) => {
    let path = `/events/incident-reports/${id}?OrganizationId=${OrganizationId}&EventId=${EventId}&ParentId=${ParentId}`;

    try {
        const alert = await API.get('ClutchAPI', path);

        dispatch({
            type: GET_INCIDENT_REPORT_SUCCESS,
            payload: alert,
        });
    } catch (error) {
        dispatch({
            type: GET_INCIDENT_REPORT_ERROR,
        });
        throw error;
    }
};

export const sendEmail = (emails) => async (dispatch, getState) => {
    let { incidentReport } = getState();

    try {
        await API.post(
            'ClutchAPI',
            `/events/incident-reports/${incidentReport.id}/email?OrganizationId=${incidentReport.OrganizationId}&ParentId=${incidentReport.ParentId}`,
            {
                body: {
                    emails,
                },
            }
        );

        dispatch({
            type: SEND_INCIDENT_REPORT_EMAIL_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SEND_INCIDENT_REPORT_EMAIL_ERROR,
        });
        throw error;
    }
};

export const remove = () => async (dispatch, getState) => {
    let { incidentReport } = getState();
    let path = `/events/incident-reports/${incidentReport.id}?OrganizationId=${incidentReport.OrganizationId}&ParentId=${incidentReport.ParentId}`;

    try {
        await API.del('ClutchAPI', path);
        dispatch({
            type: DELETE_INCIDENT_REPORT_SUCCESS,
            payload: cloneDeep(INIT),
        });
    } catch (error) {
        dispatch({
            type: DELETE_INCIDENT_REPORT_ERROR,
            payload: cloneDeep(INIT),
        });
        throw error;
    }
};
