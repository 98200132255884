import { colors } from 'global-styles';

const styles = (theme) => ({
    paperTitle: {
        paddingLeft: 9,
        marginBottom: 10,
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    paper: {
        padding: 20,
        flex: 1,
    },
    roleRequired: {
        textDecoration: 'underline',
        color: colors.info,
        cursor: 'pointer',
    },
});

export default styles;
