const styles = (theme) => ({
    root: {},
    toolbarItem: {
        backgroundColor: '#f8fafd',
        '& div': {
            backgroundColor: '#f8fafd',
        },
    },
    editor: {
        minHeight: 120,
    },
});

export default styles;
