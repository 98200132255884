const styles = (theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    outlinedInfoRoot: {
        color: 'rgb(13, 60, 97)',
        border: '1px solid #6e96f7',
    },
    outlinedInfo: {
        display: 'flex',
        alignItems: 'center',
    },
});
export default styles;
