import React from 'react';

// Modules
import clsx from 'classnames';
// Material ui
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from './styles';

const RoundedSectionLabel = ({ classes, primary, secondary, margin, marginTop }) => {
    const rootClass = clsx({
        [classes.root]: true,
        [classes.margin]: margin,
        [classes.marginTop]: marginTop,
    });

    return (
        <div className={rootClass}>
            <Typography className={classes.primary}>{primary}</Typography>
            {typeof secondary === 'object' ? (
                secondary
            ) : (
                <Typography className={classes.secondary}>{secondary}</Typography>
            )}
        </div>
    );
};

export default withStyles(styles)(RoundedSectionLabel);
