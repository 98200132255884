import React from 'react';
// Material UI
import SkeletonTableColumn from '../SkeletonTableColumn';

const SkeletonTableRow = ({ frontElements = [], count, backElements = [] }) => {
    const array = [...frontElements];

    Array.from(new Array(count)).forEach((index) => {
        array.push(<SkeletonTableColumn />);
    });

    array.push(...backElements);

    return array;
};

export default SkeletonTableRow;
