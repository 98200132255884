const styles = (theme) => ({
    topPageForm: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    hr: {
        backgroundColor: 'rgba(151, 151, 151, 0.41)',
        opacity: 0.41,
        margin: '10px 0px',
    },
    listItemButton: {
        minHeight: 57,
    },
    nested: {
        paddingLeft: theme.spacing(8),
    },
    roundedSection: {
        margin: '20px 0',
    },
    buttonHeight: {
        height: 60,
    },
    selectHandlers: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    selectButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    selectHandlersSlash: {
        display: 'flex',
        alignItems: 'center',
    },
    activeText: {
        textDecoration: 'underline',
    },
    listRoot: {
        backgroundColor: 'white',
        border: '1px solid rgba(151, 151, 151, 0.41)',
        borderRadius: 4,
        padding: '4px 8px',
    },
    username: {
        fontSize: 16,
    },

    searchOpen: {
        transform: 'none',
    },
    search: {
        minWidth: 275,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    bgWhite: {
        backgroundColor: 'white',
    },
});

export default styles;
