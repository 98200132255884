const styles = (theme) => ({
    root: {},
    colorPicker: {
        width: 'auto !important',
        marginBottom: 12,
    },
    checkboxRoot: {
        background: 'white',
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: 4,
        padding: '0px 4px 0px 16px',
    },
});

export default styles;
