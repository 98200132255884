import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
    Tooltip,
    IconButton,
    CircularProgress,
    Paper,
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
// Styles
import styles from './styles';

class UserRoles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changing: {},
        };
        this.changing = {};
        this.queue = [];
    }

    async handleRoleClicked(role, func) {
        this.changing[role.id] = true;
        this.setState({ changing: this.changing });

        this.queue.push({ role, func });
        this.runQueue((role) => {
            if (!role) return;
            delete this.changing[role.id];
            this.setState({ changing: this.changing });
        });
    }

    runQueue = (callback) => {
        if (!this.queueHandler) {
            this.queueHandler = setInterval(async () => {
                if (!this.sendingRequest && this.queue.length > 0) {
                    this.sendingRequest = true;
                    let { role, func } = this.queue.pop();

                    try {
                        await this.props[func](role).catch(console.log);
                        typeof callback == 'function' && callback(role);
                    } catch (error) {
                        this.queue = []; // empty queue
                        typeof this.props.onError === 'function' && this.props.onError(error); // Set snackbar
                        typeof callback === 'function' && callback(false);
                    }
                    this.sendingRequest = false;
                }

                if (this.queue.length === 0) {
                    clearInterval(this.queueHandler);
                    this.queueHandler = undefined;
                }
            }, 200);
        }
    };

    render() {
        const { classes, roles = [], userRoles = [], user, showDialog } = this.props;

        const userRolesById = {};
        userRoles.forEach((role) => (userRolesById[role.id] = true));
        const filteredRoles = roles.filter((role) => !userRolesById[role.id]);
        const rolesById = {};
        roles.forEach((role) => (rolesById[role.id] = role));

        return (
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6} className={classes.gridItem}>
                        <Typography className={classes.paperTitle} variant="h3">
                            Available Roles
                        </Typography>
                        <Paper className={classes.paper}>
                            <List>
                                {filteredRoles.map((role, index) => {
                                    return (
                                        <ListItem
                                            onClick={() => {
                                                if (user.integrationId)
                                                    showDialog({
                                                        onSubmit: this.handleRoleClicked.bind(this, role, 'onAddRole'),
                                                    });
                                                else this.handleRoleClicked(role, 'onAddRole');
                                            }}
                                            disabled={this.changing[role.id]}
                                            divider={filteredRoles.length - 1 !== index}
                                            key={`role-${role.id}`}
                                            button>
                                            <ListItemText
                                                primary={role.name}
                                                secondary={role.metadata?.source?.join?.(', ') ?? 'No Source Role'}
                                            />
                                            <ListItemSecondaryAction>
                                                <Tooltip title="Add role to user">
                                                    <span>
                                                        <IconButton
                                                            onClick={() => {
                                                                if (user.integrationId)
                                                                    showDialog({
                                                                        onSubmit: this.handleRoleClicked.bind(
                                                                            this,
                                                                            role,
                                                                            'onAddRole'
                                                                        ),
                                                                    });
                                                                else this.handleRoleClicked(role, 'onAddRole');
                                                            }}
                                                            disabled={this.changing[role.id]}>
                                                            {this.changing[role.id] ? (
                                                                <CircularProgress style={{ height: 24, width: 24 }} />
                                                            ) : (
                                                                <Add />
                                                            )}
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <Typography className={classes.paperTitle} variant="h3">
                            Active Roles
                        </Typography>
                        <Paper className={classes.paper}>
                            <List>
                                {userRoles.map(({ id }, index) => {
                                    const role = rolesById[id];
                                    if (!role) return null;
                                    let linkToChapters = false;
                                    if (this.props.chaptersCount === 0) {
                                        role.policies.forEach((policy) => {
                                            if (policy.metadata.member) linkToChapters = true;
                                        });
                                    }

                                    return (
                                        <ListItem divider={userRoles.length - 1 !== index} key={`role-${role.id}`}>
                                            <ListItemText
                                                primary={role.name}
                                                secondary={
                                                    linkToChapters ? (
                                                        <span
                                                            onClick={this.props.goToChapters}
                                                            className={classes.roleRequired}>
                                                            Role requires an assigned chapter
                                                        </span>
                                                    ) : (
                                                        role.metadata?.source?.join?.(', ') || 'No Source Role'
                                                    )
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <Tooltip title="Remove role from user">
                                                    <span>
                                                        <IconButton
                                                            onClick={() => this.handleRoleClicked(role, 'onRemoveRole')}
                                                            disabled={this.changing[role.id]}>
                                                            {this.changing[role.id] ? (
                                                                <CircularProgress style={{ height: 24, width: 24 }} />
                                                            ) : (
                                                                <Close />
                                                            )}
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(UserRoles);
