// React
import React, { Component } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

// Modules
import clsx from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

// Items
import CheckboxItem from '../components/Checkbox';
import TitleItem from '../components/Title';
import HeadingItem from '../components/Heading';
import BodyTextItem from '../components/BodyText';
import TextareaItem from '../components/Textarea';
import ShortTextInputItem from '../components/ShortTextInput';
import WhitespaceItem from '../components/Whitespace';
import FileUploadItem from '../components/FileUpload';
import FileDownloadItem from '../components/FileDownload';
import VolunteerItem from '../components/Volunteer';
import HorizontalRulerItem from '../components/HorizontalRuler';
import SelectsItem from '../components/Selects';

// Styles
import styles from './styles';

// Assets
import Move from 'assets/icons/Move.svg';

class EventStepSection extends Component {
    renderItem = (item, index) => {
        const { classes, onRemoveItem, onChangeItem } = this.props;

        /**
         * TODO: Make components here that match the design
         *  Components should be able to handle onChange commands and have "initial values" sent
         */

        let component = <div className={classes.itemContent}>{item.type}</div>;
        switch (item.type) {
            case 'checkBox':
                component = (
                    <CheckboxItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'bodyText':
                component = (
                    <BodyTextItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'heading':
                component = (
                    <HeadingItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'title':
                component = (
                    <TitleItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'textAreaInput':
                component = (
                    <TextareaItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'shortTextInput':
                component = (
                    <ShortTextInputItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'fileUpload':
                component = (
                    <FileUploadItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'fileDownload':
                component = (
                    <FileDownloadItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'volunteer':
                component = (
                    <VolunteerItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                    />
                );
                break;
            case 'selects':
                component = (
                    <SelectsItem
                        {...item}
                        onChange={(values) => {
                            onChangeItem(index, values);
                        }}
                        classes={{ root: classes.itemContent }}
                        specialized={this.props.specialized}
                    />
                );
                break;
            case 'whiteSpace':
                component = <WhitespaceItem classes={{ root: classes.itemContent }} />;
                break;
            case 'horizontalRuler':
                component = <HorizontalRulerItem classes={{ root: classes.itemContent }} />;
                break;
            default:
                component = <div className={classes.itemContent}>Unknown Type</div>;
                break;
        }

        return (
            <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                    <div
                        className={clsx(classes.item, {
                            [classes.whitespace]: item.type === 'whiteSpace',
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <IconButton
                            className={clsx(classes.handle, classes.controlIcons)}
                            {...provided.dragHandleProps}>
                            <MenuIcon />
                        </IconButton>

                        {component}

                        <IconButton
                            onClick={() => onRemoveItem(index)}
                            className={clsx(classes.itemDelete, classes.controlIcons)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                )}
            </Draggable>
        );
    };

    render() {
        const { classes, dragHandleProps, section, title, onRemove } = this.props;

        return (
            <Droppable droppableId={section.id} type="ITEM" key={section.id}>
                {(dropProvided, dropSnapshot) => (
                    <div className={classes.section} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                        <div className={classes.sectionHeader}>
                            <div className={classes.move} {...dragHandleProps}>
                                <IconButton className={classes.moveButton} disabled>
                                    <img src={Move} alt="move section" />
                                </IconButton>
                                <Typography variant="h5" className={classes.moveText}>
                                    Move
                                </Typography>
                            </div>
                            <Typography className={classes.title}>{title}</Typography>
                            <div className={classes.delete}>
                                <Typography variant="h5" className={classes.deleteText}>
                                    Delete
                                </Typography>
                                <IconButton className={classes.deleteButton} onClick={onRemove}>
                                    <CloseIcon style={{ color: 'white' }} />
                                </IconButton>
                            </div>
                        </div>
                        <div className={classes.sectionBody}>
                            {section.markup.map((item, index) => this.renderItem(item, index))}
                            {dropProvided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        );
    }
}

export default withStyles(styles)(EventStepSection);
