const styles = (theme) => ({
    body: {
        backgroundColor: theme.palette.primary.main,
    },

    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginRight: 'auto',
        marginLeft: 'auto',
        height: '50vh',
    },

    logo: {
        marginTop: 30,
        width: 147,
    },
    pageSection: {
        margin: '0 auto',
        position: 'relative',
        width: '100%',
        display: 'flex',
        zIndex: 99,
        justifyContent: 'center',
    },
    paper: {
        maxWidth: 500,
        width: '100%',
        alignItems: 'center',
        borderRadius: 6,
        marginLeft: 15,
        marginRight: 15,
        padding: 20,
        [theme.breakpoints.up('sm')]: {
            borderRadius: 6,
            // height: "unset",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 58,
            paddingBottom: 58,
        },
    },
});

export default styles;
