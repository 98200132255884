import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import queryString from 'query-string';
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import FormValidator from 'utils/FormValidator';
import validator from 'validator';
import phone from 'phone';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { stateAbbreviations } from 'global-constants';
// Components
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Input/Select';
import Page from 'components/Page';
// Variables
import FORM_INIT from './init';
// styles
import styles from './styles';

class OrgUserNew extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);

        this.query = queryString.parse(this.props.history.location.search);
        this.state = {
            submitting: false,
            ...cloneDeep(this.INIT),
        };
    }

    componentDidMount() {
        this.mounted = true;

        if (!this.query.OrganizationId) {
            setSnackbar('Permission denied');
            return this.props.history.push('/org-users');
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { setSnackbar } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        try {
            const newUser = await API.post('ClutchAPI', `/organizations/${this.query.OrganizationId}/users`, {
                body: {
                    firstName: this.state.firstName.value,
                    lastName: this.state.lastName.value,
                    email: this.state.email.value,
                    phone: phone(this.state.phone.value)[0],
                    office: this.state.office.value,
                    address: this.state.address.value,
                    city: this.state.city.value,
                    state: this.state.state.value,
                    zip: this.state.zip.value,
                },
            });
            Mixpanel.track('Organization user created', { OrganizationId: this.query.OrganizationId });
            setSnackbar('User was added to your organization, you can start adding roles.');
            this.props.history.push(`/org-users/${newUser.id}?OrganizationId=${this.query.OrganizationId}&tab=roles`);
        } catch (error) {
            setSnackbar(checkError(error));
            this.mounted && this.setState({ submitting: false });
        }
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;

        return (
            <Page maxWidth={720} topNavigationProps={{ pageTitle: 'Create Organization User' }}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className={classes.actions}>
                        <Button
                            classes={{
                                root: classes.mb10,
                                startIcon: classes.chevronLeft,
                            }}
                            text="Return to Organization Users"
                            onClick={() => this.props.history.push('/org-users')}
                            variant="contained"
                            variantType="containedBlue"
                            disabled={this.state.submitting}
                            disableElevation
                            startIcon={<ChevronLeft />}
                        />
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Input
                                ref="firstName"
                                value={this.state.firstName.value}
                                valid={this.state.firstName.valid}
                                errorMessage="Name is Required"
                                validator={() => !!this.state.firstName.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'firstName',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="First Name*"
                                placeholder="User first name"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="lastName"
                                value={this.state.lastName.value}
                                valid={this.state.lastName.valid}
                                errorMessage="Name is Required"
                                validator={() => !!this.state.lastName.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'lastName',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Last Name*"
                                placeholder="User Last name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                ref="email"
                                value={this.state.email.value}
                                valid={this.state.email.valid}
                                errorMessage="Email is Required"
                                validator={() => validator.isEmail(this.state.email.value)}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'email',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Email*"
                                placeholder="User Email"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="phone"
                                value={this.state.phone.value}
                                valid={this.state.phone.valid}
                                errorMessage="Phone number is Required"
                                validator={() => phone(this.state.phone.value).length > 0}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'phone',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Phone Number*"
                                placeholder="Users Phone Number"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="office"
                                value={this.state.office.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'office',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Office number"
                                placeholder="Number to office"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                ref="address"
                                value={this.state.address.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'address',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={10}
                                fullWidth
                                typography="Street Address"
                                placeholder="Street Address"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                ref="city"
                                value={this.state.city.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'city',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={20}
                                fullWidth
                                typography="City"
                                placeholder="City"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                ref="state"
                                value={this.state.state.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'state',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={20}
                                fullWidth
                                typography="State"
                                placeholder="State">
                                <MenuItem key={''} value={''}>
                                    state
                                </MenuItem>
                                {stateAbbreviations.map((state) => {
                                    return (
                                        <MenuItem key={state} value={state}>
                                            {state}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                ref="zip"
                                value={this.state.zip.value}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'zip',
                                        value: e.target.value,
                                    })
                                }
                                marginBottom={20}
                                fullWidth
                                typography="Zip Code"
                                placeholder="Zip Code"
                            />
                        </Grid>
                    </Grid>
                    <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                    <Button
                        text={this.state.id ? 'Update User' : 'Add New User'}
                        fullWidth
                        onClick={this.handleSubmit.bind(this)}
                        variant="contained"
                        variantType="containedBlue"
                        disableElevation
                        loading={this.state.submitting}
                    />
                </form>
            </Page>
        );
    }
}

const actions = { setSnackbar };

export default connect(null, actions)(withStyles(styles)(OrgUserNew));
