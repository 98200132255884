import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Local
import { ReactComponent as Info } from 'assets/icons/Info.svg';
// styles
import styles from './styles';

const Indicator = ({ classes, ...props }) => {
    return (
        <div className={classes.root}>
            <Info className={classes.icon} />
            <Typography className={classes.text}>{props.children}</Typography>
        </div>
    );
};

export default withStyles(styles)(Indicator);
