import { useEffect, useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import queryString from 'query-string';
import checkError from 'utils/check-error';

const useCategories = (organization, mounted, setLoadingCategories) => {
    const [categories, setCategories] = useState({ count: 0, data: [] });
    const dispatch = useDispatch();

    useEffect(() => {
        if (organization !== '0') {
            _getCategories();
        }
    }, [organization]);

    async function _getCategories(query = {}, setLoadingState = true) {
        if (organization && organization !== 'all') {
            query.OrganizationId = organization;
        }

        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingCategories(true);
        try {
            const categories = await API.get('ClutchAPI', `/organizations/${organization}/categories?${params}`);
            mounted && setCategories(categories);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingCategories(false);
    }

    return [categories, setCategories, _getCategories];
};

export default useCategories;
