const styles = (theme) => ({
    root: {},
    input: {
        fontSize: 18,
        textDecoration: 'underline',
        fontWeight: 700,
        fontFamily: 'Lato',
    },
});

export default styles;
