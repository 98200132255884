const init = {
    maxGuestAllowed: { value: 500, valid: true },
    logo: { value: '', valid: true },
    logoDisplaySize: { value: 'default', valid: true },
    eventPhoto: { value: '', valid: true },
    eventDateChangeTimeLimit: { value: 14, valid: true },
    daysBeforeEventCreate: { value: 0, valid: true },
    backgroundColor: { value: '#454c5d', valid: true },
    textColor: { value: '#ffffff', valid: true },
    beverageEducationOn: { value: false, valid: true },
};

export default init;
