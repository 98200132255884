import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import dayjs from 'dayjs';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import { Search, DeleteForever } from '@material-ui/icons';
// Components
import Select from 'components/Input/Select';
import Page from 'components/Page';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import BasicDialog from 'components/Dialogs/BasicDialog';
import CustomTable from 'components/CustomTable';
import StyledButton from 'components/Button';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const TableHeaders = ['Chapter', 'Submitted By', 'Date', 'Message', ''];

const PersonalSafetyAlerts = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;
    const FirstChapterId = cognitoUser.FirstChapter ? cognitoUser.FirstChapter.id : null;

    const [deleteModalData, setDeleteModalData] = useState(null);
    const [dateRange, setDateRange] = useState('all');
    const [alerts, setAlerts] = useState({ count: 0, data: [] });
    const [loadingAlerts, setLoadingAlerts] = useState(false);
    const [, setRemovingAlerts] = useState(false);
    const alertRemovals = React.useRef([]);
    // Org
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });
    const [organization, setOrganization] = useState(ParentOrgId || 'all');
    // chapter
    const [Chapters, setChapters] = useState({ count: 0, data: [] });
    const [chapter, setChapter] = useState(FirstChapterId || 'all');

    // Pagination
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState({
        orderBy: 'createdAt',
        order: 'desc',
    });

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        _getAlerts();
    }, [page, perPage, order]);

    useEffect(() => {
        async function _getChapters() {
            if (FirstChapterId)
                return setChapters({
                    data: cognitoUser.Organizations,
                    count: cognitoUser.Organizations.length,
                });

            try {
                const chapters = await API.get('ClutchAPI', `/organizations?ParentId=${organization}`);
                mounted && setChapters(chapters);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        if (!!organization) {
            _getChapters();
        }
    }, [organization, dispatch]);

    async function _getAlerts(query = {}, setLoadingState = true) {
        if (organization && organization !== 'all') {
            query.ParentId = organization;
        }
        if (chapter && chapter !== 'all') {
            query.OrganizationId = chapter;
        }
        if (dateRange && dateRange !== 'all') {
            query.dateRange = dateRange;
        }

        if (order.orderBy && order.order) {
            query.orderBy = order.orderBy;
            query.order = order.order;
        }

        query.page = page + 1;
        query.perPage = perPage;

        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingAlerts(true);
        try {
            const alerts = await API.get('ClutchAPI', `/personal/safety-alerts?${params}`);
            mounted && setAlerts(alerts);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingAlerts(false);
    }

    // Handle Functions
    async function handleDeleteAlert(alert, index) {
        alertRemovals.current[alert.id] = true;
        mounted && setRemovingAlerts(true);
        try {
            await API.del(
                'ClutchAPI',
                `/personal/safety-alerts/${alert.id}?OrganizationId=${alert.OrganizationId}&ParentId=${alert.ParentId}`
            );
            setDeleteModalData(null);
            /** Remove the alert from the state, and requery without loading state */
            const clonedAlerts = cloneDeep(alerts);
            clonedAlerts.count -= 1;
            clonedAlerts.data.splice(index, 0);
            setAlerts(clonedAlerts);
            /** Requery without loading state */
            await _getAlerts({}, false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        delete alertRemovals.current[alert.id];
        mounted && setRemovingAlerts(false);
    }
    // Pagination
    function handleChangePage(e, page) {
        mounted && setPage(page);
    }

    function handleChangeRows(e) {
        mounted && setPerPage(e.target.value);
    }
    function handleSort(order, index) {
        let translation = {
            Chapter: 'Chapter.name',
            'Submitted By': 'User.name',
            Date: 'createdAt',
            Message: 'information',
        };
        let translated = translation[TableHeaders[index]];
        mounted && setOrder({ orderBy: translated, order });
    }

    return (
        <Page
            topNavigationProps={{
                pageTitle: 'Personal Safety Alerts',
            }}>
            <BasicDialog
                open={!!deleteModalData}
                title={deleteModalData ? deleteModalData.title : ''}
                subtitle={'Are you sure you would like to delete this Alert?'}
                onClose={() => setDeleteModalData(null)}
                onSubmit={deleteModalData ? deleteModalData.onSubmit : null}
            />
            <Grid container spacing={2}>
                {!ParentOrgId && (
                    <Grid item xs={12} lg={3} md={4}>
                        <Select
                            typography={'Organization'}
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => {
                                setOrganization(e.target.value);
                                setChapter('all');
                                setChapters({ data: [], count: 0 });
                            }}>
                            <MenuItem key={`org-all`} value={'all'}>
                                All
                            </MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                )}
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Chapter'}
                        classes={{ root: classes.selectHeight }}
                        disabled={Chapters.data.length === 0}
                        value={chapter}
                        onChange={(e) => setChapter(e.target.value)}>
                        {!FirstChapterId && (
                            <MenuItem key={`chapter-all`} value={'all'}>
                                All
                            </MenuItem>
                        )}
                        {Chapters.data.map((chapter) => {
                            return (
                                <MenuItem key={`chapter-${chapter.id}`} value={chapter.id}>
                                    {chapter.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item xs={12} lg={2} md={4}>
                    <Select
                        typography={'Date Range'}
                        classes={{ root: classes.selectHeight }}
                        value={dateRange}
                        onChange={(e) => setDateRange(e.target.value)}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="7">Next 7 Days</MenuItem>
                        <MenuItem value="30">Next 30 Days</MenuItem>
                        <MenuItem value="0">Today</MenuItem>
                        <MenuItem value="-7">Last 7 Days</MenuItem>
                        <MenuItem value="-30">Last 30 Days</MenuItem>
                        <MenuItem value="-365">Last Year</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} lg={1} md={4} className={classes.searchGrid}>
                    <Button
                        disableElevation
                        classes={{ root: classes.filterButton }}
                        onClick={() => _getAlerts()}
                        variant="contained"
                        color={'primary'}>
                        <Search />
                    </Button>
                </Grid>
            </Grid>
            <CustomTable
                headers={TableHeaders}
                count={alerts.count}
                page={page}
                onSort={handleSort}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                rowsPerPage={perPage}
                rows={(loadingAlerts ? Array.from(new Array(6)) : alerts.data).map((alert, index) => {
                    if (!alert)
                        return {
                            columns: SkeletonTableRow({
                                frontElements: [],
                                count: 4,
                                backElements: [
                                    <HorizontalCell>
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.reviewButton,
                                            }}
                                            variant="contained"
                                            color={'primary'}
                                            text="Details"
                                        />
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.deleteButton,
                                            }}
                                            variant="contained"
                                            variantType="containedRed"
                                            color={'primary'}
                                            text={<DeleteForever />}
                                        />
                                    </HorizontalCell>,
                                ],
                            }),
                        };

                    return {
                        columns: [
                            alert.Organization.name,
                            cognitoUser.policies[policyIdentifiers.MANAGE_ORGANIZATION_USERS] ||
                            cognitoUser.policies[policyIdentifiers.SITE_ADMIN_POLICY] ? (
                                <Link to={`/org-users/${alert.User.id}?OrganizationId=${alert.OrganizationId}`}>
                                    {`${alert.User.firstName} ${alert.User.lastName}`}
                                </Link>
                            ) : (
                                `${alert.User.firstName} ${alert.User.lastName}`
                            ),
                            dayjs(alert.createdAt).format('M/D/YYYY'),
                            alert.information,
                            <HorizontalCell>
                                <StyledButton
                                    disableElevation
                                    disabled={!!alertRemovals.current[alert.id]}
                                    classes={{ root: classes.reviewButton }}
                                    variant="contained"
                                    color={'primary'}
                                    text="Details"
                                    onClick={() =>
                                        props.history.push(
                                            `/personal-safety-alerts/${alert.id}?OrganizationId=${alert.OrganizationId}&ParentId=${alert.ParentId}`
                                        )
                                    }
                                />
                                <StyledButton
                                    disableElevation
                                    disabled={!!alertRemovals.current[alert.id]}
                                    classes={{ root: classes.deleteButton }}
                                    variant="contained"
                                    variantType="containedRed"
                                    color={'primary'}
                                    text={<DeleteForever />}
                                    onClick={() =>
                                        setDeleteModalData({
                                            title: (
                                                <span>
                                                    Delete Alert
                                                    <br />
                                                    {`"${alert.Organization.name}" - ${alert.information}`}
                                                </span>
                                            ),
                                            onSubmit: () => handleDeleteAlert(alert, index),
                                        })
                                    }
                                />
                            </HorizontalCell>,
                        ],
                    };
                })}
            />
        </Page>
    );
};

export default withStyles(styles)(PersonalSafetyAlerts);
