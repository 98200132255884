export const GET_PERSONAL_SAFETY_ALERT_SUCCESS = 'GET_PERSONAL_SAFETY_ALERT_SUCCESS';
export const GET_PERSONAL_SAFETY_ALERT_ERROR = 'GET_PERSONAL_SAFETY_ALERT_ERROR';
export const DELETE_PERSONAL_SAFETY_ALERT_SUCCESS = 'DELETE_PERSONAL_SAFETY_ALERT_SUCCESS';
export const DELETE_PERSONAL_SAFETY_ALERT_ERROR = 'DELETE_PERSONAL_SAFETY_ALERT_ERROR';
export const SEND_PERSONAL_SAFETY_ALERT_EMAIL_SUCCESS = 'SEND_PERSONAL_SAFETY_ALERT_EMAIL_SUCCESS';
export const SEND_PERSONAL_SAFETY_ALERT_EMAIL_ERROR = 'SEND_PERSONAL_SAFETY_ALERT_EMAIL_ERROR';

export const INIT = {
    User: '',
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_PERSONAL_SAFETY_ALERT_SUCCESS:
            return payload;
        case GET_PERSONAL_SAFETY_ALERT_ERROR:
        case SEND_PERSONAL_SAFETY_ALERT_EMAIL_SUCCESS:
        case SEND_PERSONAL_SAFETY_ALERT_EMAIL_ERROR:
            return state;
        default:
            return state;
    }
}
