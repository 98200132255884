import React, { useState } from 'react';
// Modules
import Mixpanel from 'mixpanel-browser';
import checkError from 'utils/check-error';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { sendMessage as sendChapterAlertMessage } from 'redux/actions/chapterSafetyAlert';
import { setSnackbar } from 'redux/actions/snackbar';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// components
import Button from 'components/Button';
import Input from 'components/Input';
import RoundedSection from 'components/RoundedSection';
import RoundedSectionTop from 'components/RoundedSection/RoundedSectionTop';
import RoundedSectionLabel from 'components/RoundedSection/RoundedSectionLabel';
import RoundedSectionBody from 'components/RoundedSection/RoundedSectionBody';
// Styles
import styles from './styles';

const MemberMessenger = (props) => {
    const { classes } = props;

    const dispatch = useDispatch();
    const { chapterSafetyAlert } = useSelector(({ chapterSafetyAlert }) => ({ chapterSafetyAlert }));
    const [message, setMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);

    async function handleFormSubmit(e) {
        e.preventDefault();

        setSendingMessage(true);
        try {
            await dispatch(sendChapterAlertMessage(message));
            Mixpanel.track('Safety alert message sent');
            dispatch(setSnackbar('Message Sent'));
            setMessage('');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        setSendingMessage(false);
    }

    function getNumberOfSelectedMembers(alertResponses) {
        let n = 0;

        alertResponses.forEach((response) => {
            n += response.selected ? 1 : 0;
        });

        return n;
    }

    return (
        <form onSubmit={handleFormSubmit} className={classes.root}>
            <RoundedSection classes={{ root: classes.sendMessage }}>
                <RoundedSectionTop>
                    <RoundedSectionLabel
                        primary={`Message ${getNumberOfSelectedMembers(chapterSafetyAlert.AlertResponses)} Members`}
                        secondary="Keep the message under 120 characters"
                    />
                </RoundedSectionTop>
                <RoundedSectionBody>
                    <Input
                        multiline
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        maxLength={120}
                        placeholder="Type message here..."
                    />
                </RoundedSectionBody>
            </RoundedSection>
            <Button
                color="primary"
                size="large"
                text="Send Message"
                type="submit"
                variant="contained"
                fullWidth
                disabled={getNumberOfSelectedMembers(chapterSafetyAlert.AlertResponses) === 0 || sendingMessage}
                loading={sendingMessage}
            />
        </form>
    );
};

export default withStyles(styles)(MemberMessenger);
