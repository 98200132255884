import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import queryString from 'query-string';
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import Mixpanel from 'mixpanel-browser';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import { Tabs, Tab, AppBar } from '@material-ui/core';
// Components
import Button from 'components/Button';
import Indicator from 'components/Indicator';
import Page from 'components/Page';
import TabPanel from 'components/TabPanel';
import Skeleton from 'components/Skeleton';
import BasicDialog from 'components/Dialogs/BasicDialog';
import UserDetails from './UserDetails';
import UserRoles from './UserRoles';
import UserChapters from './UserChapters';
import IntegrationData from './IntegrationData/IntegrationData';
// styles
import styles from './styles';

class OrgUserEdit extends React.Component {
    constructor(props) {
        super(props);

        this.query = queryString.parse(this.props.history.location.search);
        this.state = {
            dialog: {
                open: false,
                onSubmit: () => {},
            },
            submitting: false,
            tab: this.query.tab === 'roles' ? 1 : 0,
            user: { roles: [] },
            roles: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.mounted = true;
        if (!this.query.OrganizationId) {
            setSnackbar('Permission denied');
            return this.props.history.push('/org-users');
        }
        this._getData();
    }

    async _getData() {
        try {
            const [roles, user, defaultChapters] = await Promise.all([
                API.get('ClutchAPI', `/organizations/${this.query.OrganizationId}/roles`),
                API.get('ClutchAPI', `/organizations/${this.query.OrganizationId}/users/${this.props.match.params.id}`),
                API.get('ClutchAPI', `/organizations?ParentId=${this.query.OrganizationId}&perPage=100&page=1`),
            ]);
            this.setState({ roles: roles.data, user, defaultChapters, loading: false });
        } catch (error) {
            this.props.setSnackbar(checkError(error));
        }
    }

    async showDialog({ onSubmit }) {
        this.setState({ dialog: { open: true, onSubmit } });
    }

    async handleAddRole(role) {
        try {
            await API.post(
                'ClutchAPI',
                `/organizations/${this.query.OrganizationId}/users/${this.state.user.id}/roles/${role.id}`
            );
            Mixpanel.track('Role added to user');
            // Add role to user
            const user = cloneDeep(this.state.user);
            user.roles.push(role);
            this.setState({ user });
        } catch (error) {
            this.props.setSnackbar(checkError(error));
        }
    }

    async handleRemoveRole(role) {
        try {
            await API.del(
                'ClutchAPI',
                `/organizations/${this.query.OrganizationId}/users/${this.state.user.id}/roles/${role.id}`
            );
            Mixpanel.track('Role removed from user');
            // Add role to user
            const user = cloneDeep(this.state.user);
            user.roles = user.roles.filter((r) => r.id !== role.id);
            this.setState({ user });
        } catch (error) {
            this.props.setSnackbar(checkError(error));
        }
    }

    async handleAddChapter(chapter) {
        try {
            const relation = await API.post(
                'ClutchAPI',
                `/organizations/${this.query.OrganizationId}/users/${this.state.user.id}/assign-organization/${chapter.id}`
            );
            Mixpanel.track('Chapter added to user');
            // Add role to user
            const user = cloneDeep(this.state.user);
            user.UserOrganizations.unshift(relation);
            this.setState({ user });
        } catch (error) {
            this.props.setSnackbar(checkError(error));
        }
    }

    async handleRemoveChapter(chapter) {
        try {
            await API.del(
                'ClutchAPI',
                `/organizations/${this.query.OrganizationId}/users/${this.state.user.id}/assign-organization/${chapter.id}`
            );
            Mixpanel.track('Chapter removed from user');
            // Add role to user
            const user = cloneDeep(this.state.user);
            user.UserOrganizations = user.UserOrganizations.filter((c) => c.Organization.id !== chapter.id);
            this.setState({ user });
        } catch (error) {
            this.props.setSnackbar(checkError(error));
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { classes } = this.props;
        const { user, dialog } = this.state;

        return this.state.loading ? (
            <Page maxWidth={900} topNavigationProps={{ pageTitle: `Edit User` }}>
                <Skeleton type="infoPage" />
            </Page>
        ) : (
            <Page maxWidth={900} topNavigationProps={{ pageTitle: `Edit User - ${user.firstName} ${user.lastName}` }}>
                <BasicDialog
                    open={dialog.open}
                    title="Important: Please Read Before Proceeding"
                    subtitle="Editing this information will overwrite any user data from the database integration.  Changes will only persist until the next integration is run, typically within the next 1-7 days.  Please contact your organization's data administrator to make any changes that you wish to be permanent."
                    onClose={() => this.setState({ dialog: { open: false } })}
                    onSubmit={async () => {
                        await dialog.onSubmit();
                        this.setState({ dialog: { open: false } });
                    }}
                    rightButton={{
                        variantType: 'containedBlue',
                        text: 'Confirm',
                    }}
                />
                <div className={classes.actions}>
                    <Button
                        classes={{
                            root: classes.mb10,
                            startIcon: classes.chevronLeft,
                        }}
                        text="Return to Organization Users"
                        onClick={() =>
                            this.props.history.push(`/org-users?OrganizationId=${this.query.OrganizationId}`)
                        }
                        variant="contained"
                        variantType="containedBlue"
                        disabled={this.state.submitting}
                        disableElevation
                        startIcon={<ChevronLeft />}
                    />
                </div>
                <AppBar elevation={1} position="static" color="default" className={classes.appbar}>
                    <Tabs
                        value={this.state.tab}
                        onChange={(e, value) => this.setState({ tab: value })}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        scrollButtons="auto"
                        centered>
                        <Tab label="Details" />
                        <Tab label="Roles" />
                        <Tab label="Chapters" />
                        {user.integrationId && <Tab label="Integration Data" />}
                    </Tabs>
                </AppBar>
                <Indicator>
                    {user.lastLoginDate
                        ? `Last logged in ${moment(user.lastLoginDate).format('MM/D/YYYY - h:mm A')}`
                        : 'User has not logged in.'}
                </Indicator>
                <TabPanel value={this.state.tab} index={0} classes={{ root: classes.tab }}>
                    <UserDetails
                        user={this.state.user}
                        organization={this.query.OrganizationId}
                        showDialog={this.showDialog.bind(this)}
                    />
                </TabPanel>
                <TabPanel value={this.state.tab} index={1} classes={{ root: classes.tab }}>
                    <UserRoles
                        user={this.state.user}
                        roles={this.state.roles}
                        userRoles={this.state.user.roles}
                        chaptersCount={this.state.user.UserOrganizations.length}
                        onAddRole={this.handleAddRole.bind(this)}
                        onRemoveRole={this.handleRemoveRole.bind(this)}
                        goToChapters={() => this.setState({ tab: 2 })}
                        showDialog={this.showDialog.bind(this)}
                    />
                </TabPanel>
                <TabPanel value={this.state.tab} index={2} classes={{ root: classes.tab }}>
                    <UserChapters
                        user={this.state.user}
                        organization={this.query.OrganizationId}
                        userChapters={this.state.user.UserOrganizations}
                        onAddChapter={this.handleAddChapter.bind(this)}
                        onRemoveChapter={this.handleRemoveChapter.bind(this)}
                        defaultChapters={this.state.defaultChapters}
                        showDialog={this.showDialog.bind(this)}
                    />
                </TabPanel>
                <TabPanel value={this.state.tab} index={3} classes={{ root: classes.tab }}>
                    <IntegrationData data={user.currentRevision} />
                </TabPanel>
            </Page>
        );
    }
}

const actions = { setSnackbar };

export default connect(null, actions)(withStyles(styles)(OrgUserEdit));
