const styles = (theme) => ({
    root: {},
    requiredLabel: {
        fontStyle: 'italic',
        fontSize: 16,
        color: '#4a4a4a',
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        fontFamily: 'Lato',
    },
    heading: {
        fontSize: 16,
        textDecoration: 'underline',
        fontWeight: 700,
        fontFamily: 'Lato',
    },
    editor: {
        minHeight: 120,
    },
    checkBoxes: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 0px 16px 0px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    listRoot: {
        borderRadius: 6,
        border: '1px solid rgba(151, 151, 151, 0.41)',
        padding: 15,
    },
    emailSeperator: {
        margin: '16px 0px',
    },
    list: {
        maxHeight: 300,
        paddingRight: 18,
        overflowY: 'scroll',
        /* width */
        '&::-webkit-scrollbar': {
            padding: 10,
            width: 20,
        },

        /* Track */
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f8fafd',
            borderRadius: 11,
            border: '1px solid #ccd0d7',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            background: '#454c5d',
            borderRadius: 10,
            height: 56,
        },

        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#454c5d',
        },
    },
    searchIcon: {
        color: 'rgba(151, 151, 151, 0.41)',
    },
    dividerColor: {
        backgroundColor: 'rgba(151, 151, 151, 0.41)',
    },
    listItem: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    firstItem: {
        paddingTop: 0,
    },
    lastItem: {
        paddingBottom: 0,
    },
    search: {
        marginTop: 14,
        marginBottom: 14,
    },
    placementStart: {
        marginRight: 16,
    },
    uploadRoot: {
        backgroundColor: '#f8fafd',
    },
    underline: {
        textDecoration: 'underline',
        marginBottom: 8,
    },
});

export default styles;
