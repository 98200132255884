import React from 'react';
// Modules
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { store, persistor } from './redux/config';
import * as serviceWorker from './serviceWorker';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// CSS Imports
import './index.css';

// Mixpanel
import Mixpanel from 'mixpanel-browser';
import { mixPanelConfig } from './mixpanel/mixpanel_resource';
// AWS
import Amplify from 'aws-amplify';
import { awsConfig } from './aws/aws_resource';
Amplify.configure(awsConfig);
Mixpanel.init(mixPanelConfig.AdminToken);

const App = (props) => {
    const { theme } = store.getState();
    const muiTheme = createMuiTheme(theme);

    return (
        <Provider store={store}>
            <PersistGate loading={<CircularProgress size={150} />} persistor={persistor}>
                <MuiThemeProvider theme={muiTheme}>
                    <Router />
                </MuiThemeProvider>
            </PersistGate>
        </Provider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
