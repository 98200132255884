const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 6,
        border: '1px solid rgba(151, 151, 151, 0.3)',
        padding: '15px 19px',
        margin: '16px 0',
        color: '#4f5668',
        backgroundColor: '#ffffff',
    },
    icon: {
        marginRight: 13,
    },
    text: {
        fontWeight: 700,
        fontSize: 14,
        fontFamily: 'Lato',
    },
});

export default styles;
