import whitespace from 'assets/images/whitespace.png';
const BORDER_RADIUS = 6;

const styles = (theme) => ({
    section: {
        marginBottom: 20,
    },
    sectionHeader: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0`,
        display: 'flex',
        width: '100%',
    },
    move: {
        alignItems: 'center',
        display: 'flex',
        opacity: 0.5,
    },
    moveButton: {
        cursor: 'grab',
        padding: '8px 12px',
    },
    moveText: {
        color: 'white',
        lineHeight: 0,
        textTransform: 'uppercase',
    },
    title: {
        color: 'white',
        flexGrow: 1,
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    delete: {
        alignItems: 'center',
        display: 'flex',
        opacity: 0.5,
    },
    deleteText: {
        color: 'white',
        lineHeight: 0,
        textTransform: 'uppercase',
    },
    deleteButton: {
        padding: '8px 12px',
    },
    sectionBody: {
        backgroundColor: 'white',
        borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
    },
    item: {
        display: 'flex',
        padding: '12px 0px',
        backgroundColor: 'white',
    },
    handle: {
        cursor: 'grab',
    },
    itemContent: {
        flexGrow: 1,
    },
    itemDelete: {},
    controlIcons: {
        maxHeight: 48,
    },
    whitespace: {
        backgroundImage: `url(${whitespace})`,
    },
});

export default styles;
