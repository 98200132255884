const styles = (theme) => ({
    topPageForm: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    roundedSection: {
        margin: '20px 0',
    },
    buttonHeight: {
        height: 60,
    },
});

export default styles;
