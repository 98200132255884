const styles = (theme) => ({
    root: {},
    toolbar: {
        padding: 10,
        margin: '5px 0',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #e3e2e2',
    },
    iconButton: {
        padding: 0,
        borderRadius: '50%',
        width: '22px',
        height: '22px',
        cursor: 'pointer',
    },
    buttonDenied: {},
    buttonApproved: {
        marginLeft: -1,
    },

    iconApproved: {
        color: '#82C958',
        fill: '#82C958',
        borderRadius: '50%',
        background: '#fff',
        border: `1px solid ${theme.palette.background.paper}`,
        // boxShadow: "1px 1px 4px rgba(0,0,0,0.5)",
        '&:hover': {
            fill: '#679f45',
        },
    },
    iconDenied: {
        color: '#D06767',
        fill: '#D06767',
        borderRadius: '50%',
        background: '#fff',
        border: `1px solid ${theme.palette.background.paper}`,
        // boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
        '&:hover': {
            fill: '#9b4b4a',
        },
    },
    tooltipTitle: {
        marginBottom: 15,
        fontSize: 16,
    },
    tooltipContent: {
        padding: 6,
    },
    tooltipItem: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 13,
        margin: '4px 0',
    },

    listedContainer: {
        display: 'flex',
        color: 'rgb(101, 103, 107);',
        fontSize: 15,
        marginLeft: 10,
    },
    listedNames: {
        marginLeft: 4,
    },
    listedTextEnd: {
        marginLeft: 2,
    },
});
export default styles;
