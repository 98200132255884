import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import Mixpanel from 'mixpanel-browser';
import Formalize from '@fyresite/formalize';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import FormValidator from 'utils/FormValidator';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
// Components
import Button from 'components/Button';
import Input from 'components/Input';
import Skeleton from 'components/Skeleton';
import Select from 'components/Input/Select';
import Page from 'components/Page';

// Variables
import FORM_INIT from './init';
import queryString from 'query-string';

// styles
import styles from './styles';

class Category extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);

        const { cognitoUser } = props;
        this.query = queryString.parse(this.props.history.location.search);
        this.ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;
        if (this.ParentOrgId) this.INIT.OrganizationId.value = this.ParentOrgId;

        this.state = {
            Organizations: { data: [], count: 0 },
            submitting: false,
            loading: !isNaN(parseInt(props.match.params.id)),
            pageTitle: '',
            ...cloneDeep(this.INIT),
        };
    }

    componentDidMount() {
        !this.ParentOrgId && this._getOrganizations();
        this.mounted = true;
        if (this.props.match.params.id !== 'new') {
            this.setState({ pageTitle: 'Edit Category' });
            this._getCategory();
        } else {
            this.setState({ pageTitle: 'Add Category' });
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async _getCategory() {
        const { setSnackbar } = this.props;
        this.mounted && this.setState({ loading: true });
        const parsed = queryString.parse(this.props.history.location.search);
        if (!parsed.OrganizationId) {
            setSnackbar('Permission denied');
            return this.props.history.push('/education');
        }
        try {
            const rule = Formalize(
                this.INIT,
                await API.get(
                    'ClutchAPI',
                    `/organizations/${parsed.OrganizationId}/categories/${this.props.match.params.id}`
                )
            );
            this.mounted &&
                this.setState({
                    ...rule,
                    loading: false,
                });
        } catch (error) {
            setSnackbar(checkError(error));
            this.props.history.push('/education');
            this.mounted && this.setState({ loading: false });
        }
    }

    async _getOrganizations() {
        try {
            const Organizations = await API.get('ClutchAPI', '/organizations');
            this.setState({ Organizations });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { setSnackbar } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        try {
            if (this.state.id) {
                await API.patch(
                    'ClutchAPI',
                    `/organizations/${this.state.OrganizationId.value}/categories/${this.state.id}`,
                    {
                        body: {
                            name: this.state.name.value,
                        },
                    }
                );
                Mixpanel.track('Category updated');
                setSnackbar('Category updated');
            } else {
                await API.post('ClutchAPI', `/organizations/${this.state.OrganizationId.value}/categories`, {
                    body: {
                        name: this.state.name.value,
                    },
                });
                Mixpanel.track('Category created');
                setSnackbar('Category saved');
                this.setState({ ...cloneDeep(this.INIT) });
            }
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ submitting: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        const { Organizations } = this.state;

        return this.state.loading ? (
            <Page maxWidth={720}>
                <Skeleton type="infoPage" />
            </Page>
        ) : (
            <Page maxWidth={720} topNavigationProps={{ pageTitle: this.state.pageTitle }}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Button
                        classes={{
                            root: classes.mb10,
                            startIcon: classes.chevronLeft,
                        }}
                        text="Return to Education"
                        onClick={() => this.props.history.push('/education')}
                        variant="contained"
                        variantType="containedBlue"
                        disabled={this.state.submitting}
                        disableElevation
                        startIcon={<ChevronLeft />}
                    />
                    {!this.ParentOrgId && (
                        <Select
                            ref="OrganizationId"
                            value={this.state.OrganizationId.value}
                            valid={this.state.OrganizationId.valid}
                            errorMessage="Organization Required"
                            validator={() => this.state.OrganizationId.value !== 0}
                            onChange={(e) =>
                                this.updateField({
                                    field: 'OrganizationId',
                                    value: e.target.value,
                                })
                            }
                            marginBottom={10}
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0 || !!this.state.id}
                            typography={'Organization'}>
                            <MenuItem value={0}>Select an Organization</MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                    <Input
                        ref="name"
                        value={this.state.name.value}
                        valid={this.state.name.valid}
                        errorMessage="Name is Required"
                        validator={() => !!this.state.name.value}
                        onChange={(e) =>
                            this.updateField({
                                field: 'name',
                                value: e.target.value,
                            })
                        }
                        marginBottom={20}
                        fullWidth
                        typography="Category Name"
                        placeholder="Name of Category"
                    />

                    <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                    <Button
                        text={this.state.id ? 'Update Category' : 'Save Category'}
                        fullWidth
                        onClick={this.handleSubmit.bind(this)}
                        variant="contained"
                        variantType="containedBlue"
                        disableElevation
                        loading={this.state.submitting}
                    />
                </form>
            </Page>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
const actions = { setSnackbar };

export default connect(mapStateToProps, actions)(withStyles(styles)(Category));
