const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        '& img': {
            maxWidth: '100%',
        },
    },
});

export default styles;
