import React from 'react';
import dayjs from 'dayjs';

/**
 *
 * @param {String} search "AWS Dev"
 * @return {String} "AWS* Dev*"
 */
export const fullTextSearchable = (search) => {
    return search
        .trim() // Trim invalid white spaces
        .split(' ') // Split into array
        .map((value) => `${value}*`) // Add any to the end
        .join(' '); // Join back into a string EX. AWS* Dev* etc.
};

/**
 *
 *
 * @name formatDate
 * @description Formats single or multiple dates and returns a string. Accepts 2 arguments.
 * @param  {String} date unformatted date
 * @param  {String} customFormat (Optional) argument for a custom format returned
 * @return {String} "ddd, MMM D, h:mma - ddd, MMM D, h:mma"
 */
export const formatDate = (date, customFormat) => {
    date = dayjs(date);
    if (customFormat) {
        return date.format(customFormat);
    }
    return date.format('MMMM D, YYYY');
};
/**
 *
 *
 * @name titleCase
 * @description Returns string with first letter capatalized
 * @param {String} string  Transformed string
 * @returns {String}
 */
export const titleCase = (string) => {
    return string[0].toUpperCase() + string.slice(1);
};
/**
 *
 *
 * @name parseHtmlJson
 * @description Returns JSX for dangerouslySetInnerHTML Prop
 * @param {String} string  { __html: string };
 * @returns {JSX}
 */
export const parseHtmlJson = (htmlJson) => {
    return { __html: htmlJson };
};
/**
 *
 *
 * @name RenderHtmlJson
 * @description Returns JSX for dangerouslySetInnerHTML Prop
 * @param {String} string  <div {...props} dangerouslySetInnerHTML={{ __html: json }} />
 * @returns {JSX}
 */
export const RenderHtmlJson = ({ json, ...props }) => {
    const regex = /(<([^>]+)>)/gi;
    const removeHtmlJson = json.replace(regex, '');
    return <div {...props} dangerouslySetInnerHTML={{ __html: removeHtmlJson }} />;
};
/**
 *
 *
 * @name getExcerpt
 * @description Returns an excerpt from a longer string
 * @param {String} content      Longer block of content
 * @param {Number} approxLength Number of characters desired, approximately
 * @returns {String}
 */
export const getExcerpt = (content, approxLength) => {
    if (content.length <= approxLength) {
        return content;
    }
    // Split content by words
    const contentArray = content.substr(0, approxLength).split(' ');
    // Set the last "word" in the array to an ellipsis
    contentArray[contentArray.length - 1] = '...';
    return contentArray.join(' ');
};
/**
 *
 *
 * @name noSpaceBetween
 * @description Trims whitespace in a string for URL encoding
 * @param {String} string     A single string with white space.
 * @returns {String}
 */
export const noSpaceBetween = (str) => {
    if (/\s/.test(str)) {
        return str.replace(/\s/g, '');
    }
    return str.toLowerCase();
};
/**
 *
 *

 * @name trim
 * @description Trims a string if it exists, else returns blank string
 * @param {String} str     String to trim spaces from
 * @returns {String}
 */
export const trim = (str) => (str && str.length ? str.replace(/^\s+|\s+$/gm, '') : '');

/**
 *
 *
 * @name isNullEmptyOrUndefined
 * @description Checks to see if the parameter is null,
 * empty, or undefined.
 * @param {*} str Item to verify
 * @returns {Boolean}
 */
export const isNullEmptyOrUndefined = (str) => {
    if (Array.isArray(str)) {
        return !str.length > 0;
    } else return str === null || typeof str === 'undefined' || (typeof str === 'string' && trim(str) === '');
};
