import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem } from '@material-ui/core';
import { DeleteForever, Add } from '@material-ui/icons';

// Components
import Select from 'components/Input/Select';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import CustomTable from 'components/CustomTable';
import BasicDialog from 'components/Dialogs/BasicDialog';
import StyledButton from 'components/Button';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import Page from 'components/Page';
// styles
import styles from './styles';

const TableHeaders = ['Clutch Role', 'Source Role Code(s)', ''];

const OrganizationRoles = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const ParentOrgId = cognitoUser.ParentOrganization ? cognitoUser.ParentOrganization.id : null;

    const query = queryString.parse(props.history.location.search);

    const [deleteModalData, setDeleteModalData] = useState(null);
    const [Roles, setRoles] = useState({ count: 0, data: [] });
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [, setRemovingRoles] = useState(false);
    const roleRemovals = React.useRef([]);
    // Org
    const [Organizations, setOrganizations] = useState({ count: 0, data: [] });
    const [organization, setOrganization] = useState(ParentOrgId || query.OrganizationId || -1);

    // Pagination
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState({
        orderBy: 'name',
        order: 'desc',
    });

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getOrganizations() {
            try {
                const orgs = await API.get('ClutchAPI', '/organizations');
                mounted && setOrganizations(orgs);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }

        !ParentOrgId && _getOrganizations();
    }, [dispatch]);

    useEffect(() => {
        _getRoles();
    }, [page, perPage, order, organization]);

    async function _getRoles(query = {}, setLoadingState = true) {
        if (organization === -1) return;

        if (order.orderBy && order.order) {
            query.orderBy = order.orderBy;
            query.order = order.order;
        }

        query.page = page + 1;
        query.perPage = perPage;

        let params = queryString.stringify(query);

        mounted && setLoadingState && setLoadingRoles(true);
        try {
            const roles = await API.get('ClutchAPI', `/organizations/${organization}/roles?${params}`);
            mounted && setRoles(roles);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setLoadingState && setLoadingRoles(false);
    }

    // Handle Functions
    async function handleDeleteRole(role, index) {
        roleRemovals.current[role.id] = true;
        mounted && setRemovingRoles(true);
        try {
            await API.del('ClutchAPI', `/organizations/${role.context.OrganizationId}/roles/${role.id}`);
            setDeleteModalData(null);
            /** Remove the role from the state, and requery without loading state */
            const clonedRoles = cloneDeep(Roles);
            clonedRoles.count -= 1;
            clonedRoles.data.splice(index, 0);
            setRoles(clonedRoles);
            /** Requery without loading state */
            await _getRoles({}, false);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        delete roleRemovals.current[role.id];
        mounted && setRemovingRoles(false);
    }
    // Pagination
    function handleChangePage(e, page) {
        mounted && setPage(page);
    }

    function handleChangeRows(e) {
        mounted && setPerPage(e.target.value);
    }
    function handleSort(order, index) {
        let translation = {
            'Clutch Role': 'name',
            'Source Role Code(s)': 'metadata.source',
        };
        let translated = translation[TableHeaders[index]];
        mounted && setOrder({ orderBy: translated, order });
    }

    const TopNavButton = () => {
        return (
            <div>
                <Button
                    onClick={() => props.history.push(`/event-review-process/${organization}`)}
                    color="primary"
                    variant="contained"
                    disabled={organization === -1}
                    style={{ marginRight: 8 }}>
                    Manage Review Process
                </Button>
                <Button
                    onClick={() => props.history.push(`/org-roles/new?OrganizationId=${organization}`)}
                    color="primary"
                    startIcon={<Add />}
                    variant="contained"
                    disabled={organization === -1}>
                    New Role
                </Button>
            </div>
        );
    };
    return (
        <Page
            topNavigationProps={{
                pageTitle: 'Organization Roles',
                helperText: 'Manage the roles for your organization',
                children: <TopNavButton />,
            }}>
            <BasicDialog
                open={!!deleteModalData}
                title={deleteModalData ? deleteModalData.title : ''}
                subtitle={'Are you sure you would like to delete this Role?'}
                onClose={() => setDeleteModalData(null)}
                onSubmit={deleteModalData ? deleteModalData.onSubmit : null}
            />
            {!ParentOrgId && (
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3} md={4}>
                        <Select
                            classes={{ root: classes.selectHeight }}
                            disabled={Organizations.data.length === 0}
                            value={organization}
                            onChange={(e) => {
                                setOrganization(e.target.value);
                            }}
                            typography={'Organization'}>
                            <MenuItem key={`org-unselected`} value={-1}>
                                Select an Organization
                            </MenuItem>
                            {Organizations.data.map((org) => {
                                return (
                                    <MenuItem key={`org-${org.id}`} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
            )}
            <CustomTable
                headers={TableHeaders}
                count={Roles.count}
                page={page}
                onSort={handleSort}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                initialOrderIndex={0}
                rowsPerPage={perPage}
                rows={(loadingRoles ? Array.from(new Array(6)) : Roles.data).map((role, index) => {
                    if (!role)
                        return {
                            columns: SkeletonTableRow({
                                count: 2,
                                backElements: [
                                    <HorizontalCell>
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.reviewButton,
                                            }}
                                            variant="contained"
                                            color={'primary'}
                                            text="Review"
                                        />
                                        <StyledButton
                                            disableElevation
                                            disabled
                                            classes={{
                                                root: classes.deleteButton,
                                            }}
                                            variant="contained"
                                            variantType="containedRed"
                                            color={'primary'}
                                            text={<DeleteForever />}
                                        />
                                    </HorizontalCell>,
                                ],
                            }),
                        };
                    return {
                        columns: [
                            role.name,
                            role.metadata
                                ? Array.isArray(role.metadata.source)
                                    ? role.metadata.source.join(', ')
                                    : [role.metadata.source].join(', ')
                                : '',
                            <HorizontalCell>
                                <StyledButton
                                    disableElevation
                                    disabled={!!roleRemovals.current[role.id]}
                                    classes={{ root: classes.reviewButton }}
                                    variant="contained"
                                    color={'primary'}
                                    text="Details"
                                    onClick={() =>
                                        props.history.push(`/org-roles/${role.id}?OrganizationId=${organization}`)
                                    }
                                />
                                <StyledButton
                                    disableElevation
                                    disabled={!!roleRemovals.current[role.id]}
                                    classes={{ root: classes.deleteButton }}
                                    variant="contained"
                                    variantType="containedRed"
                                    color={'primary'}
                                    text={<DeleteForever />}
                                    onClick={() =>
                                        setDeleteModalData({
                                            title: (
                                                <span>
                                                    Delete Role
                                                    <br />
                                                    {`"${role.name}"`}
                                                </span>
                                            ),
                                            onSubmit: () => handleDeleteRole(role, index),
                                        })
                                    }
                                />
                            </HorizontalCell>,
                        ],
                    };
                })}
            />
        </Page>
    );
};

export default withStyles(styles)(OrganizationRoles);
