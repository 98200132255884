import React from 'react';

// Modules
import isEqual from 'lodash/isEqual';
// Material UI Core
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { ArrowBack, Menu } from '@material-ui/icons';
import S3MediaOperations from 'utils/S3MediaOperations';
import { ImageFileTypes } from 'utils/file-types';
// Assets
import ClutchLogo from 'assets/images/ClutchWhite.svg';
import Whistle from 'icons/Whistle';

import styles from './styles';

class ThemePreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            src: '',
        };
    }
    componentDidMount() {
        this.setSource();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.logo, this.props.logo)) {
            this.setSource();
        }
    }

    async setSource() {
        const { logo } = this.props;
        if (typeof logo === 'object' && ImageFileTypes.includes(logo.type)) {
            if (logo.id) {
                if (logo.url) this.setState({ src: logo.url });
                else this.setState({ src: await S3MediaOperations.getDocumentUrl(logo) });
            } else {
                const url = URL.createObjectURL(logo);
                this.setState({ src: url });
            }
        } else {
            this.setState({ src: '' });
        }
    }

    render() {
        const {
            classes,
            backgroundColor = '#454c5d',
            textColor = '#ffffff',
            logo = {},
            logoSize = 'default',
        } = this.props;
        const styles = {
            backgroundColor: backgroundColor,
            color: textColor,
        };
        const logoStyles = [classes.logo];
        if (logoSize === 'large') logoStyles.push(classes.large);

        return (
            <div style={styles} className={classes.root}>
                <div className={classes.bar}>
                    <div className={classes.left}>
                        <IconButton>
                            <ArrowBack style={styles} />
                        </IconButton>
                    </div>
                    <div className={logoStyles.join(' ')}>
                        {!ImageFileTypes.includes(logo.type) ? (
                            <img src={ClutchLogo} alt="logo" />
                        ) : (
                            <img src={this.state.src} alt="logo" />
                        )}
                    </div>
                    <div className={classes.right}>
                        <IconButton>
                            <Whistle style={styles} color={styles.color} />
                        </IconButton>
                        <IconButton>
                            <Menu style={styles} />
                        </IconButton>
                    </div>
                </div>
                <div className={classes.preview}>
                    <Typography variant="h4" style={styles}>
                        This is a Theme Preview
                    </Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ThemePreview);
