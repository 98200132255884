import React from 'react';

// Modules
import { API } from 'aws-amplify';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    IconButton,
    CircularProgress,
    Tooltip,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import AsyncSelect from 'components/Input/AsyncSelect';
// Styles
import styles from './styles';

const DEBOUNCE_TIMER = 500;

class UserChapters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changing: {},
            value: '',
        };
        this.changing = {};
        this.queue = [];
    }

    handleLoadChapters(inputValue, callback) {
        clearTimeout(this.RequestTimer);
        this.RequestTimer = setTimeout(async () => {
            try {
                const chapters = await API.get(
                    'ClutchAPI',
                    `/organizations?ParentId=${this.props.organization}&name=${inputValue}&perPage=100&page=1`
                );
                const values = chapters.data.map((chapter) => ({
                    label: `${chapter.name} - ${chapter.university}`,
                    value: chapter,
                }));
                callback(values);
            } catch (error) {
                callback([]);
            }
        }, DEBOUNCE_TIMER);
    }

    async handleToggleChapter(chapter, func) {
        this.changing[chapter.id] = true;
        this.setState({ changing: this.changing });

        this.queue.push({ chapter, func });
        this.runQueue((chapter) => {
            if (!chapter) return;
            delete this.changing[chapter.id];
            this.setState({ changing: this.changing, value: '' });
        });
    }

    runQueue = (callback) => {
        if (!this.queueHandler) {
            this.queueHandler = setInterval(async () => {
                if (!this.sendingRequest && this.queue.length > 0) {
                    this.sendingRequest = true;
                    let { chapter, func } = this.queue.pop();

                    try {
                        await this.props[func](chapter).catch(console.log);
                        typeof callback == 'function' && callback(chapter);
                    } catch (error) {
                        this.queue = []; // empty queue
                        typeof this.props.onError === 'function' && this.props.onError(error); // Set snackbar
                        typeof callback === 'function' && callback(false);
                    }
                    this.sendingRequest = false;
                }

                if (this.queue.length === 0) {
                    clearInterval(this.queueHandler);
                    this.queueHandler = undefined;
                }
            }, 200);
        }
    };

    render() {
        const { classes, userChapters, defaultChapters, user, showDialog } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8} className={classes.gridItem}>
                        <AsyncSelect
                            id="chapters"
                            async={true}
                            typography="Find Chapter to assign"
                            placeholder="Search by name"
                            defaultOptions={defaultChapters.data.map((chapter) => ({
                                label: `${chapter.name} - ${chapter.university}`,
                                value: chapter,
                            }))}
                            loadOptions={this.handleLoadChapters.bind(this)}
                            onChange={(e) => {
                                if (user.integrationId)
                                    showDialog({
                                        onSubmit: () => {
                                            this.setState({ value: e.label });
                                            this.handleToggleChapter.call(this, e.value, 'onAddChapter');
                                        },
                                    });
                                else {
                                    this.setState({ value: e.label });
                                    this.handleToggleChapter(e.value, 'onAddChapter');
                                }
                            }}
                            value={this.state.value}
                            loading={!!this.state.value}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8} className={classes.gridItem}>
                        <Paper className={classes.paper}>
                            {userChapters.length === 0 ? (
                                <Typography variant="h3">No Chapters assigned</Typography>
                            ) : (
                                <List>
                                    {userChapters.map((chapter, index) => {
                                        return (
                                            <ListItem
                                                divider={userChapters.length - 1 !== index}
                                                key={`chapter-${chapter.Organization.id}`}>
                                                <ListItemText
                                                    primary={chapter.Organization.name}
                                                    secondary={chapter.Organization.university}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title="Unassign chapter from user">
                                                        <span>
                                                            <IconButton
                                                                onClick={() =>
                                                                    this.handleToggleChapter(
                                                                        chapter.Organization,
                                                                        'onRemoveChapter'
                                                                    )
                                                                }
                                                                disabled={this.changing[chapter.Organization.id]}>
                                                                {this.changing[chapter.Organization.id] ? (
                                                                    <CircularProgress
                                                                        style={{ height: 24, width: 24 }}
                                                                    />
                                                                ) : (
                                                                    <Close />
                                                                )}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(UserChapters);
