import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
// Components
import Input from 'components/Input';

// Styles
import styles from './styles';

class ShortTextInput extends React.Component {
    constructor(props) {
        super(props);

        this.title = props.title || '';
        this.placeholder = props.placeholder || '';
        this.required = props.required || false;
        // So that we don't get this ridiculous warning about changing default checked..
        this.defaultChecked = { required: this.required };
    }

    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                title: this.title,
                placeholder: this.placeholder,
                required: this.required,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                        <Input
                            placeholder="Type Short Text Input Title"
                            variant="standard"
                            fullWidth
                            onChange={(e) => this.onChange('title', e.target.value)}
                            defaultValue={this.title}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControlLabel
                            classes={{
                                label: classes.requiredLabel,
                            }}
                            control={
                                <Checkbox
                                    color="primary"
                                    defaultChecked={this.defaultChecked.required}
                                    onChange={(e) => this.onChange('required', e.target.checked)}
                                />
                            }
                            label={'Required'}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Input
                            placeholder="Type Placeholder Text"
                            fullWidth
                            defaultValue={this.placeholder}
                            onChange={(e) => this.onChange('placeholder', e.target.value)}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(ShortTextInput);
