import React, { useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import translateAwsError from 'translate-aws-error';

import Paper from '@material-ui/core/Paper';

// Pages
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import NewPassword from './NewPassword';
import ResetPassword from './ResetPassword';
import LoginHeader from 'components/LoginHeader';
import SsoLogin from './SsoLogin';
import { setSnackbar } from 'redux/actions/snackbar';
import Logo from 'assets/images/ClutchWhite.svg';

import styles from './styles';

const Auth = (props) => {
    const { classes, setSnackbar, history } = props;
    const pathName = history.location.pathname;

    const [challengeUser, setChallengeUser] = useState(null);

    function handleError(error) {
        let message = error.message;

        if (translateAwsError(error)) {
            message = translateAwsError(error).message;
        }

        setSnackbar(message);
    }

    const functions = { setChallengeUser, handleError };
    const variables = { challengeUser };

    // TODO: On all routes change current form with shlorm
    return (
        <div className={classes.body}>
            <div className={classes.root}>
                <LoginHeader
                    classes={{ header: classes.topbar }}
                    logo={Logo}
                    onClick={(e) => history.push('/')}
                    title={pathName === '/' ? 'Select Your Organization' : ''}
                />
                <section className={classes.pageSection}>
                    <Paper className={classes.paper} elevation={5}>
                        <Switch>
                            <Route
                                exact
                                path="/new-password"
                                render={(props) => <NewPassword {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/forgot-password"
                                render={(props) => <ForgotPassword {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/reset-password"
                                render={(props) => <ResetPassword {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/login"
                                render={(props) => <Login {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/"
                                render={(props) => <SsoLogin {...props} {...variables} {...functions} />}
                            />
                            <Route path="/*" render={() => <Redirect to="/" />} />
                        </Switch>
                    </Paper>
                </section>
            </div>
        </div>
    );
};

const actions = { setSnackbar };

export default connect(null, actions)(withStyles(styles)(Auth));
