import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import {
    INIT,
    GET_PERSONAL_SAFETY_ALERT_SUCCESS,
    GET_PERSONAL_SAFETY_ALERT_ERROR,
    SEND_PERSONAL_SAFETY_ALERT_EMAIL_SUCCESS,
    SEND_PERSONAL_SAFETY_ALERT_EMAIL_ERROR,
    DELETE_PERSONAL_SAFETY_ALERT_ERROR,
    DELETE_PERSONAL_SAFETY_ALERT_SUCCESS,
} from 'redux/reducers/personalSafetyAlert';

export const get = ({ id, OrganizationId, ParentId }) => async (dispatch) => {
    let path = `/personal/safety-alerts/${id}?OrganizationId=${OrganizationId}&ParentId=${ParentId}`;

    try {
        const alert = await API.get('ClutchAPI', path);

        dispatch({
            type: GET_PERSONAL_SAFETY_ALERT_SUCCESS,
            payload: alert,
        });
    } catch (error) {
        dispatch({
            type: GET_PERSONAL_SAFETY_ALERT_ERROR,
        });
        throw error;
    }
};

export const sendEmail = (emails) => async (dispatch, getState) => {
    let { personalSafetyAlert } = getState();

    try {
        await API.post(
            'ClutchAPI',
            `/personal/safety-alerts/${personalSafetyAlert.id}/email?OrganizationId=${personalSafetyAlert.OrganizationId}&ParentId=${personalSafetyAlert.ParentId}`,
            {
                body: {
                    emails,
                },
            }
        );

        dispatch({
            type: SEND_PERSONAL_SAFETY_ALERT_EMAIL_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SEND_PERSONAL_SAFETY_ALERT_EMAIL_ERROR,
        });
        throw error;
    }
};

export const remove = () => async (dispatch, getState) => {
    let { personalSafetyAlert } = getState();
    let path = `/personal/safety-alerts/${personalSafetyAlert.id}?OrganizationId=${personalSafetyAlert.OrganizationId}&ParentId=${personalSafetyAlert.ParentId}`;

    try {
        await API.del('ClutchAPI', path);
        dispatch({
            type: DELETE_PERSONAL_SAFETY_ALERT_SUCCESS,
            payload: cloneDeep(INIT),
        });
    } catch (error) {
        dispatch({
            type: DELETE_PERSONAL_SAFETY_ALERT_ERROR,
            payload: cloneDeep(INIT),
        });
        throw error;
    }
};
