import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { IconButton, Collapse } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styles from './styles';

const TransitionAlert = ({ classes, children, ...props }) => {
    const [open, setOpen] = useState(true);

    return (
        <div className={classes.root}>
            <Collapse in={open}>
                <Alert
                    classes={{
                        ...props.classes,
                        root: classes.outlinedInfoRoot,
                        outlinedInfo: classes.outlinedInfo,
                    }}
                    {...props}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}>
                            <Close fontSize="inherit" />
                        </IconButton>
                    }
                    children={children}></Alert>
            </Collapse>
        </div>
    );
};
export default withStyles(styles)(TransitionAlert);
