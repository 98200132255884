import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons';
// Components
import Input from 'components/Input';

// Styles
import styles from './styles';

class CheckboxItem extends React.Component {
    constructor(props) {
        super(props);

        this.title = props.title || '';
        this.body = props.body || '';
        this.helperText = props.helperText || '';
        this.required = props.required || false;
        this.initialChecked = props.initialChecked || false;

        // So that we don't get this ridiculous warning about changing default checked..
        this.defaultChecked = { required: this.required, initialChecked: this.initialChecked };
    }

    onChange(field, value) {
        this[field] = value;

        if (typeof this.props.onChange === 'function')
            this.props.onChange({
                title: this.title,
                body: typeof this.body === 'string' ? this.body : '', // Body can initially be an object with the editor state this is no bueno,
                helperText: this.helperText,
                required: this.required,
                initialChecked: this.initialChecked,
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                        <Input
                            placeholder="Type Checkbox Title"
                            variant="standard"
                            fullWidth
                            onChange={(e) => this.onChange('title', e.target.value)}
                            defaultValue={this.title}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControlLabel
                            classes={{
                                label: classes.requiredLabel,
                            }}
                            control={
                                <Checkbox
                                    color="primary"
                                    defaultChecked={this.defaultChecked.required}
                                    onChange={(e) => this.onChange('required', e.target.checked)}
                                />
                            }
                            label={'Required'}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Input
                            placeholder="Type Body Text"
                            fullWidth
                            defaultValue={this.body}
                            onChange={(e) => this.onChange('body', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Checkbox
                            color="primary"
                            defaultChecked={this.defaultChecked.initialChecked}
                            onChange={(e) => this.onChange('initialChecked', e.target.checked)}
                            icon={<RadioButtonUnchecked className={classes.checkIcon} />}
                            checkedIcon={<CheckCircle className={classes.checkIconActive} />}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} classes={{ root: classes.helper }}>
                        <Input
                            placeholder="Helper Text (Optional)"
                            fullWidth
                            variant="standard"
                            defaultValue={this.helperText}
                            onChange={(e) => this.onChange('helperText', e.target.value)}
                            InputProps={{
                                classes: { input: classes.helperText },
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(CheckboxItem);
