const styles = (theme) => ({
    root: {},
    appBar: {
        backgroundColor: '#454c5d',
        color: '#ffffff',
    },
    toolbar: {
        justifyContent: 'space-between',
    },
});

export default styles;
