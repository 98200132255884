import { colors } from 'global-styles';

const styles = (theme) => ({
    searchGrid: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        display: 'flex',
    },
    filterButton: {
        height: 57,
        width: 57,
    },
    reviewButton: {
        height: 42,
        marginRight: 5,
    },
    deleteButton: {
        height: 42,
        width: 42,
    },

    circle: {
        margin: 'auto',
        height: 15,
        width: 15,
        borderRadius: '50%',
    },
    circleRed: {
        backgroundColor: colors.brandColors.red,
    },
    circleGreen: {
        backgroundColor: colors.brandColors.green,
    },
    circleBlue: {
        backgroundColor: colors.brandColors.metallicBlue,
    },
    circleWarning: {
        backgroundColor: colors.brandColors.warning,
    },
});

export default styles;
