import React from 'react';
import clsx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DownArrow from '@material-ui/icons/ExpandMore';
import UpArrow from '@material-ui/icons/ExpandLess';
import styles from './styles';

const Page = (props) => {
    const { classes, showUp = true, showDown = true, handleUpClicked, disabled, handleDownClicked } = props;

    const rootClass = clsx({
        [classes.order]: true,
        [classes.orderOnlyUp]: showDown === false,
        [classes.orderOnlyDown]: showUp === false,
    });

    return (
        <div className={rootClass}>
            {showUp && (
                <IconButton disabled={disabled} onClick={handleUpClicked} className={classes.orderIcon}>
                    <UpArrow />
                </IconButton>
            )}
            {showDown && (
                <IconButton disabled={disabled} onClick={handleDownClicked} className={classes.orderIcon}>
                    <DownArrow />
                </IconButton>
            )}
        </div>
    );
};

export default withStyles(styles)(Page);
