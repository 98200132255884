import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink, useHistory } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'redux/actions/cognitoUser';
// Modules
import classNames from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Hidden,
    IconButton,
    ListItemAvatar,
    Avatar,
    Divider,
} from '@material-ui/core';
import { ExitToApp, Close, Person } from '@material-ui/icons';
import MobileAppBar from 'components/MobileAppBar';
// Images
import ClutchWhite from 'assets/images/ClutchWhite.svg';
// styles
import styles from './styles';
// Local
import tabs from './tabs';

const SideNavigation = ({ classes, children, ...props }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const [mobileOpen, setMobileOpen] = useState(false);

    const policyTabs = React.useRef(tabs(cognitoUser.policies));

    async function handleLogout() {
        await dispatch(signOut());
    }

    const handleListItemClick = (index) => {
        history.push({
            pathname: `/${policyTabs.current[index].pathname}`,
            state: {
                title: policyTabs.current[index].label,
            },
        });
        setMobileOpen(false);
    };
    const drawer = (
        <>
            <img
                className={classes.img}
                style={{ display: mobileOpen ? 'none' : 'block' }}
                src={ClutchWhite}
                alt="deltazeta-logo"
                width="261px"
            />
            <List className={classes.list}>
                <ListItem classes={{ root: classes.profileInfo }}>
                    <ListItemAvatar>
                        <Avatar classes={{ root: classes.listAvatar }}>
                            <Person />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primaryTypographyProps={{ classes: { root: classes.profileName } }}
                        secondaryTypographyProps={{ classes: { root: classes.profileEmail } }}
                        primary={`${cognitoUser.firstName} ${cognitoUser.lastName}`}
                        secondary={cognitoUser.email.value}
                    />
                </ListItem>
                {policyTabs.current.map((item, index) => (
                    <ListItem
                        button
                        key={item.label}
                        onClick={() => handleListItemClick(index)}
                        selected={!!history.location.pathname.includes(item.pathname)}
                        classes={{ selected: classes.selected }}
                        style={{ width: mobileOpen ? 243 : 'unset' }}>
                        <ListItemIcon className={classes.listIcon}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
            <div
                className={classNames(classes.logout, {
                    [classes.padding]: mobileOpen,
                })}
                // component={Routerdiv}
                underline="none"
                onClick={handleLogout}
                to="#">
                <Typography className={classes.logoutText}>Logout</Typography>
                <ExitToApp />
            </div>
        </>
    );

    return (
        <div className={classes.root}>
            <MobileAppBar setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />
            <Hidden smDown>
                <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" anchor="left">
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <Drawer variant="temporary" open={mobileOpen} classes={{ paper: classes.drawerPaper }}>
                    <IconButton
                        onClick={() => setMobileOpen(false)}
                        className={classes.closeBtn}
                        color="inherit"
                        aria-label="close">
                        <Close />
                    </IconButton>
                    <div className={classes.mobileDrawer}>{drawer}</div>
                </Drawer>
            </Hidden>

            <main className={classes.content}>
                <div className={classes.scrollableContent}>{children}</div>
            </main>
        </div>
    );
};

export default withRouter(withStyles(styles)(SideNavigation));
