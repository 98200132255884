import { colors } from 'global-styles';

const styles = (theme) => ({
    rounded: {
        marginTop: 20,
        borderRadius: 6,
    },
    head: {
        backgroundColor: colors.brandColors.metallicBlue,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        color: '#4f5668',
        fontWeight: 400,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
    },
    paginationRoot: {
        borderBottomWidth: 0,
    },

    tableSortLabel: {
        fontSize: 16,
        fontWeight: 700,
        fontFamily: 'Lato',
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    tableSortLabelActive: {
        color: `${theme.palette.common.white} !important`,
        textDecoration: 'underline',
    },
});

export default styles;
